<section class="courses-area ptb-100 cooper" style="padding-top: 30px;">
    <div class="container">

        <div class="section-title text-start" style="margin-bottom: 25px; ">
            <!--
            <span class="sub-title">Discover Courses</span>
            -->
            <h2>{{items.categorie}}</h2>
            <!--
            <a routerLink="/courses-2-columns-style-3" class="default-btn"><i class='bx bx-show-alt icon-arrow before'></i><span class="label">All Courses</span><i class="bx bx-show-alt icon-arrow after"></i></a>
            -->
        </div>
        <div class="courses-slides">
            <owl-carousel-o [options]="coursesSlides">


                <ng-template *ngFor="let it of items.events" carouselSlide [width]="350">

                    <div class="single-courses-box mb-30">
                        <div class="courses-image">
                            <a (click)="goToRouting('/check-event?key='+it.eventcode)" class="d-block"><img
                                    style="height: 300px; width: 450px ; cursor: pointer;" [src]="it.url"
                                    alt="image"></a>
                            <div class="courses-tag">
                                <a (click)="goToRouting('/check-event?key='+it.eventcode)"
                                    class="d-block">{{items.categorie}}</a>
                            </div>
                        </div>
                        <div class="courses-content" style="height: 150px; padding: 0px 25px 0px !important;">

                            <h3><a (click)="goToRouting('/check-event?key='+it.eventcode)" class="d-inline-block">

                                    {{it.eventitre}}

                                </a></h3>
                            <div class="courses-rating">
                                <div class="review-stars-rated">
                                    <i class='bx bxs-map'></i>
                                </div>
                                <div class="rating-total">
                                    {{it.eventlieu}}
                                </div>
                            </div>
                            <lib-angular-countdown-dateTime [endDateBind]="it.eventdebut" [dayText]="'J'"
                                [hourText]="'H'" [minutesText]="'M'" [secondsText]="'S'">
                            </lib-angular-countdown-dateTime>
                        </div>
                        <div class="courses-box-footer" style="padding: 0px 25px !important;">
                            <ul>
                                <li class="courses-price">
                                    <a *ngIf="it.eventmaxticket>it.eventachete"
                                        (click)="goToRouting('/check-event?key='+it.eventcode)" target="_blank"
                                        style="color: green ; font-weight: bold">J'achete</a>

                                    <a *ngIf="it.eventmaxticket<=it.eventachete" target="_blank">GUICHET FERMET</a>

                                </li>
                                <li class="courses-lesson">
                                </li>

                                <li class="students-number" >
                                    <br>
                                    A partir
                                    <br>
                                    <span style="color: orange ; font-weight: bold">
                                        {{it.caticketarif}} FRS
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>



                </ng-template>


            </owl-carousel-o>
        </div>
    </div>
</section>