import {HttpClient, HttpBackend, HttpHeaders, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class EventwithoutkeyService {

    constructor(private http: HttpClient, handler: HttpBackend) {
        this.http = new HttpClient(handler);
    }

    get(path: string): Observable<HttpResponse<any>> {
        return this.http.get(`${environment.apiUrl}` + '' + path, {
            observe: 'response',
            headers: new HttpHeaders({'Access-Control-Allow-Origin': '*'})
                .set('content-Type', 'application/json')
        });
    }


    public post(value: any, path: string): Observable<HttpResponse<any>> {
        return this.http.post(`${environment.apiUrl}` + '' + path, JSON.stringify(value), {
            observe: 'response',
            headers: new HttpHeaders({'Access-Control-Allow-Origin': '*'})
                .set('content-Type', 'application/json')
        });
    }

    public postSend(value: any, path: string): Observable<HttpResponse<any>> {
        return this.http.post(`${environment.apiUrlSms}` + path, (value), {
            observe: 'response',
            headers: new HttpHeaders({'Access-Control-Allow-Origin': '*'})
                .set('content-Type', 'application/json')
        });
    }

    public postSender(value: any, path: string): Observable<HttpResponse<any>> {
        return this.http.post(`${environment.apiUrlSms}`+path, (value), {
            observe: 'response',
            headers: new HttpHeaders({'Access-Control-Allow-Origin': '*'})
                .set('content-Type', 'application/json')
        });
    }


    public postVerification(value: any, path: string): Observable<HttpResponse<any>> {
        return this.http.post(path, JSON.stringify(value), {
            observe: 'response',
            headers: new HttpHeaders({'Access-Control-Allow-Origin': '*'})
                .set('content-Type', 'application/json')
        });
    }

    public postCinetpay(value: any, path: string): Observable<HttpResponse<any>> {
        return this.http.post(path, JSON.stringify(value), {
            observe: 'response',
            headers: new HttpHeaders({'Access-Control-Allow-Origin': '*'})
                .set('content-Type', 'application/json')
        });
    }

}
