import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {NGXLogger} from 'ngx-logger';
import {ToastrService} from 'ngx-toastr';
import {environment} from 'src/environments/environment';
import {EventwithkeyService} from '../home-billeterie/eventwithkey.service';
import {Carte, Spectateur} from '../spectateur';

declare let $: any;

@Component({
    selector: 'app-notification-billeterie',
    templateUrl: './notification-billeterie.component.html',
    styleUrls: ['./notification-billeterie.component.scss']
})
export class NotificationBilleterieComponent implements OnInit {

    dataMenu: any = [
        {
            uri: "/",
            label: "Accueil",
        }, {
            uri: "/concert",
            label: "Découvrir",
        }, {
            uri: "/connecter",
            label: "Mon profil",
        }
    ]
    notifications: any[] = [];
    dataUsers: any = [];
    loadingCartecheck: any = false;
    dataNav: any = [
        {
            uri: "/achat",
            label: "Mes achats",
        }, {
            uri: "/ticket",
            label: "Mes tickets",
        }, {
            uri: "/receive",
            label: "Tickets reçu",
        }, {
            uri: "/foward",
            label: "Tickets transférés",
        }, {
            uri: "/carte",
            label: "Mes cartes",
        }, {
            uri: "/notification",
            label: "Notifications",
        }, {
            uri: "/infos",
            label: "Info personnelle",
        },
    ]

    constructor(private _activatedRoute: ActivatedRoute, private router: Router,
                private gestwithkey: EventwithkeyService, public fb: UntypedFormBuilder,
                private toastr: ToastrService,
                private logger: NGXLogger) {
    }

    ngOnInit(): void {

        let u = new Spectateur();
        u = JSON.parse(localStorage.getItem(`${environment.keylocalstorage}`));
        this.dataUsers = u;
        if (!u) {
            this.router.navigate(['/'])
        }
        this.loadCarte();

    }

    logout() {
        localStorage.clear();
        this.router.navigate(['/'])
    }

    loadCarte() {
        let u: Spectateur;
        u = JSON.parse(localStorage.getItem(`${environment.keylocalstorage}`));

        this.logger.info(u);
        this.loadingCartecheck = true;


        if (u) {
            this.gestwithkey.get("/billeterie/pusher/allbyuser/" + u.spectateurcode, u.token).subscribe(data => {
                    this.selectNav(5);
                    switch (data.status) {
                        case 200: {
                            this.loadingCartecheck = false;
                            this.notifications = data.body;
                            this.logger.trace(this.notifications);
                            break;
                        }
                    }
                },
                error => {
                    this.selectNav(5);
                    switch (error.status) {
                        case 401: {
                            //this.router.navigate(['/authentication/signin']);
                            this.loadingCartecheck = false;
                            break;
                        }
                        default: {
                            this.notifications = [];
                            this.loadingCartecheck = false;
                            this.logger.error(error);
                            break;
                        }
                    }
                });
        }
    }

    selectNav(i: number) {
        for (let j = 0; j < this.dataMenu.length; j++) {
            $('#nav' + j).removeClass("active");
        }
        $('#nav' + i).addClass('active');
    }

    _lettre(spectateurnom: string) {
        return spectateurnom.substring(0, 1).toUpperCase();
    }

    _split(caticket: string) {

        let d = caticket.split(" ");
        let r: string = "";
        if (d.length > 1) {
            r = d[0].substring(0, 1) + "" + d[1].substring(0, 1)
        } else {
            r = caticket;
        }
        return r;
    }
}
