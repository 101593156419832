import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, Validators} from '@angular/forms';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {NGXLogger} from 'ngx-logger';
import {environment} from 'src/environments/environment';
import {EventwithkeyService} from '../home-billeterie/eventwithkey.service';
import {EventwithoutkeyService} from '../home-billeterie/eventwithoutkey.service';
import {CommentaireDto, Spectateur} from '../spectateur';
import {cryptSession, decode64} from "../../../core/config";
import {NzModalService} from "ng-zorro-antd/modal";
import {MaskApplierService} from "ngx-mask";

declare let $: any;

@Component({
    selector: 'app-eventdetails-billeterie',
    templateUrl: './eventdetails-billeterie.component.html',
    styleUrls: ['./eventdetails-billeterie.component.scss']
})
export class EventdetailsBilleterieComponent implements OnInit {
    dataMenu: any = [
        {
            uri: "/",
            label: "Accueil",
        }, {
            uri: "/concert",
            label: "Découvrir",
        }, {
            uri: "/connecter",
            label: "Mon profil",
        }
    ]

    event: any = [];
    loadingCheck: any = false;
    key: string;

    isConnect = false;


    loadingCommentaireCheck: any = false;


    commentaires: CommentaireDto[] = [];

    img: string = "https://static.vecteezy.com/ti/vecteur-libre/p3/1840612-image-profil-icon-male-icon-human-or-people-sign-and-symbol-vector-gratuit-vectoriel.jpg"
    public comentaireForm;


    constructor(private _activatedRoute: ActivatedRoute, private router: Router, private gestserv: EventwithoutkeyService,
                private gestwithkey: EventwithkeyService, public fb: UntypedFormBuilder,
                private logger: NGXLogger, private modal: NzModalService, private maskApplierService: MaskApplierService) {
    }

    ngOnInit(): void {


        this.comentaireForm = this.fb.group({
            'message': [null, Validators.required],
        });

        let u: any = {};
        u = JSON.parse(localStorage.getItem(`${environment.keylocalstorage}`));


        this._activatedRoute.queryParams.forEach((params: Params) => {
            console.log("params >>", params)
            if (params['key']) {
                this.key = params['key'];
                this.loadEvent(params['key']);
                if (u !== null) {
                    this.isConnect = true;
                    this.loadCommentaire();
                }
            } else {
                this.router.navigate(['/']);
            }
        });
    }


    goToRouting(elem: any) {
        this.router.navigateByUrl('/checkpayment?key=' + elem.caticketcode + '&type=' + this.event.typeventcode + '&caticketlib=' + elem.caticketlib);
    }

    loadEvent(code) {
        this.loadingCheck = false;
        this.gestserv.get("/billeterie/event/" + code).subscribe(data => {
                switch (data.status) {
                    case 200: {
                        this.selMenu(1);
                        this.event = data.body;

                        let mapSession = cryptSession(JSON.stringify(this.event), decode64(environment.APP_PASS));
                        localStorage.setItem(environment.APP_TOKEN_ACHAT, mapSession)

                        $('#myImg').css({
                            'background-image': 'url(' + this.event.url + ')',
                            'background-repeat': 'no-repeat',
                            'background-size': 'cover'
                        });
                        this.logger.trace(this.event);
                        this.loadingCheck = true;
                        break;
                    }


                }
            },
            error => {
                switch (error.status) {
                    case 401: {
                        //this.router.navigate(['/authentication/signin']);
                        this.loadingCheck = true;
                        break;
                    }
                    default: {
                        this.loadingCheck = true;
                        this.logger.trace(error);

                        break;
                    }
                }
            });
    }


    loadCommentaire() {

        let u: Spectateur;
        this.loadingCommentaireCheck = true;
        u = JSON.parse(localStorage.getItem(`${environment.keylocalstorage}`));


        this.comentaireForm = this.fb.group({
            'message': [null, Validators.required],
        });

        if (u) {


            this.gestwithkey.get("/billeterie/commentaire/byspectateur/" + u.spectateurcode + "/" + this.key, u.token).subscribe(data => {
                    switch (data.status) {
                        case 200: {
                            this.loadingCommentaireCheck = false;
                            this.commentaires = data.body;
                            this.logger.trace("MES COMMENTAIRES ==> ");

                            this.logger.trace(this.commentaires);
                            break;
                        }
                    }
                },
                error => {
                    switch (error.status) {
                        case 401: {
                            //this.router.navigate(['/authentication/signin']);
                            this.loadingCommentaireCheck = false;
                            break;
                        }
                        default: {
                            this.loadingCommentaireCheck = false;
                            this.logger.error(error);
                            break;
                        }
                    }
                });
        }


    }


    saveCommentaire() {
        let u: Spectateur;
        this.loadingCommentaireCheck = true;
        u = JSON.parse(localStorage.getItem(`${environment.keylocalstorage}`));


        if (!this.comentaireForm.valid) {
            return;
        }


        if (u) {
            let request: any = this.comentaireForm.value;
            request.spectateurcode = u.spectateurcode;
            request.eventcode = this.key;

            this.gestwithkey.post(request, "/billeterie/commentaire/add", u.token).subscribe(data => {
                    this.logger.trace('INFOS CNX', data);

                    switch (data.status) {
                        case 201: {
                            this.modal.success({
                                nzTitle: '<b>COMMENTAIRE</b>',
                                nzContent: `<p>Votre commentaire a été soumis avec succès, nous vous en remercions.</p>`,
                                nzOkText: 'OK',
                                nzCentered: true,
                                nzOnOk: () => {
                                }
                            });
                            this.loadCommentaire();
                            break;
                        }
                    }
                },
                error => {
                    //this.loader.close();

                    this.logger.error('ERROR', error);
                    switch (error.status) {


                        default: {
                            this.loadCommentaire();

                            break;
                        }
                    }
                });
        }


    }

    selMenu(i: number) {
        for (let j = 0; j < this.dataMenu.length; j++) {
            $('#ligneMenu' + j).removeClass("activeItem");
        }
        $('#ligneMenu' + i).addClass('activeItem');
    }

    formatNumber(value) {
        if (typeof value === 'number') {
            value = value.toString();
        }
        // Formatage avec 3 chiffres après la virgule
        return this.maskApplierService.applyMask(value, 'separator.3');
    }

}
