<section class="mission-area"  id="myImgApropos" style="background-color: #0B2154 !important;">
    <app-navbar></app-navbar>

    <div class="login-area" style="background-color: #0b2154;height: 150vh;">

        <div class="row m-0">
            <div class="col-lg-8 col-md-12" id="enImgg">
                <!--                    <img src="assets/img/digit.png" class="igmS" alt="LOGO NBH">-->
            </div>
            <div class="col-lg-4 col-md-12 p-0">

                <div class="login-content">
                    <div class="d-table"  style="height: 82% !important;">

                        <div class="d-table-cell">

                            <div class="login-form">
                                <div class="header">
                                    <div class="dash left tRet {{current==1?'LongtRet':''}} {{current==2?'LongtRetLast':''}} {{current==3?'successtRetLast':''}}"></div>
                                    <h3>Créez votre compte</h3>
                                    <div class="dash right tLeft {{current==1?'LongtLeft':''}} {{current==2?'LongtLeftLast':''}} {{current==3?'successtLeftLast':''}}"></div>
                                </div>

                                <strong *ngIf="checking" style="color: red;">{{checkingMessage}}</strong>

                                <div class="billing-details">

                                    <br><br>
                                    <nz-steps [nzCurrent]="current" nzSize="small">
                                        <nz-step nzTitle="Etape 1"> </nz-step>
                                        <nz-step nzTitle="Etape 2"> </nz-step>
                                        <nz-step nzTitle="Confirmation"></nz-step>
                                    </nz-steps>


                                    <div>
                                    </div>

                                    <form [formGroup]="codeForm" (ngSubmit)="verifCode()" *ngIf="current>1 && current<=2">

                                        <div class="row" style="margin: 0px 20px 0px 20px !important;">
                                            <div class="col-12">
                                                <strong  style="color: green;text-align: left">
                                                    Un code de validation vous a été envoyé par SMS. <br>
                                                    Merci le renseigner pour continuer.
                                                </strong>
                                            </div>
                                            <br>
                                            <br>
                                            <div class="col-12 mt-4">
                                                <div class="form-group">
                                                    <label  class="labelDis">CODE DE VALIDATION <span class="required">*</span></label>
                                                    <input type="text"
                                                           [formControl]="codeForm.controls['code']"
                                                           class="form-control inpForm">
                                                    <strong *ngIf="checkingCode"
                                                            style="color: red;">{{checkingCodeMessage}}</strong>
                                                </div>
                                            </div>

                                        </div>
                                        <div class="button-wrapper" style="margin-top: 0px !important;"  *ngIf="current == 2">
                                            <div class="dash left"></div>
                                            <button nz-button nzType="primary" type="submit" class="btnCnx">  <i class="bx bx-loader bx-spin  bx-md" style="font-size: 1.25rem !important; margin-right: 5px" *ngIf="isLoad"></i> Verifier</button>
<!--                                            <button nz-button nzType="default" class="btnCnx" (click)="next()">-->
<!--                                                Suivant-->
<!--                                            </button>-->
                                            <div class="dash right"></div>
                                        </div>


                                    </form>


                                    <form [formGroup]="registerForm" *ngIf="current<=1">

                                        <div *ngIf="current ==0" class="row input_0">
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <label  class="labelDis">VOTRE PAYS <span class="required">*</span></label>
                                                    <nz-select [(ngModel)]="currentInd" (ngModelChange)="changeInd($event)"
                                                               nzShowSearch
                                                               [nzNotFoundContent]="'Aucun resultat !'"
                                                               [formControl]="registerForm.controls['indicatifPays']">
                                                        <nz-option  [nzLabel]="data.country" [nzValue]="data.indicatif"  *ngFor="let data of dataContry"></nz-option>
                                                    </nz-select>

                                                    <!--                                                <select [formControl]="registerForm.controls['indicatifPays']"-->
                                                    <!--                                                        class="form-control inpForm form-control">-->
                                                    <!--                                                    <option *ngFor="let data of dataContry" [value]="data.indicatif">-->
                                                    <!--                                                        <i class="flag-icon flag-icon-{{ data.code }}"></i>   {{data.country}}-->
                                                    <!--                                                    </option>-->
                                                    <!--                                                </select>-->
                                                    <strong class="errorText" *ngIf="checkingPays"
                                                            style="color: red;">{{checkingPaysMessage}}</strong>
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <label  class="labelDis">NOM <span class="required">*</span></label>
                                                    <input type="text"
                                                           [formControl]="registerForm.controls['spectateurnom']"
                                                           class="form-control inpForm inputTaille">
                                                    <strong class="errorText" *ngIf="checkingNom"
                                                            style="color: red;">{{checkingNomMessage}}</strong>
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <label  class="labelDis">PRENOMS<span class="required">*</span></label>
                                                    <input type="text"
                                                           [formControl]="registerForm.controls['spectateurprenom']"
                                                           class="form-control inpForm inputTaille">
                                                    <strong class="errorText" *ngIf="checkingPrenom"
                                                            style="color: red;">{{checkingPrenomMessage}}</strong>

                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <label  class="labelDis">TELEPHONE <span class="required">*</span></label>
                                                    <div class="input-container">
                                                        <div class="icon-column">
                                                            <i class="icon  flag-icon flag-icon-{{currentIcon}}"></i> <b style="align-items: center;display: flex;">+{{currentInd}}</b>
                                                        </div>
                                                        <div class="input-column">
                                                            <input type="text" style="border-radius: 0px 5px 5px 0px !important;" [formControl]="registerForm.controls['spectateurtel']" class="form-control inpForm">

                                                        </div>

                                                    </div>
                                                    <strong class="errorText" *ngIf="checkingTel"
                                                            style="color: red;">{{checkingTelMessage}}</strong>
                                                </div>
                                            </div>
                                        </div>

                                        <div *ngIf="current ==1" class="row inscriptionInput">
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <label  class="labelDis">EMAIL</label>
                                                    <input type="email"
                                                           [formControl]="registerForm.controls['spectateuremail']"
                                                           class="form-control inpForm">
                                                    <strong *ngIf="checkingEmail"
                                                            style="color: red;">{{checkingEmailMessage}}</strong>
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <label class="labelDis">MOT DE PASSE (La longueur du mot de passe doit être de 5 chiffres) <span
                                                        class="required">*</span></label>
                                                    <input [formControl]="registerForm.controls['spectateurpsswd']"
                                                           type="password" maxlength="5" minlength="5" name="spectateurpsswd" id="spectateurpsswd"
                                                           class="form-control inpForm" oninput="this.value = this.value.replace(/[^0-9]/g, '');">
                                                    <strong *ngIf="checkingPsswd"
                                                            style="color: red;">{{checkingPsswdMessage}}</strong>
                                                </div>
                                                <div class="form-group">
                                                    <label class="labelDis">CONFIRMER LE MOT DE PASSE <span
                                                        class="required">*</span></label>
                                                    <input [formControl]="registerForm.controls['confspectateurpsswd']"
                                                           type="password" maxlength="5" minlength="5" name="confspectateurpsswd" id="confspectateurpsswd"
                                                           class="form-control inpForm"  oninput="this.value = this.value.replace(/[^0-9]/g, '');">
                                                    <strong *ngIf="confcheckingPsswd"
                                                            style="color: red;">{{confcheckingPsswdMessage}}</strong>
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <label class="labelDis">DATE DE NAISSANCE <span class="required">*</span></label>
                                                    <input [formControl]="registerForm.controls['spectateurdatenais']"
                                                           type="date" class="form-control inpForm">
                                                    <strong *ngIf="checkingDate"
                                                            style="color: red;">{{checkingDateMessage}}</strong>
                                                </div>
                                            </div>
                                            <div class="col-12">

                                                <div class="form-group">
                                                    <label class="labelDis">SEXE <span class="required">*</span></label>
                                                    <select [formControl]="registerForm.controls['spectateursexe']"
                                                            class="form-control inpForm">
                                                        <option value="un homme">HOMME</option>
                                                        <option value="une femme">FEMME</option>
                                                    </select>
                                                    <strong *ngIf="checkingSex"
                                                            style="color: red;">{{checkingSexMessage}}</strong>
                                                </div>

                                            </div>
                                            <div class="col-12">
                                                <p style="">
                                                    <label style="color: #fff !important;" nz-checkbox [formControl]="registerForm.controls['isCondition']" [(ngModel)]="isCheckedButton" [nzDisabled]="isDisabledButton">
                                                        {{ 'J\'accepte les' }}
                                                    </label> <a (click)="openNewTab('/condition')" style="color: #fff"><b>conditions d'utilisation</b></a>
                                                </p>
                                            </div>
                                        </div>

                                    </form>
                                    <!--                                    <button type="submit"> <i class="bx bx-loader bx-spin  bx-md" style="font-size: 1.25rem !important; margin-right: 5px" *ngIf="isLoad"></i> S'inscrire</button>-->
                                    <div class="steps-action">

                                        <div class="button-wrapper" *ngIf="current > 0 && current < 2">
                                            <div class="dash left"></div>

                                            <button  class="btnCnx" nz-button nzType="default" style="margin-right: 21px;" (click)="pre()" *ngIf="current > 0 && current<=1">
                                                <span>Précédent</span>
                                            </button>

                                            <button  class="btnCnx" nz-button [nzLoading]="isLoad" [disabled]="!isCheckedButton" nzType="primary" (click)="register()" *ngIf="current === 1">
                                                <span>S'inscrire </span>
                                            </button>

                                            <div class="dash right"></div>
                                        </div>

                                        <div class="button-wrapper"  *ngIf="current < 1">
                                            <div class="dash left"></div>
                                            <button nz-button nzType="default" class="btnCnx" (click)="next()">
                                                Suivant
                                            </button>
                                            <div class="dash right"></div>
                                        </div>

                                    </div>
                                    <div  *ngIf="current > 2">
                                        <br>
                                        <div class="success-container">
                                            <div class="icon-container">
                                                <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                                    <circle class="checkmark-circle" cx="26" cy="26" r="25" fill="none"/>
                                                    <path class="checkmark-check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
                                                </svg>
                                                <h2>SUCCES</h2>
                                            </div>
                                            <div class="success-message">
                                                <p style="font-size: 20px">Félicitations, votre compte a été activé avec succès.</p>
                                                <br>
                                                <button id="continue-button" (click)="connect()">Connectez vous</button>
                                            </div>
                                        </div>

                                        <div class="dash left llast"></div>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                    <p *ngIf="current!=3" class="textBottom {{current==1?'textCnx':''}} {{current==0?'textCnx0':''}} {{current==2?'textCnxLast':''}}"><a routerLink="/connecter" class="aCreer">Se connecter</a></p>
                </div>
            </div>
            <div class="col-lg-8 col-md-12"  id="enImg">
                <div class="spanStyle">
<!--                    <img src="assets/img/digit.png" class="igmS" alt="LOGO NBH">-->
                </div>
                <div class="txt"></div>
            </div>
        </div>
    </div>
</section>




<div class="register-area" style="display: none">
    <div class="row m-0">
        <div class="col-lg-6 col-md-12 p-0">

            <div class="register-image">
                <!--
                <img src="assets/img/register-bg.jpg" alt="image">
                -->
            </div>


        </div>
        <div class="col-lg-6 col-md-12 p-0">
            <div class="register-content">
                <div class="d-table">
                    <div class="d-table-cell">
                        <div class="register-form">
                            <div class="logo">
                                <a routerLink="/"><img src="assets/img/digit.jpg" style="height: 150px; width: 300px;"
                                                       alt="image"></a>
                            </div>
                            <h3>Créez votre compte</h3>
                            <p>vous aviez déja un compte ? <a routerLink="/connecter">se connecter </a></p>
                            <strong *ngIf="checking" style="color: red;">{{checkingMessage}}</strong>

                            <div class="billing-details">
                                <form [formGroup]="codeForm" (ngSubmit)="verifCode()" *ngIf="senderSms">

                                    <div class="row">
                                        <div class="col-12">
                                            <strong  style="color: green;text-align: left">
                                                Un code de validation vous a été envoyé par SMS. <br>
                                                Merci le renseigner pour continuer.
                                            </strong>
                                        </div>
                                        <br>
                                        <br>
                                        <div class="col-12 mt-4">
                                            <div class="form-group">
                                                <label>CODE DE VALIDATION <span class="required">*</span></label>
                                                <input type="text"
                                                       [formControl]="codeForm.controls['code']"
                                                       class="form-control">
                                                <strong *ngIf="checkingCode"
                                                        style="color: red;">{{checkingCodeMessage}}</strong>
                                            </div>
                                        </div>

                                    </div>

                                    <button type="submit">Verifier</button>

                                </form>
                                <form [formGroup]="registerForm" (ngSubmit)="register()" *ngIf="!senderSms">

                                    <div class="row">
                                        <div class="col-lg-6 col-md-6">
                                            <div class="form-group">
                                                <label>NOM <span class="required">*</span></label>
                                                <input type="text"
                                                       [formControl]="registerForm.controls['spectateurnom']"
                                                       class="form-control">
                                                <strong *ngIf="checkingNom"
                                                        style="color: red;">{{checkingNomMessage}}</strong>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-6">
                                            <div class="form-group">
                                                <label>PRENOMS <span class="required">*</span></label>
                                                <input type="text"
                                                       [formControl]="registerForm.controls['spectateurprenom']"
                                                       class="form-control">
                                                <strong *ngIf="checkingPrenom"
                                                        style="color: red;">{{checkingPrenomMessage}}</strong>

                                            </div>
                                        </div>
                                    </div>


                                    <div class="row">


                                        <div class="col-lg-6 col-md-6">
                                            <div class="form-group">
                                                <label>TELEPHONE <span class="required">*</span></label>
                                                <input [formControl]="registerForm.controls['spectateurtel']"
                                                       type="text" class="form-control">
                                                <strong *ngIf="checkingTel"
                                                        style="color: red;">{{checkingTelMessage}}</strong>
                                            </div>
                                        </div>

                                        <div class="col-lg-6 col-md-6">
                                            <div class="form-group">
                                                <label>EMAIL</label>
                                                <input type="text"
                                                       [formControl]="registerForm.controls['spectateuremail']"
                                                       class="form-control">
                                                <strong *ngIf="checkingEmail"
                                                        style="color: red;">{{checkingEmailMessage}}</strong>
                                            </div>
                                        </div>

                                        <div class="col-lg-12 col-md-12">
<!--                                            <div class="form-group">-->
<!--                                                <label>MOT DE PASSE AU MOINS 6 CARACTERES <span-->
<!--                                                    class="required">*</span></label>-->
<!--                                                <input [formControl]="registerForm.controls['spectateurpsswd']"-->
<!--                                                       type="password" name="spectateurpsswd" id="spectateurpsswd"-->
<!--                                                       class="form-control">-->
<!--                                                <strong *ngIf="checkingPsswd"-->
<!--                                                        style="color: red;">{{checkingPsswdMessage}}</strong>-->
<!--                                            </div>-->
                                        </div>




                                        <div class="col-lg-6 col-md-6">
                                            <div class="form-group">
                                                <label>DATE DE NAISSANCE <span class="required">*</span></label>
                                                <input [formControl]="registerForm.controls['spectateurdatenais']"
                                                       type="date" class="form-control">
                                                <strong *ngIf="checkingDate"
                                                        style="color: red;">{{checkingDateMessage}}</strong>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-6">

                                            <div class="form-group">
                                                <label>SEXE <span class="required">*</span></label>
                                                <select [formControl]="registerForm.controls['spectateursexe']"
                                                        class="form-control">
                                                    <option value="un homme">HOMME</option>
                                                    <option value="une femme">FEMME</option>
                                                </select>
                                                <strong *ngIf="checkingSex"
                                                        style="color: red;">{{checkingSexMessage}}</strong>
                                            </div>



                                        </div>




                                    </div>





                                    <button type="submit">S'inscrire</button>

                                </form>


                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!--<br>-->
<!--<app-news-billeterie></app-news-billeterie>-->





<nz-modal [(nzVisible)]="isVisible"
          nzTitle="Conditions d'Utilisation de la Plateforme NBH"
          (nzOnCancel)="handleCancel()"
          (nzOnOk)="handleCancel()"
          [nzWidth]="'800px'"
          [nzFooter]="modalFooter"
>
    <ng-container *nzModalContent>
        <div class="container">
          <app-cond-use-nbh></app-cond-use-nbh>
        </div>
    </ng-container>
    <ng-template #modalFooter>
        <button nz-button nzType="primary" (click)="handleCancel()" >Fermer</button>
    </ng-template>
</nz-modal>
