<section>
    <h2>1. Introduction</h2>
    <p>
        Les présentes conditions d'utilisation (le "Contrat") régissent l'accès et l'utilisation des services fournis
        par la plateforme NBH (Nouvelle Billetterie Hybride) accessible à l'adresse <a href="www.nbh.ci">www.nbh.ci.</a>  En utilisant ce site,
        vous acceptez ces conditions, notre politique de confidentialité, ainsi que les lois et règlements applicables.
    </p>
</section>
<section>
    <h2> 2. Modifications </h2>
    <p>
        La NBH se réserve le droit de modifier ces conditions à tout moment sans préavis. Il est recommandé de consulter
        régulièrement les conditions pour être informé des éventuelles modifications.
    </p>
</section>
<section>
    <h2> 3. Utilisation autorisée </h2>
    <p>
        L'accès à la plateforme est accordé pour un usage personnel et non commercial. Toute utilisation à des fins
        commerciales, y compris la revente de billets, est interdite.
    </p>
</section>
<section>
    <h2> 4. Utilisation interdite Il est interdit de : </h2>
    <ul>
        <li>Utiliser des robots, scripts ou autres dispositifs automatisés pour accéder au contenu du site.</li>
        <li>Acheter des billets en grande quantité pour les revendre.</li>
        <li>Contourner les mesures de sécurité du site.</li>
        <li>Publier ou transmettre des contenus illégaux ou nuisibles.</li>
    </ul>

</section>
<section>
    <h2>  5. Frais de transaction </h2>
    <p>
        L'achat de billets peut entraîner des frais supplémentaires, tels que des frais de commodité et de traitement.
        Ces frais seront clairement indiqués lors de l'achat.
    </p>
</section>
<section>
    <h2> 6. Liens vers d'autres sites</h2>
    <p>Le site peut contenir des liens vers des sites tiers. La NBH n'est pas responsable du contenu ou des pratiques de
        ces sites.
    </p>
</section>
<section>
    <h2> 7. Communications électroniques</h2>
    <p>
        En utilisant le site, vous consentez à recevoir des communications électroniques de la part de la NBH, y compris
        des notifications concernant vos achats et des publicités sur nos produits et services.
    </p>
</section>
<section>
    <h2>8. Propriété intellectuelle</h2>
    <p>
    La NBH détient les droits de propriété intellectuelle sur tout le contenu de la
    plateforme. Vous ne pouvez pas reproduire, modifier ou distribuer ce contenu sans autorisation préalable.</p>
</section>
<section>
    <h2>  9. Limitation de responsabilité</h2>
    <p>
        La plateforme est fournie "telle quelle" sans garantie d'aucune sorte. La NBH ne sera pas responsable des
        dommages résultant de l'utilisation du site ou de l'incapacité à l'utiliser.
    </p>
</section>
<section>
    <h2>  10. Équipement</h2>
    <p> Vous êtes responsable des équipements nécessaires pour accéder au site, y compris les frais de connexion.
    </p>
</section>
<section>
    <h2>  11. Respect des droits d'auteur</h2>
    <p>
        La NBH respecte les droits d'auteur et agira en cas de violation présumée. Les titulaires de droits peuvent
        soumettre une notification de contrefaçon conformément à la loi ivoirienne.
    </p>
</section>
<section>
    <h2> 12. Contenu de tiers</h2>
    <p>
        Le contenu fourni par des tiers sur le site n'est pas nécessairement approuvé par la NBH. Nous ne garantissons
        pas l'exactitude de ce contenu.
    </p>
</section>
<section>
    <h2> 13. Infractions</h2>
    <p>
        En cas de violation des conditions, la NBH peut annuler des transactions et restreindre l'accès au site sans
        préavis.
    </p>
</section>
<section>
    <h2>14. Services Mobiles</h2>
    <p>
        Les services mobiles fournis par la NBH sont gratuits, mais des frais de votre opérateur peuvent s'appliquer.
    </p>
</section>
<section>
    <h2>15. Contact</h2>
    <p>
        Pour signaler des violations ou pour toute autre question, veuillez contacter NBH à l'adresse : <a
        href="info@nbh.ci">info@nbh.ci.</a>
    </p>
</section>
