<app-header-billeterie></app-header-billeterie>

<section class="mission-area ptb-100" style="padding-top: 0;height: 350px !important;z-index: 1;position: relative;">
    <section class="mission-area"  id="myImgApropos" style="background-color: #006837 !important;height: 350px !important;">
        <app-navbar></app-navbar>
<!--        <div class="container" style="display: flex;justify-content: center">-->
<!--            <h1 style="margin-top: auto;margin-bottom: auto;color: #0B2154 !important;font-family: Montserrat;font-size: 30px;line-height: 0.9;">Politique de confidentialité</h1> <img class="imgApropos" src="{{imgPay}}">-->
<!--        </div>-->

        <div class="container _contentText">
            <h1 class="_titre">Politique de confidentialité</h1>
            <img class="imgApropos" src="{{imgPay}}">
        </div>

    </section>
</section>

<div class="courses-details-area  pb-70"  style="background-color: #0B2154;min-height: 100%;text-align: justify">
    <div class="container">

        <div class="row">
            <div class="col-lg-2">

            </div>

            <div class="col-lg-8">

                <h3 style="text-align: center;margin-top: 15px;text-transform: uppercase">
                    Politique de Confidentialité des Utilisateurs
                </h3>

                <div class="courses-details-desc">
<!--                    <h3>-->
<!--                        VEUILLEZ LIRE ATTENTIVEMENT CETTE POLITIQUE DE CONFIDENTIALITÉ-->
<!--                        (CETTE « POLITIQUE DE CONFIDENTIALITÉ ») AVANT D&#39;UTILISER CE SITE WEB,-->
<!--                        APPLICATION OU TOUT AUTRE SERVICE DE www.nbh.ci.-->
<!--                    </h3>-->
                    <p>
                        Veuillez lire attentivement cette politique de confidentialité (la « Politique de Confidentialité ») avant d'utiliser ce site Web, l'application ou tout autre service de <a href="www.nbh.ci" style="color: #fff">www.nbh.ci</a>.
                        <br>
                        NBH est un produit de la Nouvelle Billetterie Hybride, spécialisée dans le développement informatique et les technologies avancées. Nous offrons une billetterie moderne pour divers événements avec un système de contrôle d'accès automatique ou semi-automatique.
                        <br>
                        La protection de vos informations personnelles est cruciale pour nous. Nous avons mis en place cette politique pour assurer la sécurité et la confidentialité de vos données. Cette politique s'applique à tous les services de NBH disponibles sur notre site Web et nos applications, désignés collectivement comme les « Services de la Société ». En utilisant nos Services, vous acceptez cette politique de confidentialité.
                    </p>

                    <h3 style="text-transform: uppercase">Qui sommes-nous ?</h3>
                    <p>
                       <b> NBH (Nouvelle Billetterie Hybride)</b>
                        est une plateforme spécialisée dans la billetterie moderne pour divers événements, intégrant des technologies avancées pour un contrôle d'accès sécurisé.                    </p>
                    <h3 style="text-transform: uppercase">Informations Personnelles Collectées</h3>
                    <p>
                        Lors de la vente de billets, nous collectons les informations personnelles suivantes pour faciliter vos transactions et contrôles :                    </p>
                    <ul>
                        <li><b>Données personnelles : </b> Nom complet, adresse e-mail, numéro(s) de téléphone, nom d'utilisateur, mot de passe, etc...</li>
                        <li><b>Informations sur les transactions : </b> Détails des achats de billets et interactions avec les événements.</li>
                    </ul>
                    <p>
                        Nous collectons ces informations lorsque vous vous inscrivez, achetez des billets, participez à des promotions ou vous abonnez à nos services. Vous pouvez également acheter des billets en personne si vous préférez rester anonyme.                    </p>
                    <h3  style="text-transform: uppercase">Connexion obligatoire pour accéder aux événements</h3>

                    <p>
                        Pour garantir une expérience optimale et sécurisée, notre application exige que les utilisateurs se connectent à leur compte avant d'accéder à l'interface d'achat des événements.                    </p>
                    <p>Pourquoi est-ce important ?</p>
                    <ul>
                        <li>
                           <b> Accès simplifié aux billets : </b>Une fois connectés, les spectateurs peuvent consulter et retrouver tous leurs billets achetés, même en mode hors ligne.
                        </li>
                        <li>
                           <b>Sécurisation des données :</b>  La connexion garantit que vos billets et informations personnelles sont centralisés dans un espace sécurisé, accessible uniquement par vous.
                        </li>
                        <li>
                            <b>Conformité légale :</b>	 Dans certains cas, des restrictions d'accès peuvent être imposées par les organisateurs ou la réglementation, nécessitant une vérification via la connexion.
                        </li>
                        <li>
                            <b>Simplification des achats futurs :</b>  Vos informations sont sauvegardées, rendant les transactions futures plus rapides et fluides.
                        </li>
                    </ul>
                    <p>Nous restons engagés à offrir une expérience transparente et sécurisée à tous nos utilisateurs tout en respectant la réglementation.</p>

                    <h3  style="text-transform: uppercase">Utilisation et Partage des Informations Personnelles</h3>

                    <p>
                        Nous utilisons vos informations personnelles pour :
                    </p>
                    <ul>
                        <li><b> Traitement des transactions : </b>Effectuer les achats et communiquer avec vous concernant vos commandes.</li>
                        <li>
                            <b>Partage avec des tiers : </b> Vos informations peuvent être partagées avec des processeurs de paiement pour compléter les transactions et avec les hôtes d'événements pour vous informer des détails de l'événement.
                        </li>
                        <li>
                            <b>Fournisseurs de services :</b>  Nous partageons des informations avec des fournisseurs de services qui nous aident à gérer nos opérations et à vous fournir nos services, sous réserve qu'ils offrent un niveau similaire de protection des données.
                        </li>
                    </ul>

                    <h3   style="text-transform: uppercase">Objectif de la collecte des informations de genre et de date de naissance</h3>
                    <p>
                        Notre application collecte les informations de <b>genre</b>  et de <b> date de naissance</b> des utilisateurs pour des raisons précises et transparentes :
                    </p>
                        <ul>
                            <li> <b>Respect des exigences légales : </b> La date de naissance est collectée pour vérifier l'âge minimum requis pour accéder à certains événements, selon les restrictions imposées par les organisateurs ou la réglementation locale.
                            </li>
                            <li><b>Adapter le contenu et les fonctionnalités :</b>  En connaissant mieux les segments démographiques, nous pouvons offrir des suggestions de contenu plus pertinentes et adapter les offres ou les événements en fonction de l'âge et des intérêts.
                            </li>
                            <li><b>Amélioration de l'expérience utilisateur : </b>Ces données démographiques permettent de produire des statistiques anonymisées afin de mieux comprendre notre audience. Nous utilisons ces informations pour adapter les contenus et proposer des événements ou services correspondant aux préférences des différents segments démographiques.
                            </li>
                            <li><b>Amélioration des analyses statistiques :</b> Les informations démographiques nous permettent d'obtenir des statistiques globales et anonymisées sur les types d'utilisateurs qui interagissent avec notre application. Ces statistiques sont cruciales pour le développement de notre produit et pour garantir qu'il reste attractif et pertinent.
                            </li>
                        </ul>

                    <p>Toutes les données collectées sont traitées de manière anonyme et agrégée, en respectant strictement les normes de confidentialité, telles que le RGPD.</p>
                    <h3   style="text-transform: uppercase">
                        Respect de la confidentialité et protection des données
                    </h3>

                    <p>
                        Nous nous engageons à respecter strictement les normes de confidentialité de l'App Store et la réglementation en vigueur (RGPD par exemple). Toutes les données sont <b> traitées de manière anonyme et agrégée</b>, sans aucun lien avec l'identité personnelle de l'utilisateur. Les utilisateurs sont informés de la collecte de ces données et peuvent refuser de les fournir s'ils le souhaitent.
                    </p>
                    <h3 style="text-transform: uppercase">Cookies et Informations Techniques</h3>
                    <p>
                        Nous utilisons des cookies et des technologies similaires pour :
                    </p>
                    <ul>
                        <li><b>Collecte de données techniques : </b> Adresse IP, type de navigateur, système d'exploitation, durée de la visite.</li>
                        <li><b>Amélioration des services : </b> Analyser et améliorer les performances et la fonctionnalité de nos services.</li>
                    </ul>
                    <p>
                        Nous associons parfois ces informations techniques avec vos données personnelles pour mieux vous identifier et personnaliser votre expérience.
                    </p>
                    <h3   style="text-transform: uppercase">Réseaux Sociaux et Données de Localisation</h3>
                    <ul>
                        <li><b>Réseaux sociaux : </b> Vous pouvez lier vos comptes de réseaux sociaux à nos services. Nous pouvons partager des informations avec ces réseaux sociaux et accéder à certaines données que vous avez choisies de partager avec eux.</li>
                    <li><b>Données de localisation : </b>	Nous pouvons collecter vos données de localisation pour améliorer votre expérience. Vous pouvez activer ou désactiver cette fonctionnalité via les paramètres de votre appareil.</li>
                    </ul>

                <h3  style="text-transform: uppercase">Conservation des Données</h3>

                    <p>
                        Nous conservons vos informations personnelles pour la durée nécessaire aux fins spécifiées et en conformité avec les exigences légales, fiscales ou commerciales. La durée maximale de conservation est  de <b> 6 ans</b> après votre dernière transaction, sauf si prolongée pour des raisons spécifiques.
                    </p>
                <h3  style="text-transform: uppercase">Sécurité des Données</h3>

                    <p>
                        Nous mettons en place des mesures de sécurité pour protéger vos informations contre les accès non autorisés, les modifications et les divulgations. Cela inclut le cryptage et la sécurité physique de nos installations. Cependant, aucune méthode de sécurité n'est infaillible, et nous ne pouvons garantir une protection absolue contre les cyberattaques.
                    </p>

                    <h3  style="text-transform: uppercase">Liens Externes</h3>

                    <p>
                        Nos services peuvent contenir des liens vers des sites Web que nous ne contrôlons pas. Nous ne sommes pas responsables des pratiques de confidentialité de ces sites. Nous vous encourageons à lire leurs politiques de confidentialité.
                    </p>
                <h3   style="text-transform: uppercase">Informations sur les Mineurs</h3>
                    <p>
                        Nous ne collectons pas sciemment d'informations personnelles d'enfants de moins de 13 ans sans le consentement parental. Si nous découvrons que des informations d'un enfant de moins de 13 ans ont été collectées, nous prendrons des mesures pour les supprimer.
                    </p>
                    <h3  style="text-transform: uppercase">Suppression de Compte</h3>
                    <p>
                        Pour supprimer votre compte, envoyez un e-mail à <a href="javascript:void(0)" style="color: #fff"></a> supporttechnique@nbh.ci
                    </p>
                    <h3 style="text-transform: uppercase">Conditions d'Utilisation</h3>
                    <p>
                        Veuillez consulter nos Conditions d'utilisation pour les termes régissant l'utilisation de nos services.
                    </p>
                    <h3 style="text-transform: uppercase">Besoin d'assistance ?</h3>
                    <p>
                        Pour toute question ou demande d'assistance, <b> consultez notre page d'aide et d'assistance </b>( <a style="color: #fff" href="https://nbh.ci/faq">https://nbh.ci/faq</a> )ou écrivez nous a l’adresse suivante : <b>supporttechnique@nbh.ci</b>
                    </p>
                    <h3 style="text-transform: uppercase">Modifications de la Politique de Confidentialité</h3>
                    <p>
                        Nous pouvons modifier cette politique de confidentialité de temps à autre. La version la plus récente régira notre utilisation de vos informations. Nous vous informerons des changements importants par avis sur nos services ou par e-mail. Il est conseillé de consulter régulièrement cette page pour les mises à jour.
                    </p>

<!--                    <h3 style="text-transform: uppercase">Connexion obligatoire pour accéder à l'achat d'événements</h3>-->
<!--                    <p>-->
<!--                        Pour garantir une expérience optimale, nous exigeons que les spectateurs  <b>se connectent à leur compte</b> avant d'accéder à l'interface d'achat d'événements.-->
<!--                    </p>-->
<!--                    <ul>-->
<!--                        <li><b>Accès à vos billets à tout moment : </b> Une fois connectés, les spectateurs peuvent consulter et retrouver tous leurs billets achetés, même en mode hors ligne.</li>-->
<!--                        <li><b>Sécurisation des données : </b> Cette connexion permet de centraliser vos achats dans un espace sécurisé, accessible uniquement par vous.</li>-->
<!--                        <li><b>Simplification des achats futurs : </b> Vos informations sont sauvegardées, rendant les transactions futures plus rapides et fluides.</li>-->
<!--                    </ul>-->
<!--                    <p>-->
<!--                        Nous vous recommandons fortement de vous connecter avant toute opération afin de profiter pleinement de ces avantages.-->
<!--                    </p>-->
                </div>

            </div>
            <div class="col-lg-2">

            </div>
        </div>
    </div>
</div>
