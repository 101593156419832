<div class="contact-info-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="contact-info-box mb-30">

                    <h3>Votre place garantie</h3>
                    <p>
                        Soyez le VIP de votre prochain évènement!
                        Fini les heures d’attentes, les bousculades et le brouhaha à l’entrée des lieux de spectacle.
                        Achetez votre ticket depuis internet avec votre pc ou votre téléphone ou meme dans les points de ventes habituels, votre place est garanti.
                    </p>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="contact-info-box mb-30">

                    <h3>Une équipe pour vous accompagner</h3>
                    <p>
                        En cas de difficulté pendant l’achat, notre équipe vous accompagne.
                    </p>
                </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="contact-info-box mb-30">

                    <h3>
                        Des organisateurs sérieux
                    </h3>
                    <p>
                        Pour vous simplifier la vie et vous rassurer, nous sélectionnons pour vous les évènements
                        diffusés sur notre plateforme.
                    </p>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="contact-info-box mb-30">

                    <h3>Paiement Orange money, MTN, Flooz et carte Bancaire.
                    </h3>
                    <p>
                        Plus besoin de cash.
                        Réglez votre achat de ticket directement sur mobile.
                        TICKEY integre les paiment par Orange Money, MTN Money, Flooz Money et Bancaire
                    </p>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">

            </div>

            <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="contact-info-box mb-30">

                    <h3>
                        Un gain de temps considérable
                    </h3>
                    <p>
                        Notre plateforme est disponible 7jours/7 ; 24h/24 ; 365 jours/ an.
                        Il vous faudra bien 60 secondes pour réserver votre ticket d’evenement.
                        Soi-xan-te secondes!!
                    </p>
                </div>
            </div>


            <div class="col-lg-3 col-md-6 col-sm-6">

            </div>


        </div>
    </div>
</div>

<div class="contact-info-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="contact-info-box mb-30">

                    <h3>Comment vais-je recevoir mon ticket ?</h3>
                    <p>
                        Juste après achat, nous vous enverrons votre ticket dans votre espace client en ligne, aussi une
                        notification et un SMS de confirmation de l’achat.
                        Cette opération étant systématique et automatique, il n’y a presque jamais de risque d’erreur.
                    </p>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="contact-info-box mb-30">

                    <h3>Est-ce que le billet coûte plus cher ?</h3>
                    <p>
                        Pas du tout! Au contraire, nous faisons très souvent des promotions avec des prix moins que dans
                        mes points de vente habituels !
                    </p>
                </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="contact-info-box mb-30">

                    <h3>
                        Comment sera contrôlé mon ticket à l'entrée ?
                    </h3>
                    <p>
                        Avant de monter dans la salle, vous devrez présenter à l’organisateur votre ticket sous forme de
                        QR code (si elle est disponible sur votre application en ligne) ou présenter simplement la carte
                        DIGIBIT dont vous avez utilisé le numéro pour effectuer l’achat. Nous interdisons formellement
                        toute impression et n’envoyons jamais de ticket par email
                    </p>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="contact-info-box mb-30">

                    <h3>
                        J'ai perdu ma carte, comment faire ?
                    </h3>
                    <p>
                        En cas de perte de votre carte DIGIBIT contenant un ticket d’évènement, ne paniquez pas. Nous
                        vous donnons la possibilité de bloquer votre carte vous-même grâce à une fonctionnalité intégrée
                        dans votre espace client.
                        TICKEY, nous avons pensé à tout pour vous rendre heureux.
                    </p>
                </div>
            </div>



        </div>
    </div>
</div>
