import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
    selector: '[appMaxLength]'
})
export class MaxLengthDirective {
    @Input('appMaxLength') maxLength: number = 5;

    constructor(private el: ElementRef) {}

    @HostListener('input', ['$event']) onInput(event: Event): void {
        const input = this.el.nativeElement as HTMLInputElement;
        if (input.value.length > this.maxLength) {
            input.value = input.value.slice(0, this.maxLength); // Truncate input to maxLength
        }
    }

    @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent): void {
        const input = this.el.nativeElement as HTMLInputElement;
        if (
            input.value.length >= this.maxLength &&
            event.key !== 'Backspace' &&
            event.key !== 'Delete' &&
            !event.ctrlKey && !event.metaKey
        ) {
            event.preventDefault(); // Block additional input
        }
    }
}
