<app-header-billeterie></app-header-billeterie>



<div class="my-dashboard-area ptb-100">
    <div class="container">

        <div class="myDashboard-content text-center">
            <form class="edit-account">

                <div class="row">

                    <div class="col-lg-12 col-md-12">
                        <legend *ngIf="stepDesactivation==0">Vous souhaitez vous désabonner de NBH ?</legend>
                        <legend *ngIf="stepDesactivation==1">votre adresse email</legend>
                        <legend *ngIf="stepDesactivation==2">votre nom complet</legend>
                        <legend *ngIf="stepDesactivation==3">votre numéro de téléphone</legend>
                        <legend *ngIf="stepDesactivation==4">
                            Y a-t-il quelque chose que vous aimeriez nous dire ? <br>
                            Tous commentaires, réactions ou suggestions sont les bienvenus !
                        </legend>
                        <legend *ngIf="stepDesactivation==5">
                            En soumettant ce formulaire, vous confirmez l'annulation et la suppression de votre compte et de vos informations NBH.
                        </legend>
                        <legend *ngIf="stepDesactivation==6">
                            Merci pour tous !
                        </legend>
                    </div>
                    <div class="col-lg-6 col-md-6" *ngIf="stepDesactivation==1" style="margin-left: 25%">
                        <div class="form-group">
                            <label></label>
                            <input  type="text" placeholder="nom@gmail.com" class="form-control">
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6" *ngIf="stepDesactivation==2" style="margin-left: 25%">
                        <div class="form-group">
                            <label></label>
                            <input  type="text" placeholder="Entrez votre nom complet" class="form-control">
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6" *ngIf="stepDesactivation==3" style="margin-left: 25%">
                        <div class="form-group">
                            <label></label>
                            <input  type="text" placeholder="Entrez votre numéro de téléphone" class="form-control">
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6" *ngIf="stepDesactivation==4" style="margin-left: 25%">
                        <div class="form-group">
                            <label></label>
                            <input  type="text" placeholder="Entrez un commentaire" class="form-control">
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12">
                        <button (click)="step()" type="submit" class="default-btn mt-4"><i
                            class='bx bx-window-close icon-arrow before'></i><span class="label">{{stepDesactivation<5 ? 'Oui': stepDesactivation==5? 'Soumettre':'Retour'}}</span><i
                            class="bx bx-window-close icon-arrow after"></i></button>
                    </div>
                </div>
            </form>
        </div>

    </div>

</div>
