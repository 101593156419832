<app-header-billeterie></app-header-billeterie>

<section class="mission-area ptb-100" style="padding-top: 0;height: 250px !important;z-index: 1;position: relative;">
    <section class="mission-area"  id="myImgApropos" style="background-color:  #0B2154 !important;height: 250px !important;">
        <app-navbar></app-navbar>
        <div class="container">
            <div class="menu">
                <div class="menu-title">
                    <span>Mes cartes</span>
                </div>
                <div class="profile">
                    <div class="row _user">
                        <div class="col-4">
                            <svg style="    width: 98%;" xmlns="http://www.w3.org/2000/svg" width="104" height="106" viewBox="0 0 104 106">
                                <g id="Groupe_58" data-name="Groupe 58" transform="translate(-449.57 -116.254)">
                                    <circle id="Ellipse_4" data-name="Ellipse 4" cx="52" cy="52" r="52" transform="translate(449.57 116.255)" fill="#60ba07"/>
                                    <text id="G" transform="translate(501.57 200.254)" fill="#cecece" font-size="87" font-family="Montserrat-Bold, Montserrat" font-weight="700" letter-spacing="0.041em"><tspan x="-32.625" y="0">{{_lettre(dataUsers?.spectateurnom)}}</tspan></text>
                                </g>
                            </svg>

                        </div>
                        <div class="col-8 _inf_user">
                            <span class="profile-text">{{dataUsers?.spectateurnom}} {{dataUsers?.spectateurprenom}}</span> <br>
                            <span class="profile-text">{{dataUsers?.spectateurtel}}</span><br>
                            <span class="profile-text">{{dataUsers?.spectateuremail}}</span>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    </section>
</section>

<div class="courses-details-area pb-70" style="background-color:#006837 ;min-height: 100%;">
    <div class="container">

        <div class="row">
            <div class="col-12">
                <nav class="navCat">
                    <a style="cursor: pointer" [routerLink]="data.uri"  *ngFor="let data of dataNav;let i=index" id="nav{{i}}">{{data.label}}</a>
                </nav>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-2">
            </div>
            <div class="col-lg-8">
                <div class="courses-details-desc">


                    <div class="contLLne">
                        <div class="title">Carte n-5621</div>
                        <div class="content">
                            <div class="card-section">
                                <div
                                    class="card {{i === currentCardIndex ?'visible':'' }}"
                                    *ngFor="let card of cards; let i = index"
                                    [ngClass]=""
                                    (click)="checkTicketCarte(card)">
                                    <img [src]="card" alt="Carte Farot">
                                </div>

                            </div>
                            <div class="arrow" (click)="nextCard()">&#9654;</div>
                            <div class="tickets-section">
                                <div class="tickets-title">Les tickets de la carte</div>
                                <div class="tickets">
                                    <div class="ticket" *ngFor="let ticket of tickets">
                                        <img [src]="ticket.image" alt="Ticket">
                                        <div class="price">{{ticket.price}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button class="btn btnCl" style="margin-left: 2%; margin-top: 6%;" (click)="addCarte()">Ajouter une carte</button>
                    </div>


                </div>
            </div>
            <div class="col-lg-2">

            </div>
        </div>
    </div>
</div>



<div class="modal" *ngIf="isOpen">
    <div class="modal-content">
        <div class="modal-header">
            <span>{{ 'AJOUTER UNE CARTE A MON COMPTE'}}</span>
        </div>
        <div class="modal-body">

                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <label>ENTREZ LE CODE DE LA CARTE <span class="required">*</span></label>
                            <input type="text" [(ngModel)]="codecarte"
                                   class="form-control">
                        </div>
                    </div>
                </div>


        </div>
        <div class="modal-footer">
            <div class="row">
                <div class="col-6">
                    <button class="btn btnCl" style="width: 126px;" (click)="closeModal()">Fermer</button>
                </div>
                <div class="col-6">
                    <button class="btn btnCl" style="width: 126px;background: #096dd9;border-color: #096dd9;color: #fff !important;" (click)="saveCard()">Valider</button>
                </div>
            </div>

        </div>
    </div>
</div>
