import {Component, Input, OnInit} from '@angular/core';
import {Router} from "@angular/router";

@Component({
    selector: 'app-section-categorie',
    templateUrl: './section-categorie.component.html',
    styleUrls: ['./section-categorie.component.scss']
})
export class SectionCategorieComponent implements OnInit {
    @Input() dataItems: any = [];
    imageObject: Array<object> = [
        {
            video: 'https://youtu.be/6pxRHBw-k8M' // Youtube url
        },
        {
            video: 'assets/video/movie.mp4', // MP4 Video url
        },
        {
            video: 'assets/video/movie2.mp4',
            posterImage: 'assets/img/slider/2_min.jpeg', //Optional: You can use this key if you want to show video poster image in slider
            title: 'Image title'
        },
        {
            image: 'assets/img/slider/1.jpg',
            thumbImage: 'assets/img/slider/1_min.jpeg',
            alt: 'Image alt'
        }
    ];
    sections = [
        {
            title: 'CONCERTS',
            items: [
                { image: 'assets/concerts/oumou-sangare-1.jpg', title: 'OUMOU SANGARÉ EN CONCERT LIVE' },
                { image: 'assets/concerts/roseline-layo.jpg', title: 'ROSELINE LAYO EN CONCERT LIVE' },
                { image: 'assets/concerts/onel-mala.jpg', title: 'O\'NEL MALA EN CONCERT LIVE' },
                { image: 'assets/concerts/josey.jpg', title: 'JOSEY EN CONCERT LIVE' },
                // Ajoutez plus d'images si nécessaire
            ],
            currentIndex: 0
        },
        {
            title: 'CINEMA',
            items: [
                { image: 'assets/concerts/oumou-sangare-1.jpg', title: 'OUMOU SANGARÉ EN CONCERT LIVE' },
                { image: 'assets/concerts/roseline-layo.jpg', title: 'ROSELINE LAYO EN CONCERT LIVE' },
                { image: 'assets/concerts/onel-mala.jpg', title: 'O\'NEL MALA EN CONCERT LIVE' },
                { image: 'assets/concerts/josey.jpg', title: 'JOSEY EN CONCERT LIVE' },
                // Ajoutez plus d'images si nécessaire
            ],
            currentIndex: 0
        },
        {
            title: 'HUMOUR',
            items: [
                { image: 'assets/concerts/oumou-sangare-1.jpg', title: 'OUMOU SANGARÉ EN CONCERT LIVE' },
                { image: 'assets/concerts/roseline-layo.jpg', title: 'ROSELINE LAYO EN CONCERT LIVE' },
                { image: 'assets/concerts/onel-mala.jpg', title: 'O\'NEL MALA EN CONCERT LIVE' },
                { image: 'assets/concerts/josey.jpg', title: 'JOSEY EN CONCERT LIVE' },
                // Ajoutez plus d'images si nécessaire
            ],
            currentIndex: 0
        }
    ];



    constructor(private router: Router) {
    }

    ngOnInit(): void {
        setTimeout(()=>{
            if (this.dataItems.events) {
                this.dataItems.events = this.dataItems.events.map((e) => {
                    return {
                        ...e,
                        image: e.url,
                        thumbImage: e.url,
                        alt: e.eventitre,
                        title:  e.eventitre,
                    }
                });
            }
        },2000)
        console.log("this.dataItems ==",this.dataItems)
    }

    toGoRoute(event: number) {
        let path: any = this.dataItems?.events[event];
        this.router.navigateByUrl('/check-event?key=' + path.eventcode);
    }

}
