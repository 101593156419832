<section class="container" class="secLigne">

    <div class="container">
        <div class="instructor-slides">
            <div class="row">
                <div class="col-lg-2 ant-col-xs-2">
                    <button type="button"
                            class="btn btn-warning text-nowrap d-inline-flex position-relative me-4 rubBtn">
                        <b>{{dataItems?.categorie ? dataItems?.categorie : 'Evènement'}}</b>
                    </button>
                </div>
                <div class="col-lg-10 ant-col-xs-15">
                    <hr class="ligneCat">
                </div>
                <div class="col-12 mt-3" style="margin-left: 16px;">
                    <ng-image-slider *ngIf="dataItems.events.length"
                                     (imageClick)="toGoRoute($event)"
                                     [imagePopup]="false"
                                     [imageSize]="{width: '275px', height: '200px', space: 4}"
                                     [images]="dataItems?.events" #nav></ng-image-slider>
                </div>
            </div>
        </div>
    </div>









    <!--    <div class="concert-carousel">-->
    <!--        <button class="carousel-control prev" (click)="prevSlide()">-->
    <!--            &lt;-->
    <!--        </button>-->
    <!--        <div class="carousel-wrapper">-->
    <!--            <div class="carousel">-->
    <!--                <div class="carousel-itemm" *ngFor="let data of dataItems.events">-->
    <!--                    <img src="{{data.image}}" alt="{{ data.eventitre }}">-->
    <!--                    <div class="concert-info">-->
    <!--                        <h3>{{ data.eventitre }}</h3>-->
    <!--                    </div>-->
    <!--                </div>-->
    <!--            </div>-->
    <!--        </div>-->
    <!--        <button class="carousel-control next" (click)="nextSlide()">-->
    <!--            &gt;-->
    <!--        </button>-->
    <!--    </div>-->


<!--        <div class="section">-->
<!--            <h2>{{ 'section.title' }}</h2>-->
<!--            <div class="carousel-container">-->
<!--                <button class="carousel-control prev" (click)="prevSlide('section')">-->
<!--                    &lt;-->
<!--                </button>-->
<!--                <div class="carousel-wrapper">-->
<!--                    <div class="carousel" >-->
<!--                        <div class="carousel-itemm" *ngFor="let concert of dataItems.events">-->
<!--                            <img [src]="concert.image" alt="{{ concert.title }}">-->
<!--                            <div class="concert-info">-->
<!--                                <h3>{{ concert.title }}</h3>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--                <button class="carousel-control next" (click)="nextSlide('section')">-->
<!--                    &gt;-->
<!--                </button>-->
<!--            </div>-->
<!--        </div>-->






    <section>
