import {Component, HostListener, OnInit} from '@angular/core';
import {UntypedFormBuilder, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {NGXLogger} from 'ngx-logger';
import {ToastrService} from 'ngx-toastr';
import {environment} from 'src/environments/environment';
import {EventwithkeyService} from '../home-billeterie/eventwithkey.service';
import {Spectateur, Ticket} from '../spectateur';
import {dataSMS} from "../../../core/config-texte";
import * as moment from "moment";
import {dataContry} from "../../../core/config-var";
import {EventwithoutkeyService} from "../home-billeterie/eventwithoutkey.service";
import {NzModalService} from "ng-zorro-antd/modal";

declare let $: any;

@Component({
    selector: 'app-ticket-billeterie',
    templateUrl: './ticket-billeterie.component.html',
    styleUrls: ['./ticket-billeterie.component.scss']
})

export class TicketBilleterieComponent implements OnInit {

    dataMenu: any = [
        {
            uri: "/",
            label: "Accueil",
        }, {
            uri: "/concert",
            label: "Découvrir",
        }, {
            uri: "/connecter",
            label: "Mon profil",
        }
    ]
    dataNav: any = [
        {
            uri: "/achat",
            label: "Mes achats",
        }, {
            uri: "/ticket",
            label: "Mes tickets",
        }, {
            uri: "/receive",
            label: "Tickets reçus",
        }, {
            uri: "/foward",
            label: "Tickets transférés",
        }, {
            uri: "/carte",
            label: "Mes cartes",
        }, {
            uri: "/notification",
            label: "Notifications",
        }, {
            uri: "/infos",
            label: "Info personnelle",
        }
    ]
    tickets: Ticket[] = [];
    loadingTicketcheck: any = false;

    showTicket: Ticket;
    showListTicket: any = true;

    sharedTicketQrcode: any = false;
    shareQrcodeForm;

    errorShareQrcode: any = false;
    errorShareQrcodeMessage: string = "";


    sharedTicketCarte: any = false;
    shareCarteForm;

    errorShareCarte: any = false;
    errorShareCarteMessage: string = "";
    indicatifPays: string = "225";

    imgDefault: string = "../../../../../../assets/img/party.jpg";
    dataUsers: any = [];

    itemsPerPage = 12;
    currentPage = 1;
    paginatedItems: any[] = [];
    pageCount: number;
    pages: number[];
    isOpen: boolean = false;
    loadingCheck: boolean = false;
    dataContry: any = dataContry;
    dContry: any = [];

    constructor(private _activatedRoute: ActivatedRoute, private router: Router,
                private gestwithkey: EventwithkeyService, public fb: UntypedFormBuilder, private modal: NzModalService,
                private toastr: ToastrService, private checkwithkey: EventwithoutkeyService,
                private logger: NGXLogger) {

    }

    ngOnInit(): void {
        this.disablePrintScreen();
        $('#imgProfil').css({
            'background-image': 'url(' + this.imgDefault + ')',
            'background-size': 'cover'
        });

        this.shareQrcodeForm = this.fb.group({
            'indicatifPays': ['225', Validators.required],
            'beneficiaire': [null, Validators.required],
            'password': [null, Validators.required],
        });

        this.shareCarteForm = this.fb.group({
            'indicatifPays': ['225', Validators.required],
            'beneficiaire': [null, Validators.required],
            'password': [null, Validators.required],
        });

        this.shareQrcodeForm.get('beneficiaire').valueChanges.subscribe(data => {
            this.phoneNumberLength(data);
        });

        let u = new Spectateur();

        u = JSON.parse(localStorage.getItem(`${environment.keylocalstorage}`));
        this.dataUsers = u
        if (!u) {
            this.router.navigate(['/'])
        }
        this.loadTicket();
        this.loadPays();
    }


    phoneNumberLength(data) {
        let regexpNumber = new RegExp("^[+0-9]{0,18}$");
        let keyValue = data || '';
        if (!regexpNumber.test(keyValue)) {
            // set the new value to the formControl
            this.shareQrcodeForm.patchValue({
                beneficiaire: keyValue.slice(0, keyValue.length - 1)
            }, {
                emitEvent: false // it will make sure the valuechange won't trigger when applying new value to formcontrol.(line number 30 won't execute)
            });
            // this.keyValue = this.keyValue.slice(0, this.keyValue.length - 1);
            console.log(keyValue);
        }
    }


    showMyTicket(achat: any) {
        this.showTicket = achat;
        this.showListTicket = false;
        this.isOpen = true;
    }

    closeModal() {
        this.loadingTicketcheck = true;
        this.showTicket = null;
        this.showListTicket = true;
        this.isOpen = false;
        this.sharedTicketQrcode = false;
    }

    shareTicketQrcode() {
        this.sharedTicketQrcode = true;
        this.shareQrcodeForm = this.fb.group({
            'indicatifPays': ['225', Validators.required],
            'beneficiaire': [null, Validators.required],
            'password': [null, Validators.required],
        });
        this.shareQrcodeForm.get('beneficiaire').valueChanges.subscribe(data => {
            this.phoneNumberLength(data);
        });
    }


    shareTicketCarte() {
        this.sharedTicketCarte = true;
        this.shareCarteForm = this.fb.group({
            'beneficiaire': [null, Validators.required],
            'password': [null, Validators.required],
        });
    }

    cancelShareTicketQrcode() {
        this.sharedTicketQrcode = false;
        this.shareQrcodeForm = this.fb.group({
            'indicatifPays': [null, Validators.required],
            'beneficiaire': [null, Validators.required],
            'password': [null, Validators.required],
        });
        this.shareQrcodeForm.get('beneficiaire').valueChanges.subscribe(data => {
            this.phoneNumberLength(data);
        });
    }

    logout() {
        localStorage.clear();
        this.router.navigate(['/'])
    }

    cancelShareTicketCarte() {
        this.sharedTicketCarte = false;
        this.shareCarteForm = this.fb.group({
            'beneficiaire': [null, Validators.required],
            'password': [null, Validators.required],
        });
        this.shareQrcodeForm.get('beneficiaire').valueChanges.subscribe(data => {
            this.phoneNumberLength(data);
        });
    }

    saveShareTicketQrcode() {
        let u: Spectateur;
        u = JSON.parse(localStorage.getItem(`${environment.keylocalstorage}`));
        if (!this.shareQrcodeForm.valid) {
            return;
        }


        if (u) {

            let request: any = this.shareQrcodeForm.value;

            if (this.shareQrcodeForm.value.beneficiaire == u.numeroTelUnique) {
                this.modal.info({
                    nzTitle: '<b>TRANFERT DE TICKET</b>',
                    nzContent: `<p>Impossible de transférer un ticket à soi-même.</p>`,
                    nzOkText: 'Fermer',
                    nzCentered: true,
                    nzOnOk: () => {
                    }
                });
                return;
            }

            request.spectateurcode = u.spectateurcode;
            request.ticketcode = this.showTicket.ticketcode;
            request.type = this.showTicket.typeticketcode;
            // request.indicatifPays = this.indicatifPays;
            request.beneficiaire = request.indicatifPays + request.beneficiaire;
            console.log(request.beneficiaire, u.spectateurtel)


            let msg: any = dataSMS.transf_ticket.replace(new RegExp(`<%nom_categorie_ticket%>`, 'gi'), `${this.showTicket.caticket}`)
            msg = msg.replace(new RegExp(`<%time%>`, 'gi'), `${moment(new Date()).format('DD/MM/YYYY HH:mm')}`)
            msg = msg.replace(new RegExp(`<%type_ticket%>`, 'gi'), `${this.showTicket.typeticket}`)
            msg = msg.replace(new RegExp(`<%nom_evenement%>`, 'gi'), `${this.showTicket.event}`)
            msg = msg.replace(new RegExp(`<%mode_achat%>`, 'gi'), `transfert de ticket`)
            msg = msg.replace(new RegExp(`<%reference_achat%>`, 'gi'), `${this.showTicket.achatcode}`)

            this.gestwithkey.post(request, "/billeterie/ticket/sharebyqrcode", u.token).subscribe(data => {
                    this.logger.trace('INFOS CNX', data);

                    switch (data.status) {
                        case 200: {
                            let payload = {
                                "to": "225" + request.beneficiaire,
                                "contente": msg
                            }
                            // this.envoiSMS(payload);
                            this.toastr.success('Succes!', 'Ticket partagé');
                            this.loadTicket();
                            break;
                        }
                    }
                },
                error => {
                    //this.loader.close();

                    this.logger.error('ERROR', error);
                    switch (error.status) {


                        case 428: {
                            this.toastr.error('Echec!', error.error || 'Le numero de telephone est associé à aucun compte');
                            break;
                        }

                        case 417: {
                            this.toastr.error('Echec!', error.error || 'Mot de passe incorrect');
                            break;
                        }

                        case 412: {
                            this.toastr.error('Echec!', error.error || 'Ticket inconnu');
                            break;
                        }

                        default: {
                            this.toastr.error('Echec!', error.error || 'Ticket inconnu ');
                            break;
                        }
                    }
                });
        }
    }

    saveShareTicketCarte() {
        let u: Spectateur;
        u = JSON.parse(localStorage.getItem(`${environment.keylocalstorage}`));
        if (!this.shareCarteForm.valid) {
            return;
        }

        if (u) {
            let request: any = this.shareCarteForm.value;
            request.spectateurcode = u.spectateurcode;
            request.ticketcode = this.showTicket.ticketcode;
            request.type = this.showTicket.typeticketcode;
            request.beneficiaire = request.indicatifPays + request.beneficiaire;

            this.gestwithkey.post(request, "/billeterie/ticket/sharebycard", u.token).subscribe(data => {
                    this.logger.trace('INFOS CNX', data);

                    switch (data.status) {
                        case 200: {

                            this.toastr.success('Succes!', 'Ticket partagé');
                            this.loadTicket();
                            break;
                        }
                    }
                },
                error => {
                    //this.loader.close();

                    this.logger.error('ERROR', error);
                    switch (error.status) {


                        case 428: {
                            this.toastr.error('Echec!', 'Carte inconue');
                            break;
                        }

                        case 417: {
                            this.toastr.error('Echec!', 'Mot de passe incorrect');
                            break;
                        }

                        case 412: {
                            this.toastr.error('Echec!', 'Ticket inconnu');
                            break;
                        }

                        default: {
                            this.toastr.error('Echec!', 'Ticket inconnu');
                            break;
                        }
                    }
                });
        }
    }

    loadTicket() {
        let u: Spectateur;
        u = JSON.parse(localStorage.getItem(`${environment.keylocalstorage}`));

        this.logger.info(u);
        this.loadingTicketcheck = true;
        this.showTicket = null;
        this.showListTicket = true;
        this.isOpen = false;
        this.loadingCheck = true;

        if (u) {
            this.gestwithkey.get("/billeterie/ticket/allTicketBySpectateurCode/" + u.spectateurcode, u.token).subscribe(data => {
            // this.gestwithkey.get("/billeterie/ticket/allTicketByTelephone/" + u.spectateurcode, u.token).subscribe(data => {
                    // this.gestwithkey.get("/billeterie/ticket/all/" + u.spectateurcode + "/" + `${environment.keyNormal}`, u.token).subscribe(data => {
                    this.loadingCheck = false;
                    this.selectNav(1);
                    switch (data.status) {

                        case 200: {
                            this.loadingTicketcheck = false;
                            this.errorShareQrcode = false;
                            this.errorShareQrcodeMessage = "";
                            this.sharedTicketQrcode = false;


                            this.errorShareCarte = false;
                            this.errorShareCarteMessage = "";
                            this.sharedTicketCarte = false;

                            this.tickets = data.body;
                            this.logger.trace("MES TICKETS ==> ");


                            this.pageCount = Math.ceil(this.tickets.length / this.itemsPerPage);
                            this.pages = Array.from({length: this.pageCount}, (v, k) => k + 1);

                            this.renderItems();

                            // this.renderPagination()
                            // this.renderItems()
                            this.logger.trace(this.tickets);
                            break;
                        }
                    }
                },
                error => {
                    this.loadingCheck = false;
                    switch (error.status) {
                        case 401: {
                            //this.router.navigate(['/authentication/signin']);
                            this.loadingTicketcheck = false;
                            break;
                        }
                        default: {
                            this.tickets = [];
                            this.loadingTicketcheck = false;
                            this.logger.error(error);
                            break;
                        }
                    }
                });
        }
    }

    envoiSMS(payload: any) {

        //this.senderSms = true;
        this.gestwithkey.postSender(payload, "/api/sendSms").subscribe((data: any) => {
                // this.logger.trace('INFOS CNX', data);
                switch (data.body.data.status) {
                    case "submitted": {

                        break;
                    }
                }
            },
            error => {
                this.logger.error('ERROR', error);
                this.toastr.error('Echec!', "Une erreur est survenue ");
            });
    }


    selectNav(i: number) {
        for (let j = 0; j < this.dataMenu.length; j++) {
            $('#nav' + j).removeClass("active");
        }
        $('#nav' + i).addClass('active');
    }

    goTopath(e: any) {
        console.log(e)
        //  this.router.navigateByUrl(e.uri)
    }

    renderItems(): void {
        const start = (this.currentPage - 1) * this.itemsPerPage;
        const end = start + this.itemsPerPage;
        this.paginatedItems = this.tickets.slice(start, end);
    }

    goToPage(page: number): void {
        this.currentPage = page;
        this.renderItems();
    }

    prevPage(): void {
        if (this.currentPage > 1) {
            this.currentPage--;
            this.renderItems();
        }
    }

    nextPage(): void {
        if (this.currentPage < this.pageCount) {
            this.currentPage++;
            this.renderItems();
        }
    }


    @HostListener('window:blur', ['$event'])
    onWindowBlur(event: Event): void {
        this.blurSensitiveData();
    }

    @HostListener('window:focus', ['$event'])
    onWindowFocus(event: Event): void {
        this.unblurSensitiveData();
    }

    private disablePrintScreen() {
        document.addEventListener('keydown', (event) => {
            if (event.key === 'PrintScreen') {
                alert('Capture d\'écran désactivée');
                event.preventDefault();
            }
        });
    }

    private blurSensitiveData() {
        const elements = document.querySelectorAll('.sensitive-data');
        elements.forEach(element => {
            element.classList.add('blurred');
        });
    }

    private unblurSensitiveData() {
        const elements = document.querySelectorAll('.sensitive-data');
        elements.forEach(element => {
            element.classList.remove('blurred');
        });
    }

    _lettre(spectateurnom: string) {
        return spectateurnom.substring(0, 1).toUpperCase();
    }

    _split(caticket: string) {

        let d = caticket.split(" ");
        let r: string = "";
        if (d.length > 1) {
            r = d[0].substring(0, 1) + "" + d[1].substring(0, 1)
        } else {
            r = caticket;
        }
        return r;
    }

    loadPays() {

        this.checkwithkey.get(`/billeterie/session/getAllListPays`).subscribe(data => {

                switch (data.status) {
                    case 200: {

                        if (data.body.length) {

                            this.dContry = data.body.map((d) => {
                                return {
                                    ...d,
                                    country: d.paysNom,
                                    indicatif: d.paysIndicatif,
                                    code: d.paysCode,
                                }
                            });
                        }

                        break;
                    }
                    default : {

                    }
                }
            },
            error => {

                switch (error.status) {
                    case 401: {
                        //this.router.navigate(['/authentication/signin']);
                        break;
                    }
                    default: {
                        this.logger.trace(error);
                        break;
                    }
                }
            });
    }
    separe(s: string | null) {
        return s.toString().replace(/,/g, ' ')
    }
    validateLength(input) {
        if (input.value.length > 5) {
            input.value = input.value.slice(0, 5); // Coupe à 5 caractères
        }
    }
}
