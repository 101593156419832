import { Injectable, OnInit } from '@angular/core';
import * as cryptojs from 'crypto-js';

@Injectable({
    providedIn: 'root'
})
export class Configapp implements OnInit {


    constructor() { }

    ngOnInit(): void { }

    setEncrypt(text: string, key: any) {
        return cryptojs.AES.encrypt(text, key).toString();
    }
    getDecrypt(ciphertext: any, passphrase: any) {
        let originalText: any;
        try {
            const bytes = cryptojs.AES.decrypt(ciphertext, passphrase);
            originalText = bytes.toString(cryptojs.enc.Utf8);
        } catch (error) {
            throw 'Error to decrypt request => ' + error.message;
        }
        return originalText;
    }
}

export function getDecr(ciphertext: any, passphrase: any) {
    let originalText: any;
    try {
        const bytes = cryptojs.AES.decrypt(ciphertext, passphrase);
        originalText = bytes.toString(cryptojs.enc.Utf8);
    } catch (error) {
        throw 'Error to decrypt request => ' + error.message;
    }
    return originalText;
}
export function decode64(text: string) {

    text = text.replace(/\s/g, "");

    if (!(/^[a-zàâäçdeéèêëiîïôöuùûü0-9\+\/\s]+\={0,2}$/i.test(text)) || text.length % 4 > 0) {
        throw new Error("Not a base64-encoded string.");
    }
    let digits = "AÂÄBCDEÊËFGHIÎÏJKLMNOÔÖPQRSTUÛÜVWXYZàâäabcçdeéèêëfghiîïjklmnoôöpqrstuùûüvwxyz0123456789+/",
        cur, prev = 0, digitNum, i = 0,
        result = [];

    text = text.replace(/=/g, "");

    while (i < text.length) {

        cur = digits.indexOf(text.charAt(i));
        digitNum = i % 4;

        switch (digitNum) {

            //case 0: first digit - do nothing, not enough info to work with
            case 1:
                //second digit
                result.push(String.fromCharCode(prev << 2 | cur >> 4));
                break;

            case 2:
                //third digit
                result.push(String.fromCharCode((prev & 0x0f) << 4 | cur >> 2));
                break;

            case 3:
                //fourth digit
                result.push(String.fromCharCode((prev & 3) << 6 | cur));
                break;
        }

        prev = cur;
        i++;
    }

    return result.join("");
}
export function encode64(text: any) {
    // if(/(^[0-9A-Za-zÀ-ÿ\ ,.\;'\-()\s\:\!\?\"])+/.test(text)){
    // if (/([^\u0000-\u00ffàâäçdeéèêëiîïôöuùûü])/i.test(text)) {
    //     throw new Error("Can't base64 encode non-ASCII characters.");
    // }

    var digits = "AÂÄBCDEÊËFGHIÎÏJKLMNOÔÖPQRSTUÛÜVWXYZàâäabcçdeéèêëfghiîïjklmnoôöpqrstuùûüvwxyz0123456789+/",
        // var digits = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/",
        i = 0,
        cur, prev, byteNum, result = [];

    while (i < text.length) {

        cur = text.charCodeAt(i);
        byteNum = i % 3;

        switch (byteNum) {
            case 0:
                //first byte
                result.push(digits.charAt(cur >> 2));
                break;

            case 1:
                //second byte
                result.push(digits.charAt((prev & 3) << 4 | (cur >> 4)));
                break;

            case 2:
                //third byte
                result.push(digits.charAt((prev & 0x0f) << 2 | (cur >> 6)));
                result.push(digits.charAt(cur & 0x3f));
                break;
        }

        prev = cur;
        i++;
    }

    if (byteNum == 0) {
        result.push(digits.charAt((prev & 3) << 4));
        result.push("==");
    } else if (byteNum == 1) {
        result.push(digits.charAt((prev & 0x0f) << 2));
        result.push("=");
    }

    return result.join("");
}
function encryptWithAES(text: string, passphrase: any) {
    return cryptojs.AES.encrypt(text, passphrase).toString();
}
export function requestEncode(request: any, key_crypt: any) {
    return { request: encryptWithAES(request, key_crypt) }
}
export function cryptSession(session_string: string, key_crypt: any) {
    return encryptWithAES(session_string, key_crypt)
}
