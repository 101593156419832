<div class="login-area">
    <div class="row m-0">
        <div class="col-lg-6 col-md-12 p-0">
            <div class="login-image">

            </div>
        </div>
        <div class="col-lg-6 col-md-12 p-0">
            <div class="login-content">
                <div class="d-table">
                    <div class="d-table-cell">
                        <div class="login-form">
                            <div class="logo">
                                <a routerLink="/"><img src="assets/img/digit.jpg" style="height: 150px; width: 300px;"
                                        alt="image"></a>
                            </div>
                            <h3>Reinitialiser mot de passe</h3>
                            <p>Votre mot de passe doit contenir au moins 6 caractères</p>

                            <form [formGroup]="unlockForm" (ngSubmit)="check()">
                                <div class="form-group">
                                    <input type="password" placeholder="nouveau mot de passe"
                                        [formControl]="unlockForm.controls['oldpassword']" class="form-control">
                                </div>
                                <div class="form-group">
                                    <input type="password" placeholder="Confirmation mot de passe"
                                        [formControl]="unlockForm.controls['password']" class="form-control">
                                </div>

                                <div *ngIf="loadingCheck" class="d-flex justify-content-center"
                                    style="margin-bottom: 2px;">
                                    <div class="spinner-border" style="font-size: 12px;
                                    height: 30px;
                                    width: 30px;
                                    color: red;" role="status">
                                        <span class="sr-only"></span>
                                    </div>
                                </div>

                                <button type="submit">VALIDER</button>



                                <div class="forgot-password">
                                    <a routerLink="/connecter">Je me connecte</a>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>