import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { EventwithkeyService } from '../home-billeterie/eventwithkey.service';
import { EventwithoutkeyService } from '../home-billeterie/eventwithoutkey.service';
import { Achat, checkPay, notifPay, Spectateur } from '../spectateur';

@Component({
  selector: 'app-checkpay-billeterie',
  templateUrl: './checkpay-billeterie.component.html',
  styleUrls: ['./checkpay-billeterie.component.scss']
})
export class CheckpayBilleterieComponent implements OnInit {

  code: string;
  loadingCheck: any = false;
  user: Spectateur;

  header: string = "";
  body: string = "";

  achat: Achat = new Achat();

  constructor(private _activatedRoute: ActivatedRoute, private router: Router,
    private gestwithkey: EventwithkeyService,
    private gestwithoutkey: EventwithoutkeyService,
    private toastr: ToastrService,
    private logger: NGXLogger) {
  }

  ngOnInit(): void {
    this._activatedRoute.queryParams.forEach((params: Params) => {
      if (params['key']) {
        this.code = params['key'];
        /*
        this.user = JSON.parse(localStorage.getItem(`${environment.keylocalstorage}`));
        if (!this.user) {
          this.toastr.error('Echec!', 'Veuillez vous connecter');
          this.router.navigate(['/']);
        }
        let request: any = {};
        request.achatcode = this.code;
        */
        //this.checkPaymentStatus(this.code);



        // this.checkPayment(this.code);

      } else {
        this.router.navigate(['/']);
      }
    });
  }


  goToRouting(path: string) {
    this.router.navigateByUrl(path);
  }
  //
  //
  // checkPaymentStatus(request: any) {
  //   this.gestwithoutkey.get("/billeterie/achat/check/" + request).subscribe(data => {
  //     this.logger.trace('INFOS CNX', data);
  //     switch (data.status) {
  //       case 200: {
  //         this.logger.trace(data.body);
  //         this.loadingCheck = true;
  //         this.achat = data.body;
  //         /*
  //         this.header = "Paiement succes";
  //         this.body = "CODE ACHAT : " + this.code;
  //         */
  //         break;
  //       }
  //     }
  //   },
  //     error => {
  //       //this.loader.close();
  //
  //       this.logger.error('ERROR', error);
  //       switch (error.status) {
  //
  //         default: {
  //
  //           this.toastr.error('Echec!', 'Erreur survenue veuillez ressayer');
  //           this.router.navigate(['/']);
  //           break;
  //         }
  //       }
  //     });
  // }
  //
  //
  // checkPayment(code: string) {
  //   let request: checkPay = new checkPay();
  //   request.transaction_id = code;
  //   request.site_id = `${environment.cinetpaySiteid}`;
  //   request.apikey = `${environment.cinetpayApikey}`;
  //
  //   this.gestwithoutkey.postVerification(request, `${environment.cinetpayCheckUrl}`).subscribe(data => {
  //     this.logger.trace('INFOS CNX', data);
  //
  //     switch (data.status) {
  //       case 200: {
  //         this.logger.trace(data.body);
  //
  //         let response1: any = data.body;
  //         let request1: notifPay = new notifPay();
  //
  //         if (response1.code == "00") {
  //           request1.achatcode = code;
  //           request1.checking = true;
  //         } else {
  //           request1.achatcode = code;
  //           request1.checking = false;
  //         }
  //
  //
  //         this.pushRequest(request1);
  //
  //         /*
  //         this.loadingCheck = true;
  //         this.achat = data.body;
  //         */
  //
  //         break;
  //       }
  //     }
  //
  //   },
  //     error => {
  //       //this.loader.close();
  //
  //       this.logger.error('ERROR', error);
  //       switch (error.status) {
  //         default: {
  //
  //           this.toastr.error('Echec!', 'Erreur survenue veuillez ressayer');
  //           this.router.navigate(['/']);
  //           break;
  //         }
  //       }
  //
  //     });
  //
  // }
  //
  //
  //
  //
  // pushRequest(request: any) {
  //   this.gestwithoutkey.post(request, "/billeterie/payment/notifpaychecking").subscribe(data => {
  //     this.logger.trace('INFOS CNX', data);
  //     switch (data.status) {
  //       case 200: {
  //         this.logger.trace(data.body);
  //         this.loadingCheck = true;
  //         this.achat = data.body;
  //         /*
  //         this.header = "Paiement succes";
  //         this.body = "CODE ACHAT : " + this.code;
  //         */
  //         break;
  //       }
  //     }
  //   },
  //     error => {
  //       //this.loader.close();
  //
  //       this.logger.error('ERROR', error);
  //       switch (error.status) {
  //
  //         default: {
  //
  //           this.toastr.error('Echec!', 'Erreur survenue veuillez ressayer');
  //           this.router.navigate(['/']);
  //           break;
  //         }
  //       }
  //     });
  // }
  //
  //


}
