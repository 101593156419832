import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeBilleterieComponent } from './components/billeterie/body/home-billeterie/home-billeterie.component';
import { ComedieBilleterieComponent } from './components/billeterie/body/comedie-billeterie/comedie-billeterie.component';
import { ConcertBilleterieComponent } from './components/billeterie/body/concert-billeterie/concert-billeterie.component';
import { FestivalBilleterieComponent } from './components/billeterie/body/festival-billeterie/festival-billeterie.component';
import { LoisirsBilleterieComponent } from './components/billeterie/body/loisirs-billeterie/loisirs-billeterie.component';
import { SpectacleBilleterieComponent } from './components/billeterie/body/spectacle-billeterie/spectacle-billeterie.component';
import { SportsBilleterieComponent } from './components/billeterie/body/sports-billeterie/sports-billeterie.component';
import { TheatreBilleterieComponent } from './components/billeterie/body/theatre-billeterie/theatre-billeterie.component';
import { AutresBilleterieComponent } from './components/billeterie/body/autres-billeterie/autres-billeterie.component';
import { LoginBilleterieComponent } from './components/billeterie/body/login-billeterie/login-billeterie.component';
import { InscrireBilleterieComponent } from './components/billeterie/body/inscrire-billeterie/inscrire-billeterie.component';
import { NotificationBilleterieComponent } from './components/billeterie/body/notification-billeterie/notification-billeterie.component';
import { EventdetailsBilleterieComponent } from './components/billeterie/body/eventdetails-billeterie/eventdetails-billeterie.component';
import { DashboardBilleterieComponent } from './components/billeterie/body/dashboard-billeterie/dashboard-billeterie.component';
import { TicketBilleterieComponent } from './components/billeterie/body/ticket-billeterie/ticket-billeterie.component';
import { FowardBilleterieComponent } from './components/billeterie/body/foward-billeterie/foward-billeterie.component';
import { ReceiveBilleterieComponent } from './components/billeterie/body/receive-billeterie/receive-billeterie.component';
import { CarteBilleterieComponent } from './components/billeterie/body/carte-billeterie/carte-billeterie.component';
import { InfosBilleterieComponent } from './components/billeterie/body/infos-billeterie/infos-billeterie.component';
import { PaymentBilleterieComponent } from './components/billeterie/body/payment-billeterie/payment-billeterie.component';
import { CheckpayBilleterieComponent } from './components/billeterie/body/checkpay-billeterie/checkpay-billeterie.component';
import { EmailcheckBilleterieComponent } from './components/billeterie/body/emailcheck-billeterie/emailcheck-billeterie.component';
import { UnlockBilleterieComponent } from './components/billeterie/body/unlock-billeterie/unlock-billeterie.component';
import { RinitpasswordComponent } from './components/billeterie/body/rinitpassword/rinitpassword.component';
import { ProposBilleterieComponent } from './components/billeterie/body/propos-billeterie/propos-billeterie.component';
import { MentionBilleterieComponent } from './components/billeterie/body/mention-billeterie/mention-billeterie.component';
import { FaqBilleterieComponent } from './components/billeterie/body/faq-billeterie/faq-billeterie.component';
import { ConditionBilleterieComponent } from './components/billeterie/body/condition-billeterie/condition-billeterie.component';
import { PolitiqueBilleterieComponent } from './components/billeterie/body/politique-billeterie/politique-billeterie.component';
import {DesabonneComponent} from "./components/billeterie/body/desabonne/desabonne.component";
import {SuccessComponent} from "./components/billeterie/body/success/success.component";
//HomeBilleterieComponent
//    {path: '', component: HomePageOneComponent},

const routes: Routes = [
  { path: '', component: HomeBilleterieComponent },
  { path: 'comedie', component: ComedieBilleterieComponent },
  { path: 'concert', component: ConcertBilleterieComponent },
  { path: 'festival', component: FestivalBilleterieComponent },
  { path: 'loisir', component: LoisirsBilleterieComponent },
  { path: 'spectacle', component: SpectacleBilleterieComponent },
  { path: 'sports', component: SportsBilleterieComponent },
  { path: 'theatre', component: TheatreBilleterieComponent },
  { path: 'autres', component: AutresBilleterieComponent },
  { path: 'connecter', component: LoginBilleterieComponent },
  { path: 'inscrire', component: InscrireBilleterieComponent },
  { path: 'notification', component: NotificationBilleterieComponent },
  { path: 'check-event', component: EventdetailsBilleterieComponent },
  { path: 'achat', component: DashboardBilleterieComponent },
  { path: 'ticket', component: TicketBilleterieComponent },
  { path: 'foward', component: FowardBilleterieComponent },
  { path: 'receive', component: ReceiveBilleterieComponent },
  { path: 'carte', component: CarteBilleterieComponent },
  { path: 'infos', component: InfosBilleterieComponent },
  { path: 'checkpayment', component: PaymentBilleterieComponent },
  { path: 'mobilepayment', component: CheckpayBilleterieComponent },
  { path: 'emailcheck', component: EmailcheckBilleterieComponent },
  { path: 'rinitpsswd', component: RinitpasswordComponent },

  { path: 'propos', component: ProposBilleterieComponent },
  { path: 'mention', component: MentionBilleterieComponent },
  { path: 'faq', component: FaqBilleterieComponent },
  { path: 'condition', component: ConditionBilleterieComponent },
  { path: 'politique', component: PolitiqueBilleterieComponent },
  { path: 'desabonnement', component: DesabonneComponent },
  { path: 'success', component: SuccessComponent },


  { path: 'unlocksession', component: UnlockBilleterieComponent },

  { path: '**', component: HomeBilleterieComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
