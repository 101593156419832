import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
	selector: 'app-course-billeterie',
	templateUrl: './course-billeterie.component.html',
	styleUrls: ['./course-billeterie.component.scss']
})
export class CourseBilleterieComponent implements OnInit {

	@Input() items: any;


	constructor(private router: Router) { }

	ngOnInit(): void {
	}

	text:any = {
		Year: 'Year',
		Month: 'Month',
		Weeks: "Weeks",
		Days: "Days",
		Hours: "Hours",
		Minutes: "Minutes",
		Seconds: "Seconds",
		MilliSeconds: "MilliSeconds"
	  };


	goToRouting(path: string) {
		this.router.navigateByUrl(path);
	}

	countDownDate = new Date("march 24, 2020 15:37:25").getTime();
	endTime  = '2022-06-29T00:00:00';

	/*
	demo: any;
	x = setInterval(() => {
		var now = new Date().getTime();
		var distance = this.countDownDate - now;
		var days = Math.floor(distance / (1000 * 60 * 60 * 24));
		var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
		var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
		var seconds = Math.floor((distance % (1000 * 60)) / (1000));
		this.demo = days + " Jours " + hours + " heures " + minutes + " min " + seconds + " s";
	})
	/

	/*
	countDownDate() {

		let countDownDate = new Date("march 24, 2020 15:37:25").getTime();
		let demo: any;
		let x = setInterval(() => {
			var now = new Date().getTime();
			var distance = countDownDate - now;
			var days = Math.floor(distance / (1000 * 60 * 60 * 24));
			var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
			var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
			var seconds = Math.floor((distance % (1000 * 60)) / (1000));
			demo = days + " Jours " + hours + " heures " + minutes + " min " + seconds + " s";
		})
		console.log(demo);

		return demo;
	}
*/


	coursesSlides: OwlOptions = {
		loop: false,
		nav: true,
		dots: true,
		autoWidth: true,
		autoplayHoverPause: true,
		autoplay: false,
		margin: 30,
		navText: [
			"<i class='bx bx-left-arrow-alt'></i>",
			"<i class='bx bx-right-arrow-alt'></i>"
		],
		responsive: {
			0: {
				items: 1,
			},
			768: {
				items: 2,
			},
			1200: {
				items: 3,
			},
			1260: {
				items: 4,
			}
		}
	}

}
