import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { EventwithkeyService } from '../home-billeterie/eventwithkey.service';
import { EventwithoutkeyService } from '../home-billeterie/eventwithoutkey.service';
import { CommentaireDto } from '../spectateur';

@Component({
  selector: 'app-unlock-billeterie',
  templateUrl: './unlock-billeterie.component.html',
  styleUrls: ['./unlock-billeterie.component.scss']
})
export class UnlockBilleterieComponent implements OnInit {


  loadingCheck: any = false;
  key: string;

  public unlockForm;

  constructor(private _activatedRoute: ActivatedRoute, private router: Router, private gestserv: EventwithoutkeyService,
    private gestwithkey: EventwithkeyService, public fb: UntypedFormBuilder, private toastr: ToastrService,
    private logger: NGXLogger) { }

  ngOnInit(): void {


    this.unlockForm = this.fb.group({
      'code': [null, Validators.required],
      'oldpassword': [null, Validators.required],
      'password': [null, Validators.required]
    });

    let u: any = {};
    u = JSON.parse(localStorage.getItem(`${environment.keylocalstorage}`));

    this._activatedRoute.queryParams.forEach((params: Params) => {
      if (params['key']) {
        this.key = params['key'];
        this.unlockForm = this.fb.group({
          'code': [this.key, Validators.required],
          'oldpassword': [null, Validators.required],
          'password': [null, Validators.required]
        });

      } else {
        this.router.navigate(['/']);
      }
    });
  }

  check() {
    this.logger.trace(this.unlockForm);

    if (this.loadingCheck) {
      return null;
    }


    if (!this.unlockForm.valid) {
      this.toastr.error('Echec!', "Veuillez renseigner votre email");
      return null;
    }

    this.loadingCheck = true;

    let request: any = this.unlockForm.value;

    if (request.oldpassword.trim().length < 6) {
      this.toastr.error('Echec!', "Votre mot de passe doit avoir au moins 6 caracteres ");
      this.loadingCheck = false;
      return null;
    }

    if (request.oldpassword.trim() != request.password.trim()) {
      this.toastr.error('Echec!', "Les 2 mot de passe ne sont pas identiques ");
      this.loadingCheck = false;
      return null;
    }

    request.oldpassword = request.oldpassword.trim();
    request.password = request.password.trim();

    this.gestserv.post(request, "/billeterie/session/endpasswdrenew").subscribe(data => {
      this.logger.trace('INFOS CNX', data);

      switch (data.status) {
        case 200: {
          this.loadingCheck = false;

          this.logger.trace('Retour login');
          this.logger.trace(data.body);
          this.toastr.success('SUCCES', "MOT DE PASSE MODIFIE");

          this.unlockForm = this.fb.group({
            'code': [this.key, Validators.required],
            'oldpassword': [null, Validators.required],
            'password': [null, Validators.required]
          });

          //this.router.navigate(["/ticket"]);

          break;
        }
      }
    },
      error => {
        //this.loader.close();

        this.logger.error('ERROR', error);
        switch (error.status) {
          default: {
            this.toastr.error('Echec!', 'Erreur survenue !');

            this.loadingCheck = false;
            break;
          }
        }
      });

  }


  goToRouting(path: string) {
    this.router.navigateByUrl(path);
  }

}
