<section  id="_box_pub">
    <img src="{{imgDefault}}" alt="">
</section>
<!--<div class="boxes-area">-->
<!--    <div class="container-fluid">-->
<!--        <div class="row">-->
<!--            <div class="col-lg-3 col-md-6">-->
<!--                <div class="single-boxes-item bg-image" style="background: #DCDCDC">-->
<!--                    <h3 style="color: #000;">Achetez des tickets</h3>-->
<!--                    <p style="color: #000;">Achetez des tickets en toute sécurité !</p>-->
<!--                    &lt;!&ndash;-->
<!--     <a routerLink="/courses-2-columns-style-1" class="boxes-btn">View Courses<i-->
<!--                            class='bx bx-plus'></i></a>-->
<!--                    &ndash;&gt;-->

<!--                </div>-->
<!--            </div>-->
<!--            <div class="col-lg-3 col-md-6">-->
<!--                <div class="single-boxes-item bg-color">-->
<!--                    <h3>Notre garantie 100 %</h3>-->
<!--                    <p>Éliminez les risques de contrefaçon et assurez-vous d'une transaction securisée grâce à la nouvelle billetterie hybride (NBH).-->
<!--                    </p>-->
<!--                    &lt;!&ndash;-->
<!--                    <a routerLink="/courses-2-columns-style-2" class="boxes-btn">View More<i class='bx bx-plus'></i></a>-->
<!--                    &ndash;&gt;-->
<!--                </div>-->
<!--            </div>-->
<!--            &lt;!&ndash;-->

<!--            &ndash;&gt;-->

<!--            <div class="col-lg-3 col-md-6">-->
<!--                <div class="single-boxes-item" style="background: #DCDCDC">-->
<!--                    <h3>Contrôle de ticket fluide et automatique</h3>-->
<!--                    <p>-->
<!--                        Faites-vous controler en un clic et sans stress.-->
<!--                    </p>-->

<!--                    &lt;!&ndash;-->
<!--                    <a routerLink="/courses-4-columns-style-1" class="boxes-btn">View More<i class='bx bx-plus'></i></a>-->

<!--                        &ndash;&gt;-->
<!--                </div>-->
<!--            </div>-->


<!--            <div class="col-lg-3 col-md-6">-->
<!--                <div class="single-boxes-item bg-color">-->
<!--                    <h3>Support 7j/7</h3>-->
<!--                    <p>+225 27 20 27 32 26-->
<!--                        <br>-->
<!--                        Service Commercial: +225 05 56 66 77 97-->
<!--                        <br>-->
<!--                        Plus d'information: info@nbh.ci-->
<!--                    </p>-->
<!--                    &lt;!&ndash;-->
<!--                    <a routerLink="/courses-4-columns-style-1" class="boxes-btn">View More<i class='bx bx-plus'></i></a>-->

<!--                        &ndash;&gt;-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->
<!--</div>-->
