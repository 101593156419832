import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { EventwithoutkeyService } from '../home-billeterie/eventwithoutkey.service';

@Component({
  selector: 'app-rinitpassword',
  templateUrl: './rinitpassword.component.html',
  styleUrls: ['./rinitpassword.component.scss']
})
export class RinitpasswordComponent implements OnInit {

  
  public loginForm;
  checking: boolean = false;
  checkingMessage: string = "";

  constructor(private _activatedRoute: ActivatedRoute, private toastr: ToastrService, public fb: UntypedFormBuilder, private router: Router, private gestserv: EventwithoutkeyService, private logger: NGXLogger) { }

  ngOnInit(): void {
    localStorage.clear();
    this.loginForm = this.fb.group({
      'code': [null, Validators.required],
      'password': [null, Validators.required]
    });
  }


  check() {
    this.checkingMessage = "";
    this.logger.trace(this.loginForm);

    if (this.checking) {
      return null;
    }


    if (!this.loginForm.valid) {
      this.checkingMessage = "Veuillez renseigner tous les champs";
      this.toastr.error('Echec!', "Veuillez renseigner tous les champs");
      return null ;
    }

    this.checking = true;

    let request: any = this.loginForm.value;

    this.gestserv.post( request , "/billeterie/session/endpasswdrenew" ).subscribe(data => {
      this.logger.trace('INFOS CNX', data);

      switch (data.status) {
        case 200: {
          this.checking = false;

          this.logger.trace('Retour login');
          this.logger.trace(data.body);
          this.toastr.success('SUCCES', "MOT DE PASSE MIS A JOUR");

          this.loginForm = this.fb.group({
            'email': [null, Validators.required]
          });
          this.router.navigate(["/connecter"]);


          break;
        }
      }
    },
      error => {
        //this.loader.close();

        this.logger.error('ERROR', error);
        switch (error.status) {
          default: {
            this.checkingMessage = "code inconnu !";
            this.toastr.error('Echec!', 'code inconnu !');

            this.checking = false;
            break;
          }
        }
      });
  }
}
