import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {EventwithoutkeyService} from "../../billeterie/body/home-billeterie/eventwithoutkey.service";
import * as moment from "moment";

declare let $: any;

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
    rows: any = [];
    year:any=moment().format("YYYY")
    constructor(private router: Router, private gestserv: EventwithoutkeyService,) {
    }

    ngOnInit(): void {
        this.loadCategorie();
    }

    goToRouting(path: string) {
        this.router.navigateByUrl(path);
    }

    openNewTab(url) {
        window.open(url, '_blank');
    }

    loadCategorie() {
        this.rows = [];
        this.gestserv.get("/billeterie/event/getAllcategorieEvent").subscribe(data => {
                switch (data.status) {
                    case 200: {
                        this.rows = data.body;
                        break;
                    }
                }
            },
            error => {
                switch (error.status) {
                    case 401: {
                        break;
                    }
                    default: {
                        break;
                    }
                }
            });
    }

    selectNav(data: any, i: number) {
        for (let j = 0; j < this.rows.length; j++) {
            $('#navCatCheck' + j).removeClass("active");
        }
        $('#navCatCheck' + i).addClass('active');
        this.router.navigateByUrl('/concert?key=' + data.categoriecode);

    }
}
