<app-header-billeterie></app-header-billeterie>
<br *ngIf="!loadingCheck">
<br *ngIf="!loadingCheck">

<div *ngIf="!loadingCheck" class="d-flex justify-content-center">
    <div class="spinner-border" style="font-size: 30px;
    height: px;
    height: 120px;
    width: 120px;
    color: red;" role="status">
        <span class="sr-only"></span>
    </div>
</div>


<div *ngIf="loadingCheck" class="events-area pt-100 pb-70">
    <div class="container">
        <div *ngFor="let item of rows" class="single-events-box mb-30">
            <div class="events-box">
                <div class="events-image">
                    <div>
                        <img style="height: 250px" [src]="item.url" alt="image">
                    </div>
                </div>
                <div class="events-content">
                    <div class="content">
                        <h3><a (click)="goToRouting('/check-event?key='+item.eventcode)">{{item.eventitre}}</a></h3>
                        <p>
                            <span class="location"><i class='bx bx-time'></i> {{item.eventdebut | date: 'dd/MM/yyyy hh:mm'}}</span>
                        </p>
                        <span class="location"><i class='bx bx-map'></i> {{item.eventlieu}}</span>
                        <a (click)="goToRouting('/check-event?key='+item.eventcode)" class="join-now-btn">Voir</a>
                    </div>
                </div>

            </div>
        </div>





    </div>
</div>