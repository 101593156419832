import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {NGXLogger} from 'ngx-logger';
import {environment} from 'src/environments/environment';
import {EventwithkeyService} from '../home-billeterie/eventwithkey.service';
import {Achat, Spectateur} from '../spectateur';

declare let $: any;

@Component({
    selector: 'app-dashboard-billeterie',
    templateUrl: './dashboard-billeterie.component.html',
    styleUrls: ['./dashboard-billeterie.component.scss']
})
export class DashboardBilleterieComponent implements OnInit {


    achats: Achat[] = [];
    dataUsers: any = [];
    loadingAchatcheck: any = false;

    showAchat: Achat;

    showListAchat: any = true;

    dataMenu: any = [
        {
            uri: "/",
            label: "Accueil",
        }, {
            uri: "/concert",
            label: "Découvrir",
        }, {
            uri: "/connecter",
            label: "Mon profil",
        }
    ]
    dataNav: any = [
        {
            uri: "/achat",
            label: "Mes achats",
        }, {
            uri: "/ticket",
            label: "Mes tickets",
        }, {
            uri: "/receive",
            label: "Tickets reçu",
        }, {
            uri: "/foward",
            label: "Tickets transférés",
        }, {
            uri: "/carte",
            label: "Mes cartes",
        }, {
            uri: "/notification",
            label: "Notifications",
        }, {
            uri: "/infos",
            label: "Info personnelle",
        }
    ];
    imgDefault: string = "../../../../../../assets/img/party.jpg";
    isOpen: boolean = false;

    constructor(private _activatedRoute: ActivatedRoute, private router: Router,
                private gestwithkey: EventwithkeyService, public fb: UntypedFormBuilder,
                private logger: NGXLogger) {
    }

    ngOnInit(): void {
        $('#imgProfil').css({
            'background-image': 'url(' + this.imgDefault + ')',
            'background-size': 'cover'
        });
        let u = new Spectateur();
        u = JSON.parse(localStorage.getItem(`${environment.keylocalstorage}`));
        this.dataUsers = u;
        if (!u) {
            this.router.navigate(['/'])
        }
        this.loadAchat();

    }


    showMyAchat(achat: any) {
        this.showAchat = achat;
        // this.showListAchat = false;
        this.isOpen = true;
    }

    logout() {
        localStorage.clear();
        this.router.navigate(['/'])
    }


    loadAchat() {
        let u: Spectateur;
        u = JSON.parse(localStorage.getItem(`${environment.keylocalstorage}`));

        this.logger.info(u);
        this.loadingAchatcheck = true;
        this.showAchat = null;
        this.showListAchat = true;

        if (u) {

            //
            this.gestwithkey.get("/billeterie/achat/all/" + u.spectateurcode, u.token).subscribe(data => {
                    this.selectNav(0);
                    switch (data.status) {
                        case 200: {
                            this.loadingAchatcheck = false;
                            this.achats = data.body;
                            this.logger.trace("MES ACHATS ==> ");

                            this.logger.trace(this.achats);
                            break;
                        }
                    }
                },
                error => {
                    this.selectNav(0);
                    switch (error.status) {
                        case 401: {
                            //this.router.navigate(['/authentication/signin']);
                            this.loadingAchatcheck = false;
                            break;
                        }
                        default: {
                            this.logger.error("JE VIENS DE VERIFIER");
                            this.achats = [];
                            this.loadingAchatcheck = false;
                            this.logger.error(error);
                            break;
                        }
                    }
                });
        }
    }

    selectNav(i: number) {
        for (let j = 0; j < this.dataMenu.length; j++) {
            $('#nav' + j).removeClass("active");
        }
        $('#nav' + i).addClass('active');
    }

    closeModal() {
        this.isOpen = false;
    }

    _lettre(spectateurnom: string) {
        return spectateurnom.substring(0, 1).toUpperCase();
    }

    _split(caticket: string) {

        let d = caticket.split(" ");
        let r: string = "";
        if (d.length > 1) {
            r = d[0].substring(0, 1) + "" + d[1].substring(0, 1)
        } else {
            r = caticket;
        }
        return r;
    }
}
