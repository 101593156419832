import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-courses-all-billeterie',
  templateUrl: './courses-all-billeterie.component.html',
  styleUrls: ['./courses-all-billeterie.component.scss']
})
export class CoursesAllBilleterieComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
