import { Component } from '@angular/core';

@Component({
  selector: 'app-cond-use-nbh',
  templateUrl: './cond-use-nbh.component.html',
  styleUrls: ['./cond-use-nbh.component.scss']
})
export class CondUseNbhComponent {

}
