export const dataSMS = {
  achat_ticket:`Votre achat de ticket <%nom_categorie_ticket%>
 de <%nom_evenement%> a été effectué avec succès.
 Référence de l'achat: <%reference_achat%>
 Mode d'achat : <%mode_achat%>
 Nom du bénéficiaire : <%beneficiaire%>
 Type de ticket : <%type_ticket%>
 Date de l'opération: <%time%>`,
  transf_ticket:`Votre tranfert de ticket <%nom_categorie_ticket%>
 de <%nom_evenement%> a été effectué avec succès.
 Référence de l'achat: <%reference_achat%>
 Type d'opération : Transfert de ticket
 Type de ticket : <%type_ticket%>
 Date de l'opération: <%time%>`
}

