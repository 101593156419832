export class Spectateur {
    spectateurcode: string;
    spectateurnom: string;
    spectateurprenom: string;
    spectateurtel: string;
    numeroTelUnique: string;
    spectateuremail: string;
    spectateursexe: string;
    spectateurdatenais : any ;
    token: string;
}

export class UserLogin {
    username: string;
    password: string;
}

export class CommentaireItem {
    objet: string;
    message: string;
    date: any;
}

export class CommentaireDto {

    sender: CommentaireItem;
    receivers: CommentaireItem[];

}


export class Achat {

    achatcode: string;
    caticket: string;
    caticketcode: string;
    event: string;
    eventcode: string;
    organe: string;
    organecode: string;
    acheteurcode: string;
    typeacheteur: string;
    typeacheteurcode: string;
    provenancecode: string;
    provenance: string;
    achatdatelevel1: Date;
    achatdatelevel2: Date;
    acheteurnom: string;
    acheteurprenom: string;
    acheteuremail: string;
    acheteurtel: string;
    spectateurcode: string;
    achatnbreticket: number;
    achatnbreqr: number;
    achatnbrecarte: number;
    achatlevel: number;
    achatmontantnormal: number;
    achatreduction: number;
    achatmontantpayer: number;
    cel_phone_num: string;
    signature: string;
    cpm_trans_id: string;
    achatdatecreation: Date;

}


export class Ticket {
    ticketcode: string;
    achatcode: string;
    typeticket: string;
    beneficiairecarte: String;
    typeticketcode: string;
    beneficiairetel: string;
    beneficiairenom: string;
    beneficiaireprenom: string;
    spectateurcode: string;
    spectateurtypecode: string;
    spectateurtype: string;
    ticketstep: number;
    eventcode: string;
    event: string;
    caticket: string;
    caticketcode: string;
    hasreduction: boolean;
    ticketreduction: number;
    ticketmontantnormal: number;
    ticketmontantpayer: number;
    provenancecode: string;
    provenance: string;
    modecode: string;
    mode: string;
    organecode: string;
    organe: string;
    spectateurnom: string;
    spectateurprenom: string;
    spectateuremail: string;
    spectateurtel: string;
    ticketenable: boolean;
    ticketdatecreation: Date;
}


export class Carte {
    cartecode: string;
    categorie: string;
    categoriecode: string;
    carteprix: number;
    categoriered: number;
    groupecode: string;
    groupe: string;
    usereduction: boolean;
    cartenable: boolean;
    cartedate: string;
    cartedatexpiration: Date;
    cartenom: string;
    carteemail: string;
    cartetelephone: string;
    creerparorganecode: string;
    creerparorgane: string;
    creerparemail: string;
    creerparnom: string;
    creerparcode: string;
    lierparorganecode: string;
    lierparorgane: string;
    lierparemail: string;
    lierparnom: string;
    lierparcode: string;
    actparorganecode: string;
    actparorgane: string;
    actparemail: string;
    actparnom: string;
    actparcode: string;
    cartestatus: string;
    cartestatuscode: string;
    hasorgane: boolean;
    haslink: boolean;
    spectateurcode: string;
}

export class CheckLevel1Resp {
    isAvailable: boolean;
    cumul: number;
    checkPromoCode: boolean;
    checkPromoQte: boolean;
    reduction: number;
    total: number;
}

export class CheckLevel1Req {
    categorieticket: string;
    check: boolean;
    code: string;
    nbre: number;
}


export class Item {
    carte: string;
    email: string;
    nom: string;
    numero: number;
    prenom: string;
    telephone: string;
    type: string;
}


export class CheckLevel2 {
    caticketcode: string;
    checkcode: boolean;
    eventcode: string;
    items: Item[];
    montant: number;
    reduction: string;
    usercode: string;
    web: boolean;
}

export class Responsecheck2 {
    montantpayer: number;
    reduction: number;
    total: number;
    allreductions: Reductioncheck[];
    items: Item[];
}

export class Reductioncheck {
    reductionlib: string;
    reductionvalue: number;
}


export class Cardstatus {
    cartecode: string;
    cartepasswd: string;
    step: number;
    usercode: string;
}

export class Pub {
    url: string;
    eventcode: string;
    event: string;
    categorie: string;
}


export class RequestCinetpay {
    amount: number;
    apikey: string;
    channels: string;
    currency: string;
    customer_id: string;
    customer_name: string;
    customer_surname: string;
    description: string;
    metadata: string;
    notify_url: string;
    return_url: string;
    site_id: string;
    transaction_id: string;
}

export class ResponseCinetPay {
    achat: Achat;
    requestCinetpay: RequestCinetpay;
    url: string;
}



export class notifPay {
    achatcode: String;
    checking: boolean;
}





export class checkPay {
    apikey: String;
    site_id: String;
    transaction_id: String;
}
