import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {NGXLogger} from 'ngx-logger';
import {ToastrService} from 'ngx-toastr';
import {environment} from 'src/environments/environment';
import {EventwithkeyService} from '../home-billeterie/eventwithkey.service';
import {Spectateur, Ticket} from '../spectateur';
import {NgxSpinnerService} from "ngx-spinner";
declare let $: any;

@Component({
    selector: 'app-receive-billeterie',
    templateUrl: './receive-billeterie.component.html',
    styleUrls: ['./receive-billeterie.component.scss']
})
export class ReceiveBilleterieComponent implements OnInit {


    tickets: Ticket[] = [];
    dataUsers: any = [];
    dataMenu: any = [];
    loadingTicketcheck: any = false;

    showTicket: Ticket;
    showListTicket: any = true;
    dataNav: any = [
        {
            uri: "/achat",
            label: "Mes achats",
        }, {
            uri: "/ticket",
            label: "Mes tickets",
        }, {
            uri: "/receive",
            label: "Tickets reçus",
        }, {
            uri: "/foward",
            label: "Tickets transférés",
        }, {
            uri: "/carte",
            label: "Mes cartes",
        }, {
            uri: "/notification",
            label: "Notifications",
        }, {
            uri: "/infos",
            label: "Info personnelle",
        }
    ]

    constructor(private _activatedRoute: ActivatedRoute, private router: Router,
                private gestwithkey: EventwithkeyService, public fb: UntypedFormBuilder,
                private toastr: ToastrService,
                private logger: NGXLogger,private spinner: NgxSpinnerService) {
    }

    ngOnInit(): void {


        let u = new Spectateur();
        u = JSON.parse(localStorage.getItem(`${environment.keylocalstorage}`));
        this.dataUsers = u;
        if (!u) {
            this.router.navigate(['/'])
        }
        this.loadTicket();

    }


    showMyTicket(achat: any) {
        this.showTicket = achat;
        this.showListTicket = false;
    }

    logout() {
        localStorage.clear();
        this.router.navigate(['/'])
    }

    loadTicket() {
        this.spinner.show();
        let u: Spectateur;
        u = JSON.parse(localStorage.getItem(`${environment.keylocalstorage}`));

        this.logger.info(u);
        this.loadingTicketcheck = true;
        this.showTicket = null;
        this.showListTicket = true;

        if (u) {
            this.gestwithkey.get("/billeterie/ticket/all/" + u.spectateurcode + "/" + `${environment.keyShared}`, u.token).subscribe(data => {
                    this.selectNav(2);
                    this.spinner.hide();
                switch (data.status) {
                        case 200: {
                            this.loadingTicketcheck = false;

                            this.tickets = data.body;
                            this.logger.trace("MES TICKETS ==> ");

                            this.logger.trace(this.tickets);
                            break;
                        }
                    }
                },
                error => {
                    this.selectNav(2);
                    this.spinner.hide();
                    switch (error.status) {
                        case 401: {
                            //this.router.navigate(['/authentication/signin']);
                            this.loadingTicketcheck = false;
                            break;
                        }
                        default: {
                            this.tickets = [];
                            this.loadingTicketcheck = false;
                            this.logger.error(error);
                            break;
                        }
                    }
                });
        }
    }


    selectNav(i: number) {
        for (let j = 0; j < this.dataMenu.length; j++) {
            $('#nav' + j).removeClass("active");
        }
        $('#nav' + i).addClass('active');
    }


    _lettre(spectateurnom: string) {
        return spectateurnom.substring(0, 1).toUpperCase();
    }

    _split(caticket: string) {

        let d = caticket.split(" ");
        let r: string = "";
        if (d.length > 1) {
            r = d[0].substring(0, 1) + "" + d[1].substring(0, 1)
        } else {
            r = caticket;
        }
        return r;
    }
}
