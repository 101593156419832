import {Component} from '@angular/core';
import {dataMenu, imgProfil} from "../../../core/config-var";
import {Spectateur} from "../spectateur";
import {environment} from "../../../../../environments/environment";
import {ActivatedRoute, NavigationEnd, Params, Router, RouterStateSnapshot} from "@angular/router";
import {filter} from "rxjs/operators";

declare let $: any;

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent {
    dMenu: any = dataMenu;
    imgP: string = imgProfil;
    u: any = [];
    currentUrl: string = "";
    key: string = "";
    itemApropos: any = {
        uri: '/propos',
        label: 'A Propos',
        is: true,
        icon: 'a'
    };
    isMenuOpen = false;
    urlOrgan :string = environment.urlOrgan;
    constructor(private router: Router, private _activatedRoute: ActivatedRoute) {
    }

    selectNav(i: number, data: any = []) {
        for (let j = 0; j < this.dMenu.length; j++) {
            $('#nav' + j).removeClass("active");
        }
        $('#nav' + i).addClass('active');
        if (data?.uri) {
            this.router.navigateByUrl(data.uri);
        }
    }


    ngOnInit(): void {

        this.selectNav(1, []);
        this.u = new Spectateur();

        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd)
        ).subscribe((event: NavigationEnd) => {
            this.currentUrl = event.urlAfterRedirects;
            console.log("this.currentUrl ::::::::::::::>", this.currentUrl)
        });
        this._activatedRoute.queryParams.forEach((params: Params) => {
            if (params['key']) {
                this.key = params['key'];
            }
        });

        this.u = JSON.parse(localStorage.getItem(`${environment.keylocalstorage}`));
        let path: string;
        this.dMenu = dataMenu.map((e) => {
            let r = !(e.uri == "/inscrire" && this.u);
            path = e.uri == "/connecter" && this.u ? "/ticket" : e.uri;
            return {
                ...e,
                is: r,
                uri: path
            }
        })
        console.log("path==========", path)
        if (!this.u) {
            if (this.key) return;
            // this.router.navigate(['/']);
        }
    }

    goToHome() {
        this.router.navigate(['/']);
    }

    logout() {
        console.log("-----")
        localStorage.clear();
        this.u = null;
        this.router.navigate(['/'])
    }

    cnx() {
        this.router.navigate(['/connecter'])
    }

    inscrire() {
        this.router.navigate(['/inscrire'])
    }
    profil() {
        this.router.navigate(['/achat'])
    }
    toggleMenu() {
        this.isMenuOpen = !this.isMenuOpen;
    }
}
