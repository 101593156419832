<app-header-billeterie></app-header-billeterie>

<section class="mission-area ptb-100" style="padding-top: 0;height: 250px !important;z-index: 1;position: relative;">
    <section class="mission-area"  id="myImgApropos" style="background-color:  #0B2154 !important;height: 250px !important;">
        <app-navbar></app-navbar>
        <div class="container">
            <div class="menu">
                <div class="menu-title">
                    <span>Mes notifications</span>
                </div>
                <div class="profile">
                    <div class="row _user">
                        <div class="col-4">
                            <svg style="    width: 98%;" xmlns="http://www.w3.org/2000/svg" width="104" height="106" viewBox="0 0 104 106">
                                <g id="Groupe_58" data-name="Groupe 58" transform="translate(-449.57 -116.254)">
                                    <circle id="Ellipse_4" data-name="Ellipse 4" cx="52" cy="52" r="52" transform="translate(449.57 116.255)" fill="#60ba07"/>
                                    <text id="G" transform="translate(501.57 200.254)" fill="#cecece" font-size="87" font-family="Montserrat-Bold, Montserrat" font-weight="700" letter-spacing="0.041em"><tspan x="-32.625" y="0">{{_lettre(dataUsers?.spectateurnom)}}</tspan></text>
                                </g>
                            </svg>

                        </div>
                        <div class="col-8 _inf_user">
                            <span class="profile-text">{{dataUsers?.spectateurnom}} {{dataUsers?.spectateurprenom}}</span> <br>
                            <span class="profile-text">{{dataUsers?.spectateurtel}}</span><br>
                            <span class="profile-text">{{dataUsers?.spectateuremail}}</span>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    </section>
</section>

<div class="courses-details-area pb-70" style="background-color:#006837 ;min-height: 100%;">
    <div class="container">

        <div class="row">
            <div class="col-12">
                <nav class="navCat">
                    <a style="cursor: pointer" [routerLink]="data.uri"  *ngFor="let data of dataNav;let i=index" id="nav{{i}}">{{data.label}}</a>
                </nav>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-2">
            </div>
            <div class="col-lg-8">
                <div class="courses-details-desc">
                    <div  class="order-table table-responsive">
                        <table>
                            <thead>
                            <tr>
                                <th>Date</th>
                                <th>OBJET</th>
                                <th>MESSAGE</th>
                            </tr>
                            </thead>


                            <tbody>
                            <tr *ngFor="let a of notifications">
                                <td>{{a.pusherdatecreation | date: 'dd/MM/yyyy hh:mm'}}</td>
                                <td>{{a.objet}}</td>
                                <td>{{a.pusher}}</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>Aucune donnée</td>
                                <td></td>
                            </tr>
                            </tbody>
                        </table>

                    </div>
                </div>
            </div>
            <div class="col-lg-2">

            </div>
        </div>
    </div>
</div>




<!--<div class="my-dashboard-area ptb-100" style="display: none">-->
<!--    <div class="container">-->

<!--        <div class="myDashboard-navigation">-->
<!--            <ul>-->
<!--                <li><a routerLink="/achat"><i class='bx bx-cart'></i> Mes achats</a></li>-->
<!--                <li><a routerLink="/ticket"><i class='bx bxs-dashboard'></i> Mes tickets</a></li>-->
<!--                <li><a routerLink="/foward"><i class='bx bx-download'></i>Tickets transferés</a></li>-->
<!--                <li><a routerLink="/receive"><i class='bx bx-recycle'></i> Tickets réçus</a></li>-->
<!--                <li><a routerLink="/carte"><i class='bx bx-credit-card'></i> Mes cartes</a></li>-->
<!--                <li><a routerLink="/notification" class="active"><i class='bx bx-notification'></i>Notification</a></li>-->
<!--                <li><a routerLink="/infos"><i class='bx bx-edit'></i> Infos personelles</a></li>-->
<!--                <li><a (click)="logout()" ><i class='bx bx-user-x'  ></i>Deconnexion</a></li>-->
<!--            </ul>-->
<!--        </div>-->



<!--        <div class="myDashboard-content">-->

<!--            <h3></h3>-->

<!--            <div class="recent-orders-table table-responsive">-->
<!--                <table class="table">-->
<!--                    <thead>-->
<!--                        <tr>-->
<!--                            <th>Date</th>-->
<!--                            <th>OBJET</th>-->
<!--                            <th>MESSAGE</th>-->
<!--                        </tr>-->
<!--                    </thead>-->


<!--                    <tbody *ngIf="notifications && notifications.length>0">-->
<!--                        <tr *ngFor="let a of notifications">-->
<!--                            <td>{{a.pusherdatecreation | date: 'dd/MM/yyyy hh:mm'}}</td>-->
<!--                            <td>{{a.objet}}</td>-->
<!--                            <td>{{a.pusher}}</td>-->
<!--                        </tr>-->



<!--                    </tbody>-->
<!--                </table>-->
<!--            </div>-->
<!--        </div>-->


<!--    </div>-->

<!--</div>-->
