<app-header-billeterie></app-header-billeterie>

<section class="mission-area ptb-100" style="padding-top: 0;height: 350px !important;z-index: 1;position: relative;">
    <section class="mission-area"  id="myImgApropos" style="background-color: #006837 !important;height: 350px !important;">
        <app-navbar></app-navbar>
<!--        <div class="container" style="display: flex;justify-content: center">-->
<!--            <h1 style="margin-top: auto;margin-bottom: auto;color: #0B2154 !important;font-family: Montserrat;font-size: 52px;line-height: 0.9;">Mentions <br>-->
<!--                Légales</h1> <img class="imgApropos" src="{{imgPay}}">-->
<!--        </div>-->

        <div class="container _contentText">
            <h1 class="_titre">Mentions <br> Légales</h1>
            <h1 class="_titre1">Mentions Légales</h1>
            <img class="imgApropos" src="{{imgPay}}">
        </div>
    </section>
</section>


<div class="courses-details-area pb-70"  style="background-color: #0B2154;min-height: 100%;text-align: justify">
    <div class="container">

        <div class="row">
            <div class="col-lg-2">

            </div>

            <div class="col-lg-8">

                <div class="courses-details-desc">


                    <h3>Mentions légales</h3>
                    <p>
                        NBH est une marque exploité la société NBH (Nouvelle Billetterie Hybride) SARL spécialisée dans le
                        developpement des nouvelles technologies.
                    </p>

                    <p>
                        C’est une plateforme d’intermédiation entre spectateurs « clients » et organisateurs
                        d’événements ou de spectacles « vendeurs de tickets » développée par l’entreprise Nouvelle Billetterie Hybride
                        SARL basée à Abidjan. Créée en 2023, Nouvelle Billetterie Hybride est une
                        entreprise de conception, de production et d’impression de tickets et egalement de developpement informatique.
                    </p>

                    <h3>1 - Dispositions générales</h3>
                    <h3>1-1 Société</h3>

                    <p>
                        NBH est une société de droit ivoirien, immatriculée au registre de
                        commerce d’Abidjan au numéro CI-ABJ-2017-B-6547 dont le siège social est situé à Abidjan,
                        Cocody Rivera Attoban, derriere le Commissariat du 30 eme Arrondissement.
                    </p>


                    <h3>1-2 Acceptation</h3>

                    <p>
                        Le Membre déclare avoir pris connaissance des Conditions Générales de Vente et
                        d’Utilisation avant de passer toute commande de ticket et les avoir acceptées sans réserve.
                    </p>



                    <h3>1-3 Objet</h3>

                    <p>
                        Dispositions contractuelles.
                    </p>

                    <h3>1-4 Dispositions contractuelles</h3>

                    <p>
                        La nullité d’une clause contractuelle des Conditions Générales de Vente ou d’Utilisation
                        n’entraînera pas la nullité de l’intégralité des présentes.
                    </p>


                    <h3>1-5 Modification des conditions générales de vente</h3>

                    <p>
                        Nouvelle Billetterie Hybride se réserve la faculté de modifier les présentes Conditions Générales de
                        Vente à tout moment. Les Conditions Générales de Vente applicables sont celles en vigueur à
                        la date de la commande passée par le Membre.
                    </p>
                    <h3>2 - Commandes</h3>
                    <h3>2-1 Caractère définitif de la commande</h3>

                    <p>
                        Les achats effectués sur le Site Web ou mobile valent engagement ferme et définitif, et après
                        confirmation de ladite commande par le client, NBH envoie automatique sur le compte en
                        ligne du membre son ticket, une notification et un message de confirmation par SMS sur le
                        numéro du bénéficiaire.
                    </p>

                    <h3>2-2 Modification de la commande</h3>

                    <p>
                        Les commandes étant définitives et irrévocables, toute demande de modification faite par le
                        Membre ne pourra être acceptée. Le membre a donc le droit de revendre son ticket à un autre
                        désireux.
                    </p>

                    <h3>2-3 Validité de la commande</h3>

                    <p>
                        NBH se réserve le droit de refuser toute commande pour des motifs légitimes et
                        plus particulièrement dans le cas où les quantités commandées sont anormalement élevées par
                        rapport aux quantités disponible par les organisateurs en qualité de promoteurs ou si le
                        membre ne disposeas pas de fond suffisant pour effectuer sa commande.
                    </p>



                    <h3>3 - Le prix du Ticket</h3>

                    <p>
                        Le prix est exprimé F CFA. Le prix indiqué dans la confirmation de commande est le prix
                        définitif, exprimé en toutes taxes comprises ;
                    </p>


                    <h3>4 - Remise des Tickets ou des codes promotionnelles</h3>

                    <p>
                        La remise du ticket ou du code promo est effectué par voie électronique, il n’est pas
                        imprimable à domicile et pourvus d’un code QR ou sur une carte magnétique réutilisable. Il
                        est impossible d&#39;être admis à l&#39;entrée d’un évènement plusieurs fois avec le même
                        billet. La
                        reproduction de billets est interdite et ne vous procurera aucun avantage. Seule la première
                        personne à présenter le billet sera admise à assister à la manifestation.
                    </p>
                    <p>
                        Nos tickets non imprimables sont valables et disponibles en permanence jusqu&#39;à la date de
                        l&#39;évènement sur le site internet ou sur notre application mobile dans la rubrique « Mes
                        achats
                        » ou « Mes tickets ».
                    </p>
                    <p>
                        L&#39;acheteur des tickets prend toutes les mesures et précaution requises pour conserver secret
                        ses accès (adresse et mot de passe) a son compte et pour empêcher toute utilisation par des
                        personnes non autorisées ou toute infraction.
                    </p>
                    <p>
                        Lorsque le ticket est envoyé à l’adresse du compte indiquée par le Membre, il lui appartient de
                        vérifier la nature du ticket envoyé et, en cas d’erreur, d’émettre des réserves, éventuellement
                        de refuser le ticket ou le code promo et d’en avertir Nouvelle Billetterie Hybride dans un délai de 48h.
                        Ce qui est très rare, voire impossible.
                    </p>

                    <p>
                        Le remboursement du paiement des manifestations ou des deals annulées avec des billets
                        s&#39;effectue automatiquement vis-à-vis de l&#39;acheteur de billets par mobile money sur le
                        numéro
                        indiqué lors du processus de commande uniquement en cas d’annulation de l’évènement.
                    </p>

                    <h3>5 - Annulation, Remboursement, Utilisation</h3>
                    <p>
                        Les billets de spectacles ne font pas l&#39;objet d&#39;un droit de rétractation.
                    </p>
                    <p>
                        Un billet de spectacle ne peut être remboursé même en cas de perte ou de vol, ni repris, ni
                        échangé sauf en cas d&#39;annulation d&#39;un spectacle. En cas d’annulation du spectacle, seul
                        le prix
                        du billet sera remboursé sans les frais d’envoi. Ce remboursement n&#39;interviendra qu&#39;en
                        faveur
                        de l&#39;acquéreur initial contre désactivation du billet.
                    </p>
                    <p>
                        Aucun duplicata du billet ne pourra être délivré y compris en cas de perte ou de vol.
                    </p>
                    <p>Aucun remboursement ne sera effectué au-delà de 30 jours après la date à laquelle
                        l’événement était prévu.
                    </p>
                    <p>
                        Lors du contrôle à l&#39;entrée du lieu de l&#39;événement, une pièce d&#39;identité, avec
                        photo, en cours
                        de validité pourra vous être demandée et elle devra correspondre au nom inscrit sur le billet en
                        cas de nécessité.
                    </p>
                    <p>
                        À l&#39;annonce de l&#39;annulation ou d&#39;une modification de date, d&#39;horaire ou de lieu
                        d&#39;un spectacle
                        pour lequel vous avez réservé des places, vous acceptez que la Nouvelle Billetterie Hybride dans la mesure du
                        possible, lorsqu&#39;elle aura elle-même été prévenue par l&#39;organisateur, puisse utiliser
                        les
                        coordonnées que vous avez saisies lors de la réservation pour vous tenir informés de la
                        marche à suivre.
                    </p>
                    <p>
                        Nous vous invitons en tout état de cause à vérifier 24 heures avant votre spectacle que celui-ci
                        est bien maintenu sans modification.
                    </p>




                    <h3>6 – Valeur</h3>

                    <p>
                        La valeur des codes promo est celle en vigueur au jour de la confirmation de la commande par
                        le Membre telle que mentionnée à l’article Ci-dessus. Nouvelle Billetterie Hybride s’engage à appliquer
                        cette valeur à toute commande passée durant la période de la publicité faite pour le Billet sur
                        le Site Web.
                    </p>

                    <p>
                        Toute cession de bons à titre onéreux est formellement interdite.
                    </p>

                    <h3>7 - Consignation de la valeur du Bon d’achat</h3>

                    <h3>
                        7-1 Exigibilité
                    </h3>

                    <p>La consignation de la valeur du Billet ou Bon d&#39;achat est effectué à la commande, une fois
                        que
                        celle-ci a été confirmée par la Nouvelle Billetterie Hybride.</p>

                    <h3>
                        7-2 Moyens de paiement
                    </h3>

                    <p>
                        Le paiement en ligne sur notre site est sécurisé par la plateforme de paiement en
                        ligne <strong> CINEPAY.</strong>.
                    </p>


                    <p>
                        Le système de sécurisation des paiements électroniques mis en place par  <strong>
                            CINEPAY (1er
                            opérateur de paiement certifié PCI DSS de niveau 1 en côte d’ivoire, le plus haut niveau
                            de sécurité des transactions par carte bancaire)
                        </strong> est certifié
                    </p>


                    <p>
                        La plate-forme <strong> CINEPAY</strong> vous garantit le traitement, en toute confidentialité,
                        de vos
                        données bancaires et personnelles en conformité avec les standards de sécurité en vigueur,
                        ainsi que la loi 09-08 relative à la protection des données à caractère personnel.
                    </p>

                    <p>
                        La connexion entre le site marchand et la plateforme  <strong>CINEPAY</strong>  est sécurisée
                        par le protocole
                        TLS 1.2, le plus haut niveau de sécurité de cryptage des données échangées sur
                        Internet.  <strong>CINEPAY</strong> utilise notamment un certificat de sécurité EV (Extended
                        Value) que
                        vous pouvez identifier à travers la barre verte dans le champ URL de votre navigateur.
                    </p>


                    <p>
                        Dans le cas où votre banque fourni ce service, <strong> CINEPAY</strong> utilise la double
                        authentification 3-
                        D Secure (Verified By Visa ou MasterCard SecureCode) afin de sécuriser au maximum vos
                        achats sur Internet.
                    </p>


                    <h3>
                        8 - Règlement des litiges, Droit applicable et Juridiction compétente
                    </h3>

                    <h3>
                        8-1 Réclamation
                    </h3>

                    <p>
                        Toute réclamation doit être adressée au service consommateurs de <strong>Nouvelle Billetterie Hybride</strong> .
                        Contactez-nous sur contact@nbh.ci.
                    </p>


                    <h3>
                        8-2 Droit applicable et Juridiction compétente
                    </h3>

                    <p>
                        Les Conditions Générales de Commercialisation sont régies par le droit ivoirien. Tout litige
                        relatif à la commande ou aux présentes Conditions Générales de Vente sera soumis au
                        tribunal compétent.
                    </p>


                    <h3>
                        9 - Informatique et libertés
                    </h3>

                    <p>
                        Ce site est déclaré à l’Autorité de Régulation des Télécommunications/TIC de Côte d’Ivoire
                        (ARTCI) et est en cour d’immatriculation.
                        Le site est également soumis à la la Loi N° 2013-546 du 30 juillet 2013 relative aux
                        transactions électroniques.
                    </p>


                    <p>
                        Les informations recueillies sur le site www.nbh.ci font l’objet d’un traitement destiné à
                        assurer le traitement de la réservation de l’Acheteur, ainsi que pour renforcer et personnaliser
                        la communication notamment par lettres/courriers électroniques d’information, et enfin pour
                        personnaliser son Site Web en fonction des préférences constatées des Acheteurs et clients.
                    </p>


                    <p>
                        Les données personnelles communiquées par l’Acheteur sont destinées à la Nouvelle Billetterie Hybride et
                        à l’organisateur du dit évènements. Ceux-ci s’engagent mutuellement à respecter la
                        confidentialité de ces données personnelles et à ne les communiquer à aucuns autres partis
                        tiers.
                    </p>

                    <p>
                        Lors de sa réservation ou de la création de son compte, l’Acheteur pourra accepter de recevoir
                        de la part de Digibit, des informations, des promotions, des propositions commerciales ou
                        tous types de messages publicitaires de leurs partenaires et ce par le biais d’envoi d’e-mail ou
                        de sms envoyés directement par leurs partenaires commerciaux. L’acheteur aura la possibilité
                        de se désinscrire dès qu’il le souhaite.
                    </p>

                    <p>
                        Conformément à la loi n° 09 du 18 février 2009, relative à la protection des personnes
                        physiques à l&#39;égard du traitement des données à caractère personnel, vous bénéficiez d’un
                        droit d’accès et de rectification aux informations qui vous concernent, que vous pouvez
                        exercer en vous adressant à : contact@nbh.ci.
                    </p>

                    <p>
                        Vous pouvez également, pour des motifs légitimes, vous opposer à ce que les données qui
                        vous concernent fassent l’objet d’un traitement.
                    </p>


                    <h3>
                        10- Accessibilité
                    </h3>

                    <p>
                        Le Site est par principe accessible aux utilisateurs 24H/24H et 7/7j, sauf interruption,
                        programmée ou non, pour les besoins de maintenance ou en cas de force majeure.
                    </p>

                    <p>
                        En cas d’impossibilité d’accès au Site, celui-ci s’engage à faire son maximum afin d’en
                        rétablir l’accès. Le Site ne saurait être tenu pour responsable de tout dommage, quelle qu’en
                        soit la nature, résultant de son indisponibilité.
                    </p>


                    <h3>
                        11- Cookies
                    </h3>


                    <p>
                        Lorsque le Client navigue sur le Site, des cookies et autres traceurs sont déposés sur le
                        terminal qu’il utilise pour se connecter sur le Site. Le Site Internet utilise en effet des
                        cookies
                        et autres traceurs pour offrir au Client une navigation optimale sur le site Internet et pour
                        lui
                        proposer des publicités ciblées, adaptées à ses besoins.
                        En poursuivant sa navigation sur le Site, le Client accepte l’utilisation de ces cookies et
                        autres
                        traceurs.
                    </p>


                    <h3>
                        11- Cookies
                    </h3>

                    <p>
                        Lorsque le Client navigue sur le Site, des cookies et autres traceurs sont déposés sur le
                        terminal qu’il utilise pour se connecter sur le Site. Le Site Internet utilise en effet des
                        cookies
                        et autres traceurs pour offrir au Client une navigation optimale sur le site Internet et pour
                        lui
                        proposer des publicités ciblées, adaptées à ses besoins.
                        En poursuivant sa navigation sur le Site, le Client accepte l’utilisation de ces cookies et
                        autres
                        traceurs.
                    </p>


                    <h3>
                        12- Divers
                    </h3>
                    <p>
                        Le défaut d&#39;exercice par tickey.ci et/ou le Client des droits qui leur sont reconnus par
                        les
                        CGU ne constitue pas une renonciation à faire valoir leurs droits.
                        Si une ou plusieurs dispositions des CGU sont déclarées nulles ou caduques par application
                        d&#39;une loi ou d&#39;une décision judiciaire ou administrative, les autres dispositions
                        conservent
                        force obligatoire.
                    </p>

                </div>

            </div>
            <div class="col-lg-2">

            </div>
        </div>
    </div>
</div>
