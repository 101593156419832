import { Injectable } from '@angular/core';
import { PaymentService } from '../payment-billeterie/payment.service';
declare var CinetPay;

@Injectable({
  providedIn: 'root'
})
export class CinetpayService {

  private CinetPay: any;

  private apikey: string = '964013949618a6a3597a644.40844633';
  private site_id: number = 704090;
  private notify_url: string = 'https://www.tickey.ci/login';

  private trans_id: any = '859sezez'; //Your transaction id
  private cpm_custom: any;
  private designation: any;
  private currency: string = 'CFA';

  constructor(private paymentService: PaymentService) { }

  seamless(amount: number, code, keyStorage) {
    return new Promise(resolve => {

      //Generation d'une id de transaction
      this.trans_id = window.performance && window.performance.now && window.performance.timing && window.performance.timing.navigationStart ? window.performance.now() + window.performance.timing.navigationStart : Date.now();

      CinetPay.setConfig({
        apikey: this.apikey,
        site_id: this.site_id,
        notify_url: this.notify_url
      });

        console.log("CinetPay >>",CinetPay)
      //Lorsque la signature est généré
      CinetPay.on('signatureCreated', function (token) {
        //console.log('Tocken généré: ' + token);
      });

      CinetPay.on('paymentPending', function (e) {
        console.log('code:' + e.code + 'Message::' + e.message);
      });

      CinetPay.on('error', function (e) {
        console.log('Error code:' + e.code + 'Message::' + e.message);
      });

      CinetPay.on('paymentSuccessfull', function (paymentInfo) {
        resolve(paymentInfo);
        console.log("SUCCES==>");
        console.log(paymentInfo);
        let request: any = paymentInfo;
        request.achatcode = code;
      });

      CinetPay.setSignatureData({
        amount: amount,
        trans_id: this.trans_id,
        currency: this.currency,
        designation: this.designation,
        custom: this.cpm_custom
      });
      CinetPay.getSignature();
    });

  }

}
