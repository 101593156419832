import { HttpClient, HttpBackend, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Spectateur } from '../spectateur';

@Injectable({
  providedIn: 'root'
})
export class EventwithkeyService {


  private url = `${environment.apiUrl}`;
  private urlSms = `${environment.apiUrlSms}`;

  // parameter/tva/getall

  authorization: string;

  constructor(private http: HttpClient, handler: HttpBackend,
    private logger: NGXLogger) {

    this.http = new HttpClient(handler);
    let u = new Spectateur();
    u = JSON.parse(localStorage.getItem(`${environment.keylocalstorage}`));
    logger.trace("KEYSTORAGE-VALUE => "+this.authorization);

    if (u != null) {
      this.authorization = u.token;
      logger.trace("KEYSTORAGE => "+this.authorization);
    }
  }

  public post(value: any, path: string  , key): Observable<HttpResponse<any>> {
    return this.http.post(this.url + '' + path, JSON.stringify(value), {
      observe: 'response',
      headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
        .set('content-Type', 'application/json')
        .set('Authorization', key)
    });
  }
    public postSender(value: any, path: string): Observable<HttpResponse<any>> {
        return this.http.post(this.urlSms + '' + path, (value), {
            observe: 'response',
            headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
                .set('content-Type', 'application/json')
        });
    }

  public put(value: any, path: string): Observable<HttpResponse<any>> {
    return this.http.put(this.url + '' + path, JSON.stringify(value), {
      observe: 'response',
      headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
        .set('content-Type', 'application/json')
        .set('Authorization', this.authorization)
    });
  }

  delete(path: string): Observable<HttpResponse<any>> {
    return this.http.delete(this.url + '' + path, {
      observe: 'response',
      headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
        .set('content-Type', 'application/json')
        .set('Authorization', this.authorization)
    });
  }

  get(path: string , key): Observable<HttpResponse<any>> {
    return this.http.get(this.url + '' + path, {
      observe: 'response',
      headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
        .set('content-Type', 'application/json')
        .set('Authorization', key)
    });
  }


}
