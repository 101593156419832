import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {NGXLogger} from 'ngx-logger';
import {ToastrService} from 'ngx-toastr';
import {EventwithoutkeyService} from '../home-billeterie/eventwithoutkey.service';

declare let $: any;

@Component({
    selector: 'app-theatre-billeterie',
    templateUrl: './theatre-billeterie.component.html',
    styleUrls: ['./theatre-billeterie.component.scss']
})
export class TheatreBilleterieComponent implements OnInit {

    loadingCheck: any = false;
    rows: any[] = [];

    constructor(private router: Router, private gestserv: EventwithoutkeyService,
                private toastr: ToastrService,
                private logger: NGXLogger) {
    }

    ngOnInit(): void {
        this.loadEvent();
    }


    goToRouting(path: string) {
        this.router.navigateByUrl(path);
    }

    loadEvent() {
        this.loadingCheck = false;
        this.gestserv.get("/billeterie/alleventbycategorie/VFJDEL13").subscribe(data => {
                switch (data.status) {
                    case 200: {

                        this.rows = data.body;
                        this.logger.trace(this.rows);
                        this.loadingCheck = true;
                        if (!this.rows || this.rows.length == 0) {
                            this.toastr.error('', 'Aucun evenement');

                            this.goToRouting("/");
                        }
                        break;
                    }


                }
            },
            error => {
                switch (error.status) {
                    case 401: {
                        //this.router.navigate(['/authentication/signin']);
                        this.loadingCheck = true;
                        break;
                    }
                    default: {
                        this.loadingCheck = true;
                        this.logger.trace(error);

                        break;
                    }
                }
            });
    }

}
