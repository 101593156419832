<app-header-billeterie></app-header-billeterie>

<section class="mission-area ptb-100" style="padding-top: 0;height: 350px !important;z-index: 1;position: relative;">
    <section class="mission-area"  id="myImgApropos" style="background-color: #006837 !important;height: 350px !important;">
        <app-navbar></app-navbar>
        <div class="container _contentText">
           <h1 class="_titre">A propos de <br> nous</h1>
           <h1 class="_titre1">A propos de nous</h1>
            <img class="imgApropos" src="{{imgPay}}">
        </div>
    </section>
</section>

<div class="events-details-area" style="background-color: #0B2154;min-height: 100%;text-align: justify">

        <div class="container">
            <div class="row">
                <div class="col-lg-2"> </div>
                <div class="col-lg-8">
                    <div class="courses-details-desc">

                        <h3>À PROPOS DE LA NOUVELLE BILLETTERIE HYBRIDE (NBH)</h3>
                        <p>
                            Révolutionner la billetterie en Afrique, c’est possible, grâce à votre adhésion.
                            NBH est une entreprise Ivoirienne, créée en 2023. Nous sommes spécialisés dans
                            la révolution technologique. Par notre expertise, nous offrons de nouveaux systèmes aux
                            entrepreneurs d’Afrique francophone. A l’exemple de « NBH » qui permet à des millions de
                            personnes amoureux de l'événementiel de jouir du pur bonheur en toute sécurité.
                        </p>

                        <h3>LE PROBLEME</h3>
                        <p>
                            Selon des recherches menées dans le domaine de l’évènementiel en 2019, ce secteur est estimé à
                            35 milliards d’euros. Aussi, considérant la croissance du numérique et de l’internet dans le
                            monde, sans oublier les grands changements engendrés par la COVID-19, la vision des États
                            Africains et les efforts consentis en ce qui concerne la transition digitale et numérique ; ce
                            projet a été pensé afin de permettre aux organisateurs et promoteurs d'événements, une meilleure
                            gestion logistique de leurs évènements dans le but de pallier à certaines limites observées dans
                            les pratiques existantes telles que la conception et l’impression répétitive des tickets, la
                            falsification des tickets, la fraude, et la corruption, la sous-traitance avec des points ou
                            dépôts de vente, la gestion traditionnelle et conditions précaires des évènements.

                        </p>



                        <h3>LA SOLUTION</h3>
                        <p>
                            La société Nouvelle Billetterie Hybride (NBH) vous offre «  NBH  », une plate-forme innovante qui permet de
                            centraliser la billetterie en ligne et de faciliter le contrôle d’accès des participants à un
                            événement de façon automatique. Nos objectifs se résument en trois points :
                        </p>



                        <ul class="description-features-list">
                            <li>Réduire la fraude, la corruption, et l’impression répétitive des tickets engendrées dans le
                                secteur de l’évènementiel. </li>
                            <li>Promouvoir l’économie verte par la suppression des tickets en papier.</li>
                            <li>Optimiser la satisfaction et les relations clients dans le secteur de l’évènementiel.</li>

                        </ul>



                        <h3>SERVICES OFFERTS PAR NBH A L'UTILISATEUR OU ABONNES</h3>
                        <p>
                            1. Les utilisateurs abonnés seront informés de tous les événements nouveaux et à venir via leur
                            adresse e-mail, comptes en ligne ou SMS.
                        </p>

                        <p>
                            2. L'utilisateur paie ses billets dans les guichets de vente habituel ou en ligne en utilisant
                            le système Mobile Money ou Visa avec facilité et commodité ou un paiement en espèce.
                        </p>

                        <p>
                            3. L'utilisateur obtient son Ticket dans son compte en ligne (qr code) ou sur sa carte (selon le
                            choix du ticket qu’il aura fait). Aussi, un SMS de confirmation de l’achat lui est envoyé au
                            même instant.
                        </p>

                        <p>
                            4. Sans inscription sur le site Web, il est très facile, rapide et pratique pour un utilisateur
                            d'acheter son billet et de l’obtenir sous forme de QR code ou sur une carte magnétique digibit.
                        </p>

                        <p>
                            5. Nos billets sont délivrés sous forme de QR code ou disponible automatique sur une carte ou un
                            bracelet magnétique pouvant être scanner. C'est tellement pratique pour tous les utilisateurs,
                            même ceux qui n'ont pas de smartphone et dans le cas où l'on utilise notre USSD, il n'y a pas
                            besoin de connexion Internet.
                        </p>




                        <h3>SERVICES OFFERTS PAR NBH AUX PROMOTEURS D'EVENEMENTS</h3>
                        <p>
                            1. Les organisateurs peuvent économiser de l'argent sur l'impression (billets papier) et fournir
                            à leurs clients des billets en ligne ou utiliser l’USSD gratuit. Notre passerelle USSD est
                            rapide, facile et pratique pour chaque personne.
                        </p>

                        <p>
                            2. Juste un contrat et aucun frais pour l’ouverture de compte d’un organisateur ou l'utilisation
                            de notre service. Il est prélevé juste des frais de transaction encourus lors du transfert de
                            l'argent de votre billet sur vos comptes.
                        </p>

                        <p>
                            3. Nous réalisons des publicités sponsorisées sur Facebook pour tous les événements créés sur
                            notre plateforme, sans exceptions.
                        </p>

                        <p>
                            4. Vous aurez accès aux informations de tous vos clients recevant des billets afin de pouvoir
                            les inviter à vos prochains événements.
                        </p>


                        <p>
                            5. Grâce aux rapports, vous pouvez voir instantanément combien de ticket avez-vous vendu,
                            essentiellement via notre tableau de bord organisateur.
                        </p>

                        <p>
                            6. Débarrassez-vous des pertes subies dans le système papier et surveillez vos gains en temps
                            réel, consultez l'historique des achats, gagnez sur votre tableau de bord.
                        </p>

                        <p>
                            7. Retirez vos fonds de votre tableau de bord vers votre compte mobile money ou votre compte
                            bancaire.
                        </p>

                        <p>
                            8. Avec l'utilisation du scanne de ticket, vous pourrez numériser les billets des clients à la
                            porte pour la validation des billets.
                        </p>

                    </div>

                </div>
                <div class="col-lg-2">

                </div>
            </div>
        </div>



</div>







<app-header-billeterie></app-header-billeterie>

