<footer class="footer-area " style="display: none">
    <div class="container">
        <div class="row">
            <div class="col-6">
                <div class="single-footer-widget mb-30">

                    <div class="logo" style="text-align: center;">
                        <a routerLink="/" class="d-inline-block"><img style="width: 370px; margin-bottom: 25px;"
                                src="assets/img/digit.png" alt="image"></a>
                    </div>

                    <ul class="contact-us-link" style="display: none;">
                        <li>
                            <a>Votre nouvelle billetterie Hybride vous offre la possibilité
                                d’acheter les tickets digitaux à la fois en ligne ou dans les points de ventes habituels de tout évènement (concerts, sports, spectacles, cinéma,
                                festivals, théâtre, humour, formation) en collaboration avec les organisateurs
                                d’événements.</a>
                        </li>

                    </ul>

                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6" style="display: none">
                <div class="single-footer-widget mb-30">
                    <h3>NOTRE GUICHET</h3>

                    <ul class="contact-us-link">

                        <li>
                            <a routerLink="/">Accueil</a>
                            <br>
                            <a routerLink="/concert">Concert</a>
                            <br>

                            <a routerLink="/comedie">Comedie</a>
                            <br>

                            <a routerLink="/theatre">Theatre</a>
                            <br>

                            <a routerLink="/festival">Festival</a>
                            <br>

                            <a routerLink="/spectacle">Spectacle</a>
                            <br>

                            <a routerLink="/loisir">Loisir</a>
                            <br>

                            <a routerLink="/sports">Sport</a>


                        </li>

                    </ul>

                </div>


                <!--
                       <div class="single-footer-widget mb-30">
                    <h3>Support</h3>
                    <ul class="support-link">
                        <li><a routerLink="/">Privacy</a></li>
                        <li><a routerLink="/">FAQ's</a></li>
                        <li><a routerLink="/">Support</a></li>
                        <li><a routerLink="/">Terms</a></li>
                        <li><a routerLink="/">Condition</a></li>
                        <li><a routerLink="/">Policy</a></li>
                    </ul>
                </div>
                -->

            </div>
            <div class="col-lg-2 col-md-6 col-sm-6" style="display: none">
                <div class="single-footer-widget mb-30">
                    <h3 >A PROPOS
                    </h3>

                    <ul class="contact-us-link">

                        <li>
                            <a (click)="openNewTab('/propos')" >Qui sommes-nous ?</a>
                            <br>
                           <!--
                             <a>Contactez nous</a>
                            <br>
                           -->
                            <a (click)="openNewTab('/mention')">Mention légale</a>
                            <br>
                            <a (click)="openNewTab('/faq')">FAQ</a>
                            <br>
                            <a (click)="openNewTab('/condition')">CONDITIONS</a>
                            <br>
                            <a (click)="openNewTab('/politique')">POLITIQUE</a>
                        </li>
                    </ul>

                </div>
            </div>


            <div class="col-6">
                <div class="single-footer-widget mb-30">
                    <h3>Kiffez en toute sécurité !</h3>

                    <ul class="contact-us-link">

                        <li>
                            <a>Bienvenue sur NBH, <br>
                                La nouvelle billeterie hybride, connecté a un système de contrôle d'accès automatique.</a>
                            <br>
                            <a>
                                NBH est un système de billetrie hybride qui vous propose des tickets digitaux de tous vos événements préférés. Concert, spactacle, barbecue, brunch, matchs, conférence, salon, panel et bien d'autres...
                            </a>

                        </li>
                    </ul>

                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6" style="display: none">
                <div class="single-footer-widget mb-30">
                    <h3>LOCALISATION
                    </h3>

                    <ul class="contact-us-link">

                        <li>
                            <a>Siége social: Abidjan Rivera Attoban, Cote d’ivoire</a>
                            <br>
                            <a>Derriere le 30 eme Arrondissement</a>

                        </li>
                    </ul>

                </div>
            </div>


            <!--
                   <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget mb-30">


                    <ul class="social-link">
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>
                    </ul>
                </div>
            </div>
            -->

        </div>
    </div>

    <!--
         <div class="footer-bottom-area">
        <div class="container">
            <div class="logo">
                <a routerLink="/" class="d-inline-block"><img style="height: 50px; width: 162px;"
                        src="assets/img/digibit.png" alt="image"></a>
            </div>
            <p><i class='bx bx-copyright'></i>2021 <a routerLink="/" target="_blank">Ednuv</a> Designed By <a
                    target="_blank">Connecto</a> | All rights reserved.</p>
        </div>
    </div>
    -->

</footer>



<footer class="footer">
    <div class="containerL">
        <div class="footer-section">
<!--            <h2 style="font-size: 20px">LA NOUVELLE BILLETTERIE HYBRIDE</h2>-->
        </div>
        <div class="footer-columns">
            <div class="footer-column">
                <h3 style="font-size: 15px">NOUS CONCERNANT</h3>
                <ul class="point">
<!--                    <li><a href="#">Nous contacter</a></li>-->
                    <li><a (click)="openNewTab('/faq')">FAQ</a></li>
                    <li><a (click)="openNewTab('/propos')">A Propos</a></li>
                    <li><a (click)="openNewTab('/mention')">Mention légale</a></li>
                    <li><a (click)="openNewTab('/condition')">Conditions d'utilisation</a></li>
                    <li><a (click)="openNewTab('/politique')">Politique de confidentialité</a></li>

                </ul>
            </div>
            <div class="footer-column">
                <h3  style="font-size: 15px">NOTRE GUICHET</h3>
                <ul>
                    <li *ngFor="let data of rows;let i=index">
                        <a href="javascript:void(0)" (click)="selectNav(data,i)" id="navCatCheck{{i}}">{{data.categorielib}}</a>
                    </li>
                </ul>
            </div>
            <div class="footer-column logo-column">
                <img src="assets/img/digit.png" alt="NBH Logo" class="logo">
                <div class="download-links">
                    <a href="#"><img src="assets/img/app_store.png" alt="Télécharger dans l'App Store"></a>
                    <a href="https://play.google.com/store/apps/details?id=com.nbh.nbh_spectateur" target="_blank"><img src="assets/img/play_store.png" alt="Disponible sur Google Play"></a>
                </div>
            </div>
        </div>
        <div class="footer-bottom">
            <p style="text-align: left;color: #fff"><b>Siège social : </b> Côte d'ivoire, Abidjan, Riviera Attoban, derrière le 30e arrondissement.</p>
        </div>
    </div>
    <div class="footer-background">
        <p class="copyright">Tous droits réservés - {{year}}</p>
        <img src="assets/img/Rectangle 19.png" alt="Footer Background">
    </div>
</footer>


<ngx-scrolltop></ngx-scrolltop>
