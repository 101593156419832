import {Component, OnInit} from '@angular/core';

declare let $: any;

@Component({
    selector: 'app-politique-billeterie',
    templateUrl: './politique-billeterie.component.html',
    styleUrls: ['./politique-billeterie.component.scss']
})
export class PolitiqueBilleterieComponent implements OnInit {

    imgPay: string = "../../../../../../assets/img/Groupe de masques 4.png"

    constructor() {
    }

    ngOnInit(): void {
        $('#myImgApropos').css({
            'background-image': 'url(' + this.imgPay + ')',
            'background-size': 'cover'
        });
        this.selectNav();
    }

    selectNav() {
        for (let j = 0; j < 5; j++) {
            $('#nav' + j).removeClass("active");
        }
        $('#nav' + 3).addClass('active');
    }

}
