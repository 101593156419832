import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-verify-sms',
  templateUrl: './verify-sms.component.html',
  styleUrls: ['./verify-sms.component.scss']
})
export class VerifySmsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
