<app-header-billeterie></app-header-billeterie>

<section class="mission-area ptb-100" style="padding-top: 0;height: 250px !important;z-index: 1;position: relative;">
    <section class="mission-area"  id="myImgApropos" style="background-color:  #0B2154 !important;height: 250px !important;">
        <app-navbar></app-navbar>
        <div class="container">
            <div class="menu">
                <div class="menu-title">
                    <span>Tickets transférés</span>
                </div>
                <div class="profile">
                    <div class="row _user">
                        <div class="col-4">
                            <svg style="    width: 98%;" xmlns="http://www.w3.org/2000/svg" width="104" height="106" viewBox="0 0 104 106">
                                <g id="Groupe_58" data-name="Groupe 58" transform="translate(-449.57 -116.254)">
                                    <circle id="Ellipse_4" data-name="Ellipse 4" cx="52" cy="52" r="52" transform="translate(449.57 116.255)" fill="#60ba07"/>
                                    <text id="G" transform="translate(501.57 200.254)" fill="#cecece" font-size="87" font-family="Montserrat-Bold, Montserrat" font-weight="700" letter-spacing="0.041em"><tspan x="-32.625" y="0">{{_lettre(dataUsers?.spectateurnom)}}</tspan></text>
                                </g>
                            </svg>

                        </div>
                        <div class="col-8 _inf_user">
                            <span class="profile-text">{{dataUsers?.spectateurnom}} {{dataUsers?.spectateurprenom}}</span> <br>
                            <span class="profile-text">{{dataUsers?.spectateurtel}}</span><br>
                            <span class="profile-text">{{dataUsers?.spectateuremail}}</span>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    </section>
</section>

<div class="courses-details-area pb-70" style="background-color:#006837 ;min-height: 100%;">
    <div class="container">

        <div class="row">
            <div class="col-12">
                <nav class="navCat">
                    <a style="cursor: pointer" [routerLink]="data.uri"  *ngFor="let data of dataNav;let i=index" id="nav{{i}}">{{data.label}}</a>
                </nav>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-2">
            </div>
            <div class="col-lg-8">
                <h2 class="titleListe">Liste des tickets transférés :</h2>
                <div class="courses-details-desc">
                    <div *ngIf="showListTicket && !showTicket" class="order-table table-responsive">
                        <table>
                            <thead>
                            <tr>
                                <th>Date achat</th>
                                <th>Evenement</th>
                                <th>Categorie ticket</th>
                                <th>TYPE</th>
                                <th>Actions</th>
                            </tr>
                            </thead>
                            <tbody >
                            <tr *ngFor="let t of tickets">
                                <td>{{t.ticketdatecreation | date: 'dd/MM/yyyy hh:mm'}}</td>
                                <td>{{t.event}}</td>
                                <td>{{t.caticket}}</td>
                                <td>{{t.typeticket}}</td>
                                <td><a class="view-button" style="cursor: pointer;" (click)="showMyTicket(t)">Voir</a></td>
                            </tr>

                            <tr *ngIf="!tickets.length">
                                <td></td>
                                <td></td>
                                <td>Aucune donnée</td>
                                <td></td>
                                <td></td>
                            </tr>

                            </tbody>
                        </table>

                    </div>

                    <!--        <ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "small" color = "#fff" type = "square-jelly-box" [fullScreen] = "false"><p style="color: white" > Chargement... </p></ngx-spinner>-->


                    <div *ngIf="!showListTicket && showTicket" class="myDashboard-profile">
                        <div class="row align-items-center">





                            <div class="col-lg-12 col-md-12">
                                <div class="profile-content" *ngIf="!sharedTicketQrcode">
                                    <ul class="contact-info">
                                        <li>
                                            <h3>{{ showTicket.event}}</h3>
                                        </li>
                                        <li>
                                            <p> <b>Categorie ticket : </b> {{ showTicket.caticket}}</p>
                                        </li>
                                        <li><b>Ref achat : </b>{{ showTicket.achatcode }}</li>
                                        <li><b>Date achat : </b>{{ showTicket.ticketdatecreation | date: 'dd/MM/yyyy hh:mm' }}</li>
                                        <li><b>Mode Achat : </b> {{ showTicket.provenance}}</li>
                                        <li><b>Montant normal : </b> {{ showTicket.ticketmontantnormal}}</li>
                                        <li><b>Reduction : </b> {{ showTicket.ticketreduction}}</li>
                                        <li><b>Montant payé : </b> {{ showTicket.ticketmontantpayer}}</li>
                                        <li><b>Transferé A : </b> {{ showTicket.spectateurtel}}</li>

                                        <li><b>Beneficiaire Nom : </b> {{ showTicket.beneficiairenom}}</li>
                                        <li><b>Montant Prenom : </b> {{ showTicket.beneficiaireprenom}}</li>
                                        <li><b>Montant Contact : </b> {{ showTicket.beneficiairetel}}</li>

                                        <li>
                                            <a (click)="loadTicket()" style="cursor: pointer; color: white;"
                                               class="myDashboard-logout">Fermer</a>

                                        </li>

                                    </ul>
                                </div>



                            </div>





                        </div>
                    </div>


                </div>
            </div>
            <div class="col-lg-2">

            </div>
        </div>
    </div>
</div>





<div class="my-dashboard-area ptb-100" style="display: none">
    <div class="container">

        <div class="myDashboard-navigation">
            <ul>
                <li><a routerLink="/achat"><i class='bx bx-cart'></i> Mes achats</a></li>
                <li><a routerLink="/ticket"><i class='bx bxs-dashboard'></i> Mes tickets</a></li>
                <li><a routerLink="/foward" class="active"><i class='bx bx-download'></i>Tickets transferés</a></li>
                <li><a routerLink="/receive"><i class='bx bx-recycle'></i> Tickets réçus</a></li>
                <li><a routerLink="/carte"><i class='bx bx-credit-card'></i> Mes cartes</a></li>
                <li><a routerLink="/notification"><i class='bx bx-notification'></i>Notification</a></li>
                <li><a routerLink="/infos"><i class='bx bx-edit'></i> Infos personelles</a></li>
                <li><a (click)="logout()" ><i class='bx bx-user-x'  ></i>Deconnexion</a></li>

            </ul>
        </div>




        <div *ngIf="showListTicket && !showTicket" class="myDashboard-content">

            <h3></h3>

            <div class="product-add-to-cart" style="margin-bottom: 10px;">
                <button (click)="loadTicket()" type="submit" class="default-btn"><span
                        class="label">Rafraichir</span></button>
            </div>

            <div class="recent-orders-table table-responsive">
                <table class="table">
                    <thead>
                        <tr>
                            <th>Date achat</th>
                            <th>Evenement</th>
                            <th>Categorie ticket</th>
                            <th>TYPE</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody *ngIf="tickets && tickets.length>0">
                        <tr *ngFor="let t of tickets">
                            <td>{{t.ticketdatecreation | date: 'dd/MM/yyyy hh:mm'}}</td>
                            <td>{{t.event}}</td>
                            <td>{{t.caticket}}</td>
                            <td>{{t.typeticket}}</td>
                            <td><a class="view-button" style="cursor: pointer;" (click)="showMyTicket(t)">Voir</a></td>
                        </tr>



                    </tbody>
                </table>
            </div>
        </div>

    </div>

</div>
