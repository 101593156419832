import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormBuilder} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {NGXLogger} from 'ngx-logger';
import {OwlOptions} from 'ngx-owl-carousel-o';
import {ToastrService} from 'ngx-toastr';
import {Pub} from '../../spectateur';
import {EventwithoutkeyService} from '../eventwithoutkey.service';

declare let $: any;

@Component({
    selector: 'app-slider-billeterie',
    templateUrl: './slider-billeterie.component.html',
    styleUrls: ['./slider-billeterie.component.scss']
})
export class SliderBilleterieComponent implements OnInit {

    pubs: any = [];
    loading: boolean = false;
    imgDefault: string = "../../../../../../assets/img/panel.JPG";
    // imgDefault: string = "../../../../../../assets/img/mix DJ.png";
    private currentImageIndex: number = 0;
    dataImg: any = [
        {
            url: "../../../../../../assets/img/slide_img/img_1.jpg",
            titre: ``,
            code: ``
        }, {
            url: "../../../../../../assets/img/slide_img/img_2.jpg",
            titre: ``,
            code: ``
        }, {
            url: "../../../../../../assets/img/slide_img/img_3.jpg",
            titre: ``,
            code: ``
        }, {
            url: "../../../../../../assets/img/slide_img/img_4.jpg",
            titre: ``,
            code: ``
        }, {
            url: "../../../../../../assets/img/slide_img/img_5.jpg",
            titre: ``,
            code: ``
        },
    ]
    slideIndex = 0;
    timeoutId;

    constructor(private _activatedRoute: ActivatedRoute, private router: Router,
                private gestwithkey: EventwithoutkeyService, public fb: UntypedFormBuilder,
                private toastr: ToastrService,
                private logger: NGXLogger) {
    }

    ngOnInit(): void {

        this.loadingAllImage();
    }


    goToRouting(path: string) {
        this.router.navigateByUrl(path);
    }

    loadingAllImage() {
        this.gestwithkey.get("/billeterie/allpub").subscribe(data => {
                // this.gestwithkey.get("/billeterie/allpub/NKMXCF21").subscribe(data => {

                // $('#your-element').css('background-image', 'url(' + this.imgDefault + ')');
                // return
                switch (data.status) {

                    case 200: {

                        this.loading = true;

                        this.pubs = data.body;
                        console.log("==> PUB======= ");
                        console.log("this.pubs[0].url ::", this.pubs)
                        let ind = this.pubs.length - 2 >= 0 ? this.pubs.length - 2 : 0
                        console.log("Concert image :::", this.pubs[ind]?.url)
                     //   $('#your-element').css('background-image', 'url(' + this.pubs[0]?.url + ')');
                        let myCat = document.getElementById("myCat");
                        let myEvent = document.getElementById("myEvent");
                        let myContentEvent = document.getElementById("myContentEvent");
                        if (this.pubs[0]?.categorie) {
                            myCat.innerHTML = this.pubs[0]?.categorie;
                        }
                        if (this.pubs[0]?.event) {
                            myEvent.innerHTML = this.pubs[0]?.event;//.replace("CONCERT", "");
                        }

                        if(this.pubs.length){
                            myContentEvent.innerHTML = ` ${this.pubs[0].description1}<br> <br>Ticket disponible ici !`;
                        }


                        console.log("==> PUB======= ");

                        this.timeoutId = setInterval(() => {
                            this.changeBackgroundImage();
                        }, 10000);

                        break;
                    }
                    default: {
                        $('#your-element').css('background-image', 'url(' + this.imgDefault + ')');
                        break;
                    }
                }
            },
            error => {
                switch (error.status) {

                    default: {
                        $('#your-element').css('background-image', 'url(' + this.pubs[0].url + ')');
                        this.loading = true;
                        this.logger.error(error);
                        break;
                    }
                }
            });
    }

    changeBackgroundImage() {

        //this.currentImageIndex = (this.currentImageIndex + 1) % this.dataImg.length;
        this.currentImageIndex = (this.currentImageIndex + 1) % this.pubs.length;
        this.setBackgroundImage();
    }

    setBackgroundImage() {
        $('#your-element').css('background-image', 'url(' + this.imgDefault + ')');
        return
        console.log(this.imgDefault)
        const sliderWrapper = document.getElementById('your-element');
        //let data = this.dataImg[this.currentImageIndex];
        let data = this.pubs[this.currentImageIndex];
        this.changeEvent(data, data.categorie, 0)
        // Utilisation de jQuery pour changer l'image de fond avec animation
        // $('#your-element')
        //     .fadeOut(500, () => {
        //         $(this).css('background-image', 'url(' + data.url + ')');
        //     })
        //     .fadeIn(500); // Animation d'apparition

        console.log("data  =====dfilement ===", data)
        let myContentEvent = document.getElementById("myContentEvent");
        if (myContentEvent) {
            myContentEvent.innerHTML = `${data?.description1 ? data?.description1 : ''}`;
        }
        $('#your-element').css('background-image', 'url(' + data.url + ')');
        // sliderWrapper.style.transform = `translateX(-${this.currentImageIndex * 100}%)`;
    }

    changeEvent(event: any, cat: string, actin: number = 0) {
        if (actin === 1) {
            clearInterval(this.timeoutId);
        }
    }

    instructorSlides: OwlOptions = {
        loop: true,
        nav: true,
        dots: false,
        autoplayHoverPause: true,
        autoplay: false,
        margin: 30,
        navText: [
            "<i class='bx bx-left-arrow-alt'></i>",
            "<i class='bx bx-right-arrow-alt'></i>"
        ],
        responsive: {

            0: {
                items: 1
            },
            576: {
                items: 2
            },
            768: {
                items: 2
            },
            1200: {
                items: 3
            }

        }
    }
}
