import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";

@Component({
    selector: 'app-desabonne',
    templateUrl: './desabonne.component.html',
    styleUrls: ['./desabonne.component.scss']
})
export class DesabonneComponent implements OnInit {
    stepDesactivation: number = 0;

    constructor( private router: Router) {
    }

    ngOnInit(): void {
    }

    step() {
        this.stepDesactivation++;
        if(this.stepDesactivation==7){
            this.router.navigate(['/'])
        }
    }
}
