<app-header-billeterie></app-header-billeterie>
<div class="contact-info-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-2 col-md-6 col-sm-6">
               
            </div>
            <div class="col-lg-8 col-md-6 col-sm-6">


                <div *ngIf="!loadingCheck" class="d-flex justify-content-center">
                    <div class="spinner-border" style="font-size: 30px;
                    height: 120px;
                    width: 120px;
                    color: red;" role="status">
                        <span class="sr-only"></span>
                    </div>
                </div>

                <div *ngIf="loadingCheck" class="contact-info-box mb-30">
                    <h3> CODE : {{achat.achatcode}}</h3>
                    <h3> MONTANT : {{achat.achatmontantpayer}} FRS</h3>
                    <div *ngIf="achat.achatlevel==1" class="icon">
                        <i class='bx bx-certification'></i>
                    </div>
                    <h3 *ngIf="achat.achatlevel==1" style="color: green"> SUCCESS</h3>

                    <div *ngIf="achat.achatlevel==0" class="icon" style="color: red">
                        <i class='bx bx-window-close'></i>
                    </div>
                    <h3 *ngIf="achat.achatlevel==0" style="color: red"> AUCUN PAIEMENT</h3>
                    
                    
                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
               
            </div>
        </div>
    </div>
</div>