import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-news-billeterie',
  templateUrl: './news-billeterie.component.html',
  styleUrls: ['./news-billeterie.component.scss']
})
export class NewsBilleterieComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  submit(form){
      var name = form.name;
      console.log(name);
      
      var email = form.email;
      console.log(email);

      var number = form.number;
      console.log(number);
      
      var subject = form.subject;
      console.log(subject);
      
      var message = form.message;
      console.log(message);
  }

}
