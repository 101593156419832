<div class="instructor-slides">

<!--    <strong style="font-size: 16px !important;font-family: Verdana; color:#000 !important;    text-shadow: 0 0 2px #000;">-->
<!--        CONCERTS-->
<!--    </strong>-->
<!--    <owl-carousel-o *ngIf="loading" [options]="instructorSlides">-->
<!--        <ng-template carouselSlide *ngFor="let p of pubs">-->
<!--            <div class="single-instructor-box mb-30">-->
<!--                <div class="image" style="height: 240px !important; width: 240px !important;">-->
<!--                    <img style="height: 100% ; cursor: pointer;" (click)="goToRouting('/check-event?key='+p.eventcode)"-->
<!--                        [src]="p.url" alt="image">-->
<!--                </div>-->
<!--            </div>-->
<!--        </ng-template>-->
<!--    </owl-carousel-o>-->
</div>
