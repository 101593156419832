import {Component, OnInit} from '@angular/core';

declare let $: any;

@Component({
    selector: 'app-propos-billeterie',
    templateUrl: './propos-billeterie.component.html',
    styleUrls: ['./propos-billeterie.component.scss']
})
export class ProposBilleterieComponent implements OnInit {
    imgPay: string = "../../../../../../assets/img/Groupe de masques 4.png"

    constructor() {
    }

//
    ngOnInit(): void {
        $('#myImgApropos').css({
            'background-image': 'url(' + this.imgPay + ')',
            'background-size': 'cover'
        });
        this.selectNav();
    }

    selectNav() {
        for (let j = 0; j < 5; j++) {
            $('#nav' + j).removeClass("active");
        }
        $('#nav' + 3).addClass('active');
    }
}
