<div class="hero-banner">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container-fluid">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-12">
                        <div class="hero-banner-content">
                            <span class="sub-title">We are Pioneers</span>
                            <h1>Learn a new skill from online courses</h1>
                            <p>Raque supports students by introducing collaborators outside R, internships and research experience abroad.</p>
                            <div class="btn-box">
                                <a routerLink="/" class="default-btn"><i class='bx bx-move-horizontal icon-arrow before'></i><span class="label">Acheter tickets</span><i class="bx bx-move-horizontal icon-arrow after"></i></a>

                                <!--
                                   <a routerLink="/contact" class="optional-btn">Get Started Free</a>
                                -->
                            </div>
                        </div>
                    </div>
                    <!--
                          <div class="col-lg-6 col-md-12">
                        <div class="hero-banner-image text-center">
                            <img src="assets/img/banner-img1.jpg" alt="image">
                        </div>
                    </div>
                    -->
                  
                </div>
            </div>
        </div>
    </div>
</div>