import {Component, OnInit, ViewChild} from '@angular/core';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {NGXLogger} from 'ngx-logger';
import {TabsetComponent} from 'ngx-tabset';
import {ToastrService} from 'ngx-toastr';
import {environment} from 'src/environments/environment';
import {CinetpayService} from '../home-billeterie/cinetpay.service';
import {EventwithkeyService} from '../home-billeterie/eventwithkey.service';
import {EventwithoutkeyService} from '../home-billeterie/eventwithoutkey.service';
import {
    Achat,
    CheckLevel1Req,
    CheckLevel1Resp,
    CheckLevel2, checkPay,
    Item, notifPay,
    Responsecheck2,
    ResponseCinetPay,
    Spectateur
} from '../spectateur';
import {dataSMS} from "../../../core/config-texte";
import * as moment from "moment";
import {Configapp, decode64} from "../../../core/config";
import PaystackPop from '@paystack/inline-js'
import {NzMessageService} from "ng-zorro-antd/message";
import {NzModalService} from "ng-zorro-antd/modal";
import {MaskApplierService} from "ngx-mask";

declare var CinetPay: any;
declare let $: any;

@Component({
    selector: 'app-payment-billeterie',
    templateUrl: './payment-billeterie.component.html',
    styleUrls: ['./payment-billeterie.component.scss']
})
export class PaymentBilleterieComponent implements OnInit {
    loadingCheck: any = false;
    achat: Achat = new Achat();
    categerieSlect: any = [];
    ticketMax: any = 0;
    ticketQrcode: any = 0;
    ticketCarte: any = 0;
    ticketNow: any = 0;
    pu: number = 0;
    pt: number = 0;

    activeFrist: boolean = true;
    activeSecond: boolean = false;
    activeThree: boolean = false;


    checkMaxticket: boolean = false;
    errorMaxticket: string;


    checkFinale: boolean = false;
    errorFinal: string;


    user: Spectateur;
    key: string;
    type: string;
    caticketlib: string;

    responseLevel1: CheckLevel1Resp = null;

    isPay: boolean = true;
    isFree: boolean = false;
    isOpean: boolean = false;

    beneficiaireForm: UntypedFormGroup;
    openForm: UntypedFormGroup;

    code_carte: string = `${environment.keyCarte}`;
    code_qrcode: string = `${environment.keyQrcode}`;

    responseStep2: Responsecheck2 = null;
    hasReduction: boolean = false;
    dataSms: any = [];
    dataPayTicket: any = [];
    u: Spectateur;
    @ViewChild('staticTabs', {static: false}) staticTabs: TabsetComponent;
    msg: any = "";
    dataMenu: any = [
        {
            uri: "/",
            label: "Accueil",
        }, {
            uri: "/concert",
            label: "Découvrir",
        }, {
            uri: "/connecter",
            label: "Mon profil",
        }
    ]
    imgPay: string = "../../../../../../assets/img/party.jpg"
    private dataGet: any = [];
    dContry: any = [];


    selectedCountryCode = 'us';
    countryCodes = ['us', 'lu', 'de', 'bs', 'br', 'pt'];
    indicatifPays: string = "225";
    pourMoi: boolean = false;

    changeSelectedCountryCode(value: string): void {
        this.selectedCountryCode = value;
    }

    dataCheckEvent: any = [];
    dataCarte: any = [];
    dataUsers: any = [];

    constructor(private _activatedRoute: ActivatedRoute, private router: Router,
                private gestwithkey: EventwithkeyService, public fb: UntypedFormBuilder,
                private checkwithkey: EventwithoutkeyService,
                private modal: NzModalService,
                private toastr: ToastrService, private cinetpay: CinetpayService,
                private logger: NGXLogger, private gestwithoutkey: EventwithoutkeyService,
                private Conf: Configapp, private message: NzMessageService,private maskApplierService: MaskApplierService) {
        this.beneficiaireForm = this.fb.group({
            beneficiaires: this.fb.array([]),
            indicatifPays: this.fb.array([]),
        });
    }

    ngOnInit(): void {
        let u = new Spectateur();
        u = JSON.parse(localStorage.getItem(`${environment.keylocalstorage}`));
        this.dataUsers = u;
        const userSession = localStorage.getItem(environment.APP_TOKEN_ACHAT);

        if (userSession) {
            this.dataCheckEvent = JSON.parse(this.Conf.getDecrypt(userSession, decode64(environment.APP_PASS)));
        }
        $('#myImgAchat').css({
            'background-image': 'url(' + this.dataCheckEvent.url + ')',
            'background-size': 'cover'
        });
        this.dataCarte = this.dataCheckEvent.listCatCarte.slice(0, 3)
        this.loadPays();
        this.u = JSON.parse(localStorage.getItem(`${environment.keylocalstorage}`));
        this.openForm = this.fb.group({
            'montant': [null, Validators.required]
        });

        this._activatedRoute.queryParams.forEach((params: Params) => {

            if (params['key'] && params['type']) {
                this.key = params['key'];
                this.type = params['type'];
                this.caticketlib = params['caticketlib'];
                this.checkCategorie(params['key']);

                if (this.type === `${environment.categorieTicketGratuit}`) {
                    this.isPay = false;
                    this.isOpean = false;
                    this.isFree = true;

                }
                if (this.type === `${environment.categorieTicketLibre}`) {
                    this.isPay = false;
                    this.isOpean = true;
                    this.isFree = false;
                }
                if (this.type === `${environment.categorieTicketPayant}`) {
                    this.isPay = true;
                    this.isOpean = false;
                    this.isFree = false;
                }

            } else {
                this.router.navigate(['/']);
            }
        });


    }

    validateInput(input) {
        // Vérifier si la valeur est plus grande que la valeur maximale
        if (input.value > input.max) {
            input.value = input.max;
        }
        // Vérifier si la valeur est plus petite que la valeur minimale
        if (input.value < input.min) {
            input.value = input.min;
        }
    }

    loadPays() {

        this.checkwithkey.get(`/billeterie/session/getAllListPays`).subscribe(data => {

                switch (data.status) {
                    case 200: {

                        if (data.body.length) {

                            this.dContry = data.body.map((d) => {
                                return {
                                    ...d,
                                    country: d.paysNom,
                                    indicatif: d.paysIndicatif,
                                    code: d.paysCode,
                                }
                            });
                        }

                        break;
                    }
                    default : {

                    }
                }
            },
            error => {

                switch (error.status) {
                    case 401: {
                        //this.router.navigate(['/authentication/signin']);
                        break;
                    }
                    default: {
                        this.logger.trace(error);
                        break;
                    }
                }
            });
    }

    get beneficiaires(): UntypedFormArray {
        return this.beneficiaireForm.get("beneficiaires") as UntypedFormArray
    }

    addBeneficiares(code) {
        if (code === 1) {
            this.beneficiaires.push(this.newBeneficiareCarte());
        } else if (code === 2) {
            this.beneficiaires.push(this.newBeneficiareQrcode());
        }
    }


    newBeneficiareCarte(): UntypedFormGroup {
        return this.fb.group({
            'type': [`${environment.keyCarte}`, Validators.required],
            'carte': [null, Validators.required],
            'telephone': [null],
            'indicatifPays': ['225'],
            'nom': [null, Validators.required],
            'prenom': [null, Validators.required]
        })
    }


    newBeneficiareQrcode(): UntypedFormGroup {
        return this.fb.group({
            'type': [`${environment.keyQrcode}`, Validators.required],
            'carte': [null],
            'indicatifPays': ['225'],
            'telephone': [null, Validators.required],
            'nom': [null, Validators.required],
            'prenom': [null, Validators.required]
        })
    }

    valueChangeCarte(newValue) {
        this.ticketCarte = newValue;
        this.checkMaxTicket();
    }

    valueChangeQrcode(newValue) {
        this.ticketQrcode = newValue;
        this.checkMaxTicket();
    }

    checkSum() {
        this.ticketNow = this.ticketCarte + this.ticketQrcode;
        this.pt = this.pu * this.ticketNow;
        this.logger.trace(this.pt + " - " + this.ticketNow);
    }


    checkMaxTicket() {
        if ((parseInt(this.ticketCarte) + parseInt(this.ticketQrcode)) > parseInt(this.ticketMax)) {
            this.checkMaxticket = true;
            this.errorMaxticket = "Il reste " + this.ticketMax + " en stock. Ne depassez pas cette limite";
            return;
        } else if ((parseInt(this.ticketCarte) + parseInt(this.ticketQrcode)) == 0) {
            this.checkMaxticket = true;
            this.errorMaxticket = "Veuillez renseigner un total de ticket superieur à 0";
            return;

        } else if ((parseInt(this.ticketCarte) + parseInt(this.ticketQrcode)) > parseInt(this.dataCheckEvent.nbrTicketParPersonne)) {
            this.checkMaxticket = true;
            this.errorMaxticket = "Veuillez renseigner un total de ticket inférieur ou égale à " + this.dataCheckEvent.nbrTicketParPersonne;
            return;

        } else {
            this.checkMaxticket = false;
            this.errorMaxticket = "";
        }
        this.checkSum();
    }


    NextStep1() {
        //  this.pay();
        //this.makePayment();
        if ((parseInt(this.ticketCarte) + parseInt(this.ticketQrcode)) > parseInt(this.ticketMax)) {
            this.checkMaxticket = true;
            this.errorMaxticket = "Il reste " + this.ticketMax + " en stock. Ne depassez pas cette limite";
            return;
        } else if ((parseInt(this.ticketCarte) + parseInt(this.ticketQrcode)) == 0) {
            this.checkMaxticket = true;
            this.errorMaxticket = "Veuillez renseigner un total de ticket superieur à 0";
            return;

        } else if ((parseInt(this.ticketCarte) + parseInt(this.ticketQrcode)) > parseInt(this.dataCheckEvent.nbrTicketParPersonne)) {
            this.checkMaxticket = true;
            this.errorMaxticket = "Veuillez renseigner un total de ticket inférieur ou égale à " + this.dataCheckEvent.nbrTicketParPersonne;
            return;

        } else {

            /*
            this.checkMaxticket = false;
            this.errorMaxticket = "";

            this.activeFrist = false;
            this.activeSecond = true;
            */
            // dataPayTicket
            this.checkNbreTicket();
            let leng = (parseInt(this.ticketCarte) + parseInt(this.ticketQrcode));
            for (let i = 0; i < leng; i++) {
                this.dataPayTicket.push({
                    pourMoi: '',
                    nom: '',
                    prenom: '',
                    indicatifPays: '225',
                    telephone: '',
                    carte: '',
                })
            }
            if (leng != (this.dataPayTicket.length)) {
                let dif = this.dataPayTicket.length - leng;
                this.dataPayTicket = this.dataPayTicket.slice(0, -dif)
            }

        }
    }


    checkNbreTicket() {
        let request: CheckLevel1Req = new CheckLevel1Req();
        request.categorieticket = this.key;
        request.check = false;
        request.code = "KCQHZ113";
        request.nbre = parseInt(this.ticketCarte) + parseInt(this.ticketQrcode);
        // {
        //     "categorieticket": "PGQL3088",
        //     "check": false,
        //     "code": "KCQHZ113",
        //     "nbre": 1
        // }
        this.gestwithkey.post(request, "/billeterie/payments/checklevel1", this.user.token).subscribe(data => {
                this.logger.trace('INFOS CNX', data);

                switch (data.status) {
                    case 200: {
                        this.logger.trace(data.body);
                        this.responseLevel1 = data.body;
                        this.checkMaxticket = false;
                        this.errorMaxticket = "";

                        this.activeFrist = false;
                        this.activeSecond = true;
                        this.activeThree = false;

                        this.beneficiaireForm = this.fb.group({
                            beneficiaires: this.fb.array([]),
                        });

                        if (this.ticketCarte > 0) {
                            for (let index = 0; index < this.ticketCarte; index++) {
                                this.addBeneficiares(1);
                            }
                        }

                        if (parseInt(this.ticketQrcode) > 0) {
                            for (let index = 0; index < parseInt(this.ticketQrcode); index++) {
                                this.addBeneficiares(2);
                            }
                        }


                        this.logger.trace(this.beneficiaireForm.value);

                        break;
                    }
                }
            },
            error => {
                //this.loader.close();

                this.logger.error('ERROR', error);
                switch (error.status) {

                    case 428: {
                        this.checkMaxticket = true;
                        this.errorMaxticket = "le nombre de ticket demandé est superieur au stock en cours";
                        break;
                    }


                    default: {
                        this.checkMaxticket = true;
                        this.errorMaxticket = "Erreur survenue veuillez ressayer";
                        this.toastr.error('Echec!', 'Erreur survenue veuillez ressayer');

                        break;
                    }
                }
            });
    }


    PrecStep2() {
        this.activeFrist = true;
        this.activeSecond = false;
        this.activeThree = false;
    }


    doSomethingOnTabSelect(event) {
        if (event === 1) {
            this.logger.info("--- EVENT ---- " + 1);
            this.activeFrist = false;
            this.activeSecond = true;
            this.activeThree = false;
        } else if (event === 2) {
            this.activeFrist = false;
            this.activeSecond = false;
            this.activeThree = true;
        } else {
            this.logger.info("--- EVENT ---- " + 0);
            this.activeFrist = true;
            this.activeSecond = false;
            this.activeThree = false;

        }
    }


    checkCategorie(code) {
        this.user = JSON.parse(localStorage.getItem(`${environment.keylocalstorage}`));
        if (!this.user) {
            this.toastr.error('Echec!', 'Veuillez vous connecter afin de finaliser le paiement');
            this.router.navigate(['/']);
        }

        this.gestwithkey.get("/billeterie/payments/categorieticket/" + code, this.user.token).subscribe(data => {
                switch (data.status) {
                    case 200: {
                        this.selMenu(1);
                        this.categerieSlect = data.body;
                        console.log("this.categerieSlect ===", this.categerieSlect)
                        this.pu = this.categerieSlect.caticketarif;
                        this.pt = this.pu * this.ticketNow;
                        this.ticketMax = this.categerieSlect.caticketqte - this.categerieSlect.caticketqtenow;
                        if (this.ticketMax <= 0) {
                            this.toastr.error('Echec!', 'Guichet fermé');
                            this.router.navigate(['/']);
                        }
                        this.logger.trace(this.categerieSlect);

                        break;
                    }
                }
            },
            error => {
                switch (error.status) {

                    default: {
                        //this.toastr.error('Echec!', 'Veuillez vous connecter afin de finaliser le paiement');
                        this.router.navigate(['/']);
                        break;
                    }
                }
            });

    }

    checkSecondFormulaire() {

        const benFormArray = this.beneficiaireForm.get('beneficiaires') as UntypedFormArray;
        let items: Item[] = [];

        let errorData: any = this.dataPayTicket.filter((d) => !d.indicatifPays || !d.telephone || !d.nom || !d.prenom);

        if (errorData.length) {
            this.modal.info({
                nzTitle: '<b>ACHAT DE TICKET</b>',
                nzContent: `<p>Il y a des informations qui n'ont pas été fournies concernant certains bénéficiaires.</p>`,
                nzOkText: 'Fermer',
                nzCentered: true,
                nzOnOk: () => {
                }
            });
            return;
        }
        if (this.dataPayTicket.length > this.dataCheckEvent.nbrTicketParPersonne) {
            this.modal.info({
                nzTitle: '<b>ACHAT DE TICKET</b>',
                nzContent: `<p>Vous n'êtes pas autorisé à achèter plus que le nombre de ticket par participant.</p>`,
                nzOkText: 'Fermer',
                nzCentered: true,
                nzOnOk: () => {
                }
            });
            return;
        }
        let request: CheckLevel2 = new CheckLevel2();
        console.log("this.dataPayTicket====", this.dataPayTicket)
        items = this.dataPayTicket;
        // for (let index = 0; index < benFormArray.length; index++) {
        //     const element = benFormArray.controls[index] as UntypedFormGroup;
        //     let it: Item = new Item();
        //     if (element.invalid) {
        //
        //
        //         let nameFormControl = element.controls.nom;
        //         if (nameFormControl.invalid) {
        //             this.toastr.error('  veuillez renseigner le nom du Beneficiaire ' + (index + 1));
        //             return;
        //         }
        //
        //         let lastnameFormControl = element.controls.prenom;
        //         if (lastnameFormControl.invalid) {
        //             this.toastr.error('  veuillez renseigner le prenom du Beneficiaire ' + (index + 1));
        //             return;
        //         }
        //
        //
        //         let carteFormControl = element.controls.carte;
        //         if (carteFormControl.invalid) {
        //             this.toastr.error('  veuillez renseigner la carte du Beneficiaire ' + (index + 1));
        //             return;
        //         }
        //
        //         let telephoneFormControl = element.controls.telephone;
        //         if (telephoneFormControl.invalid) {
        //             this.toastr.error('  veuillez renseigner le telephone du Beneficiaire ' + (index + 1));
        //             return;
        //         }
        //
        //     }
        //     it.numero = index + 1;
        //     it.type = element.value.type;
        //     it.nom = element.value.nom;
        //     it.prenom = element.value.prenom;
        //     it.carte = element.value.carte;
        //     // it.telephone = element.value.telephone;
        //     it.telephone = element.value.indicatifPays + "" + element.value.telephone;
        //     items.push(it);
        //     this.logger.info(items);
        // }
        //

        if (this.dataPayTicket.length > 0) {
            // if (items && items.length > 0) {
            let dd: any = this.dataPayTicket.map((d) => {
                return {
                    ...d,
                    email: '',
                    numero: 0,
                    type: "string",
                    telephone: d.indicatifPays + d.telephone
                }
            })
            request.items = dd;
            request.checkcode = false;
            request.caticketcode = this.categerieSlect.caticketcode;
            request.eventcode = this.categerieSlect.eventcode;
            request.usercode = this.user.spectateurcode;
            request.montant = this.responseLevel1.cumul;
            request.reduction = '0';
            request.web = true;

            this.gestwithkey.post(request, "/billeterie/payments/checkitem", this.user.token).subscribe(data => {
                    this.logger.trace('INFOS CNX', data);

                    switch (data.status) {
                        case 200: {
                            this.logger.info(data.body);
                            this.responseStep2 = data.body;
                            this.responseStep2.items = items;

                            if (this.responseStep2 && this.responseStep2.allreductions && this.responseStep2.allreductions.length > 0) {
                                this.hasReduction = true;
                            }

                            this.activeFrist = false;
                            this.activeSecond = false;
                            this.activeThree = true;
                            this.checkFinale = true


                            break;
                        }
                    }
                },
                error => {
                    //this.loader.close();

                    this.logger.error('ERROR', error);
                    switch (error.status) {


                        case 400: {
                            this.logger.info(error.error);
                            let check: any = error.error;
                            this.modal.info({
                                nzTitle: '<b>ACHAT DE TICKET</b>',
                                nzContent: `<p>${error.error}</p>`,
                                nzOkText: 'Fermer',
                                nzCentered: true,
                                nzOnOk: () => {
                                }
                            });
                            break;
                        }


                        case 406: {
                            this.logger.info(error.error);
                            let check: any = error.error;
                            if (check.type === `${environment.keyQrcode}`) {
                                this.toastr.error('Echec!', 'Le numero de telephone    ' + check.telephone + '   du beneficiaire  ' + check.numero + '    est lié à aucun compte ');
                            } else if (check.type === `${environment.keyCarte}`) {
                                this.toastr.error('Echec!', 'Veuillez verifier la carte saisie     ' + check.carte + '    pour le beneficiaire  ' + check.numero);
                            }
                            break;
                        }

                        case 417: {
                            this.logger.info(error.error);
                            let check: any = error.error;
                            if (check.type === `${environment.keyCarte}`) {
                                this.toastr.error('Echec!', 'La carte      ' + check.carte + ' du beneficiaire  ' + check.numero + '   est déja enregistré pour cet environement');
                            }
                            break;
                        }

                        case 423: {
                            this.toastr.error('Echec!', 'Le stock de ticket est insuffisant');
                            break;
                        }

                        case 424: {
                            this.logger.info(error.error);
                            let check: any = error.error;
                            if (check.type === `${environment.keyQrcode}`) {
                                this.toastr.error('Echec!', 'Le numero de telephone    ' + check.telephone + '   du beneficiaire  ' + check.numero + '    est lié à aucun compte ');
                            } else if (check.type === `${environment.keyCarte}`) {
                                this.toastr.error('Echec!', 'Veuillez verifier la carte saisie     ' + check.carte + '    pour le beneficiaire  ' + check.numero);
                            }
                            break;
                        }

                        default: {
                            this.logger.info(error.error);

                            this.toastr.error('Echec!', 'Erreur survenue veuillez ressayer');

                            break;
                        }
                    }
                });
        }
    }

    saveTicket() {
        this.user = JSON.parse(localStorage.getItem(`${environment.keylocalstorage}`));
        let dd: any = this.dataPayTicket.map((d) => {
            return {
                ...d,
                email: '',
                numero: 0,
                "type": "KCQHZ113",
                telephone: d.indicatifPays + d.telephone
            }
        })
        if (!this.user) {
            this.toastr.error('Echec!', 'Veuillez vous connecter afin de finaliser le paiement');
            return;
            //this.router.navigate(['/']);
        }

        /*
        if (this.isOpean || this.isPay) {
          this.toastr.error('Echec!', '');
          return;
          //this.router.navigate(['/']);
        }
        */
        if (!this.responseStep2 || !this.categerieSlect) {
            this.toastr.error('Echec!', 'Veuillez verfier et finaliser les étapes précedantes');
            return;
            //this.router.navigate(['/']);
        }


        let request: CheckLevel2 = new CheckLevel2();

        this.responseStep2.items = this.responseStep2.items.map((d) => {
            return {
                ...d,
                "email": "string",
                telephone: `${d.telephone}`
            }
        })

        request.items = dd  //
        // request.items = this.responseStep2.items  //
        request.checkcode = false;
        request.caticketcode = this.categerieSlect.caticketcode;
        request.eventcode = this.categerieSlect.eventcode;
        request.usercode = this.user.spectateurcode;
        request.montant = this.responseLevel1.cumul;
        request.reduction = "string";
        request.web = true;

        console.log("request ==============", request)


        if (this.isOpean && !this.openForm.valid) {
            this.toastr.error('Echec!', 'Veuillez saisir le montant libre ');
            return;
        }

        this.logger.trace(request);

        let url: string = "";

        if (this.isPay) {
            url = "/billeterie/payments/save";
        }

        if (this.isOpean) {
            request.montant = this.openForm.get("montant").value;
            url = "/billeterie/payments/open";
        }


        if (this.isFree) {
            url = "/billeterie/payments/free";
        }
        this.dataGet = [];

        this.gestwithkey.post(request, url, this.user.token).subscribe(data => {
                this.logger.trace('INFOS CNX', data);
                let res = data.body.achat
                let tTicket = this.ticketQrcode ? "QR_CODE" : "CARTE"
                this.msg = dataSMS.achat_ticket.replace(new RegExp(`<%beneficiaire%>`, 'gi'), `${request.items[0].nom.toUpperCase() + ' ' + request.items[0].prenom}`)
                this.msg = this.msg.replace(new RegExp(`<%time%>`, 'gi'), `${moment(new Date()).format('DD/MM/YYYY HH:mm')}`)
                this.msg = this.msg.replace(new RegExp(`<%reference_achat%>`, 'gi'), `${res.achatcode}`)
                this.msg = this.msg.replace(new RegExp(`<%mode_achat%>`, 'gi'), `${res.provenance}`)
                this.msg = this.msg.replace(new RegExp(`<%type_ticket%>`, 'gi'), `${tTicket}`)
                this.msg = this.msg.replace(new RegExp(`<%nom_evenement%>`, 'gi'), `${res.event}`)
                this.msg = this.msg.replace(new RegExp(`<%nom_categorie_ticket%>`, 'gi'), `${res.typeacheteur}`)

                switch (data.status) {
                    case 200: {
                        this.logger.info(data.body);
                        this.logger.info("this.responseStep2.items[0] :::", this.responseStep2.items[0]);

                        this.dataSms.push(this.responseStep2.items[0].telephone)
                        this.dataSms.push(this.u?.spectateurtel + "02")

                        let response: any = data.body;
                        this.dataGet = data.body;

                        console.log("SAVE DATA TO PAIEMENT ====== this.dataGet ========", this.dataGet)

                        if (this.isPay || this.isOpean) {
                            console.log(response);

                            let cinetpay: ResponseCinetPay = response;


                            this.checkPayStack(cinetpay.requestCinetpay, cinetpay.url);

                            //window.open(response.url , "_self");
                            //this.sendCinetpay(response.achatmontantpayer, response.achatcode, this.user.token);
                        }

                        if (this.isFree) {
                            let requestSend: any = {};
                            requestSend.achatcode = response.achatcode;
                            this.ticketFree(requestSend);
                        }
                        break;
                    }
                }
            },
            error => {
                //this.loader.close();

                this.logger.error('ERROR', error);
                switch (error.status) {


                    case 406: {
                        this.logger.info(error.error);
                        let check: any = error.error;
                        if (check.type === `${environment.keyQrcode}`) {
                            this.toastr.error('Echec!', 'Le numero de telephone    ' + check.telephone + '   du beneficiaire  ' + check.numero + '    est lié à aucun compte ');
                        } else if (check.type === `${environment.keyCarte}`) {
                            this.toastr.error('Echec!', 'Veuillez verifier la carte saisie     ' + check.carte + '    pour le beneficiaire  ' + check.numero);
                        }
                        break;
                    }

                    case 417: {
                        this.logger.info(error.error);
                        let check: any = error.error;
                        if (check.type === `${environment.keyCarte}`) {
                            this.toastr.error('Echec!', 'La carte      ' + check.carte + ' du beneficiaire  ' + check.numero + '   est déja enregistré pour cet environement');
                        }
                        break;
                    }

                    case 423: {
                        this.toastr.error('Echec!', 'Le stock de ticket est insuffisant');
                        break;
                    }

                    case 424: {
                        this.logger.info(error.error);
                        let check: any = error.error;
                        if (check.type === `${environment.keyQrcode}`) {
                            this.toastr.error('Echec!', 'Le numero de telephone    ' + check.telephone + '   du beneficiaire  ' + check.numero + '    est lié à aucun compte ');
                        } else if (check.type === `${environment.keyCarte}`) {
                            this.toastr.error('Echec!', 'Veuillez verifier la carte saisie     ' + check.carte + '    pour le beneficiaire  ' + check.numero);
                        }
                        break;
                    }

                    default: {
                        this.modal.error({
                            nzTitle: '<b>Echec</b>',
                            nzContent: `<p style="color: #000 !important;">${error.error || 'Une erreur est survenue, merci ressayer svp.'}</p>`,
                            nzOkText: 'Fermer',
                            nzCentered: true,
                            nzOnOk: () => {
                            }
                        });
                        // this.toastr.error('Echec!', error.error);

                        break;
                    }
                }
            });


    }


    async sendCinetpay(amount, code, key) {
        var result = await this.cinetpay.seamless(amount, code, key);
    }

    checkPayStack(request, url) {
        console.log("URL ==> " + url);
        console.log(JSON.stringify(request));
        console.log("-----------------------------");

        this.makePayment();

        return;

        this.checkwithkey.postCinetpay(request, url).subscribe(data => {
                switch (data.status) {
                    case 200: {

                        this.logger.info(data.body);
                        let response: any = data.body;

                        if (response.code === "201") {


                            //  window.open(dataGet.payment_url, "_self");

                        }
                        break;
                    }
                }
            },
            error => {
                switch (error.status) {

                    default: {
                        this.toastr.error('Echec!', 'Ticket non generé');

                        this.logger.error(error);
                        break;
                    }
                }
            });
    }


    ticketFree(request) {
        let u: Spectateur;
        u = JSON.parse(localStorage.getItem(`${environment.keylocalstorage}`));
        this.logger.info(u);
        if (u) {
            this.gestwithkey.post(request, "/billeterie/payments/ticket", this.user.token).subscribe(data => {
                    switch (data.status) {
                        case 200: {
                            this.toastr.success('Succes!', 'Achat effectué');
                            this.dataSms.forEach((e) => {
                                let payload = {
                                    "to": "225" + e,
                                    "contente": this.msg
                                }
                                this.onSendSMS(payload);
                            })
                            this.router.navigate(['/achat']);

                            break;
                        }
                    }
                },
                error => {
                    switch (error.status) {

                        default: {
                            this.toastr.error('Echec!', 'Ticket non generé');

                            this.logger.error(error);
                            break;
                        }
                    }
                });
        }
    }

    onSendSMS(payload) {
        //this.senderSms = true;
        this.gestwithkey.postSender(payload, "/api/sendSms").subscribe((data: any) => {
                // this.logger.trace('INFOS CNX', data);
                switch (data.body.data.status) {
                    case "submitted": {
                        break;
                    }
                }
            },
            error => {
                this.logger.error('ERROR', error);
                this.toastr.error('Echec!', "Une erreur est survenue ");
            });

    }

    selMenu(i: number) {
        for (let j = 0; j < this.dataMenu.length; j++) {
            $('#ligneMenu' + j).removeClass("activeItem");
        }
        $('#ligneMenu' + i).addClass('activeItem');
    }

//       fetchTransactionDetailsAndSave(reference) {
//         try {
//             // Effectue un appel API pour récupérer les détails de la transaction
//             const response = await fetch("https://api.paystack.co/transaction/verify/${reference}"}, {
//             method: 'GET',
//                 headers: {
//                 'Authorization': 'Bearer sk_live_00bf358e2bf2270cc4ba8a7c64cc7d747bd582f1',
//             },
//         });
//
//         // Analyse la réponse pour extraire les données de la transaction
//         const data = await response.json();
//         console.log("@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@");
//         console.log(data);
//
//         if (response.status === 200) {
//             // Traitement des données de la transaction si la requête est réussie
//             console.log('Transaction details:', data);
//         } else {
//             // Gérer les erreurs
//             console.error('Failed to fetch transaction details');
//             window.location.href = "/index"; // Redirige vers la page d'accueil ou une autre page appropriée
//         }
//     } catch (error) {
//         console.error('Error:', error);
//     }
// }
    makePayment() {

        var email = (this.u.spectateuremail && this.u.spectateuremail != 'string') ? this.u.spectateuremail : 'supporttechnique@nbh.ci';
        var amountWithFrais = this.responseLevel1.total; // Assurez-vous que cette valeur est en centimes
        var amount = amountWithFrais / 100
        let me = this;
        var handler = PaystackPop.setup({
            key: 'pk_live_1dff8534aef1c61c42a76a1350dc6361f4265d0f', // Remplacez par votre clé publique
            // key: 'pk_test_6f92695541622f0d580fb161f226b001c4043a48', // Remplacez par votre clé publique
            email: email,
            amount: amountWithFrais * 100, // Montant en centimes
            currency: 'XOF',
            ref: Math.floor(Math.random() * 100000000).toString(), // Référence unique
            callback: function (response) {
                console.log('Payment complete! Reference: ' + response.reference);
                console.log('Payment complete! Response: ', response);

                me.fetchTransactionDetailsAndSave(response.reference);

                // Soumettre le formulaire avec les détails du paiement
                //document.getElementById('paymentForm').submit();
            },
            onClose: function (err) {
                console.log("error ==", err)
                // alert('Transaction was not completed, window closed.');
                //window.location.href = "/paiement/paiement_cart_echec";
            },
        });
        handler.openIframe();

    }

    async fetchTransactionDetailsAndSave(reference) {
        let me = this;
        try {
            // Effectue un appel API pour récupérer les détails de la transaction
            const response = await fetch(`https://api.paystack.co/transaction/verify/${reference}`, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer pk_live_1dff8534aef1c61c42a76a1350dc6361f4265d0f',
                    // 'Authorization': 'Bearer sk_test_a6c2b892f42d8b88e35809ce07cb351741045706',
                },
            });

            // Analyse la réponse pour extraire les données de la transaction
            const data = await response.json();
            console.log("@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@");
            console.log(data);

            if (response.status === 200) {
                // Traitement des données de la transaction si la requête est réussie
                console.log('Transaction details:', data);

                me.pushRequest(data);
            } else {
                // Gérer les erreurs
                console.error('Failed to fetch transaction details');
                window.location.href = "/index"; // Redirige vers la page d'accueil ou une autre page appropriée
            }
        } catch (error) {
            console.error('Error:', error);
        }
    }

    makePayment1() {

        let me = this;

        CinetPay.setConfig({
            apikey: environment.cinetpayApikey,
            site_id: environment.cinetpaySiteid,
            notify_url: 'https://www.tickey.ci/login',
            mode: 'PRODUCTION'
        });
        CinetPay.getCheckout({
            transaction_id: Math.floor(Math.random() * 100000000).toString(), // YOUR TRANSACTION ID
            amount: this.responseStep2.montantpayer,
            currency: 'XOF',
            channels: 'ALL',
            description: 'Paiement ticket NBH',
            //Fournir ces variables pour le paiements par carte bancaire
            customer_name: this.u.spectateurnom,//Le nom du client
            customer_surname: this.u.spectateurprenom,//Le prenom du client
            customer_email: "down@test.com",//l'email du client
            customer_phone_number: "225" + this.u.spectateurtel,//l'email du client
            customer_address: "BP 0024",//addresse du client
            customer_city: "Antananarivo",// La ville du client
            customer_country: "CI",// le code ISO du pays
            customer_state: "CI",// le code ISO l'état
            customer_zip_code: "06510", // code postal

        });
        CinetPay.waitResponse(function (data) {

            console.log("RESPONSE >>> ", data)
            if (data.status == "ACCEPTED") {
                me.pushRequest(data);
                console.log("ACCEPTED >>> ", data)
            } else {
                console.log("REFUSED >>> ", data)
                me.pushRequest(data);
            }
        });
        CinetPay.onError(function (data) {
            console.log(data);
        });
    }

    pushRequest(request: any) {
        let respData: any = this.dataGet?.achat;
        let dataSave = {
            ...respData,
            achatid: respData?.primaryKey?.achatid,
            achatdatelevel2: respData?.achatdatelevel1,
            acheteuremail: "",
            cel_phone_num: "",
            cpm_trans_id: "",
            payment_method: "",
            achatmontantAprespayement: parseInt(respData?.achatmontantnormal) - parseInt(respData?.achatmontantpayer),
        }

        // {
        //     "cintepayData": request,
        //     "spectateurCode": this.u?.spectateurcode,
        //     "ticketPaiementData": {...dataSave, acheteurtel: "225" + dataSave.acheteurtel}
        // }
        let payload = {
            "codeAchatTicket": this.dataGet?.achat.achatcode,
            "response": {
                "amount": parseInt(request.data.amount) / 100,
                "channel": "cart",
                "paid_at": request.data.paid_at,
                "reference": request.data.reference,
                "status": "success"
            },
            "spectateurCode": this.u?.spectateurcode
        }
        this.gestwithkey.post(payload, "/billeterie/payments/finalisepaiment", this.user.token).subscribe(data => {
                this.logger.trace('INFOS CNX', data);
                switch (data.status) {
                    case 201: {
                        this.logger.trace(data.body);
                        this.loadingCheck = true;
                        this.achat = data.body;
                        window.location.href = '/success?key=' + dataSave.achatcode + '&mont=' + dataSave.achatmontantpayer;
                        break;
                    }
                    default: {
                        // window.location.href = '/success?key=' + 1 + '&mont=' + 1;
                        this.toastr.error('Echec!', data.body.message);
                    }
                }
            },
            error => {

                //this.loader.close();
                this.logger.error('ERROR', error);
                switch (error.status) {
                    case 409: { // CAS DE FRA
                        //  window.location.href = '/success?key=' + 2 + '&mont=' + 2;
                        break;
                    }
                    default: {

                        this.toastr.error('Echec!', error.error || `Erreur survenue veuillez ressayer`);

                        //this.router.navigateByUrl('/success?key=' + dataSave.achatcode + '&mont=' + dataSave.achatmontantpayer);
                        // '/checkpayment?key='+cat.caticketcode+'&type='+event.typeventcode+'&caticketlib='+cat.caticketlib
                        //this.router.navigate(['/success?key=' + dataSave.achatcode + '&mont=' + dataSave.achatmontantpayer]);
                        // window.location.reload();
                        //  window.location.href = '/success?key=' + 1 + '&mont=' + 1;
                        // window.location.href = '/';
                        break;
                    }
                }
            });
    }

    viewImg(ticket: any) {
        return ticket.listImage ? ticket.listImage[0].imageurl : '';
    }

    up(e: string) {
        switch (e) {
            case "c":
                if ((parseInt(this.ticketCarte) + parseInt(this.ticketQrcode)) == this.dataCheckEvent.nbrTicketParPersonne) {

                    this.message.create('warning', `Vous avez atteint le nombre de tickets autorisé par participant.`);
                    return;
                }
                this.ticketCarte++;
                break;
            case "q":
                if ((parseInt(this.ticketCarte) + parseInt(this.ticketQrcode)) == this.dataCheckEvent.nbrTicketParPersonne) {

                    this.message.create('warning', `Vous avez atteint le nombre de tickets autorisé par participant.`);
                    return;
                }
                this.ticketQrcode++;
                break;
        }
        this.checkSum();
    }

    down(e: string) {

        switch (e) {
            case "c":
                if (this.ticketCarte == 0) return;
                this.ticketCarte--;
                break;
            case "q":
                if (this.ticketQrcode == 0) return;
                this.ticketQrcode--;
                break;
        }
        this.checkSum();
    }

    chEng($event: any, i: number) {
        if ($event) {
            this.dataPayTicket[i].nom = this.dataUsers.spectateurnom;
            this.dataPayTicket[i].prenom = this.dataUsers.spectateurprenom;
            if (this.dataUsers.spectateurtel.includes('225')) {
                this.dataPayTicket[i].telephone = this.dataUsers.spectateurtel.replace(/225/g, '');
            } else {
                this.dataPayTicket[i].telephone = this.dataUsers.spectateurtel;
            }

            this.dataPayTicket[i].indicatif = "225";
        } else {
            this.dataPayTicket[i].nom = "";
            this.dataPayTicket[i].prenom = "";
            this.dataPayTicket[i].telephone = "";
            this.dataPayTicket[i].indicatif = "225";
        }

    }

    chEng1($event: any, i: number) {
        this.dataPayTicket[i].pourMoi = !this.dataPayTicket[i].pourMoi;
        if (this.dataPayTicket[i].pourMoi) {
            this.dataPayTicket[i].nom = this.dataUsers.spectateurnom;
            this.dataPayTicket[i].prenom = this.dataUsers.spectateurprenom;
            if (this.dataUsers.spectateurtel.includes('225')) {
                this.dataPayTicket[i].telephone = this.dataUsers.spectateurtel.replace(/225/g, '');
            } else {
                this.dataPayTicket[i].telephone = this.dataUsers.spectateurtel;
            }
            this.dataPayTicket[i].indicatif = "225";
        } else {
            this.dataPayTicket[i].nom = "";
            this.dataPayTicket[i].prenom = "";
            this.dataPayTicket[i].telephone = "";
            this.dataPayTicket[i].indicatif = "225";
        }

    }
    formatNumber(value) {
        if (typeof value === 'number') {
            value = value.toString();
        }
        // Formatage avec 3 chiffres après la virgule
        return this.maskApplierService.applyMask(value, 'separator.3');
    }
}
