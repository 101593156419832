<app-header-billeterie></app-header-billeterie>

<section class="mission-area ptb-100" style="padding-top: 0;height: 350px !important;z-index: 1;position: relative;">
    <section class="mission-area"  id="myImgApropos" style="background-color: #006837 !important;height: 350px !important;">
        <app-navbar></app-navbar>
<!--        <div class="container" style="display: flex;justify-content: center">-->
<!--            <h1 style="margin-top: auto;margin-bottom: auto;color: #0B2154 !important;font-family: Montserrat;font-size: 30px;line-height: 0.9;"></h1>-->
<!--            <img class="imgApropos" src="{{imgPay}}">-->
<!--        </div>-->

        <div class="container _contentText">
            <h1 class="_titre">Foire Aux Questions</h1>
            <img class="imgApropos" src="{{imgPay}}">
        </div>

    </section>
</section>




<div class="courses-details-area pb-70" style="background-color: #0B2154;min-height: 100%;text-align: justify">
    <div class="container">

        <div class="row">
            <div class="col-lg-2">

            </div>

            <div class="col-lg-8">

                <h3 style="text-align: center;margin-top: 15px">
                    Bienvenue dans le centre d’assistance de la Nouvelle Billetterie Hybride (NBH)
                </h3>

                <div class="courses-details-desc">

                    <h3>Qu’est-ce que la NBH ?</h3>
                    <p>
                        La Nouvelle Billetterie Hybride (NBH) est une solution digitale moderne pour la gestion
                        d'événements, offrant un contrôle d'accès automatique ou semi-automatique. Ce système intègre
                        des moyens de paiement digitaux tels que le mobile money, Visa, et Mastercard, permettant aux
                        spectateurs d'acheter leurs billets numériques en ligne à distance, ainsi que dans les points de
                        vente habituels. Pour les organisateurs d'événements, les entreprises et les institutions, NBH
                        permet de créer et de gérer facilement leurs événements, qu'ils soient gratuits ou payants, en
                        seulement quelques clics.
                        Nos services s'adressent aux organisateurs de concerts, salons, conférences publiques ou
                        privées, formations, spectacles, galas, barbecues, sorties détente, activités sportives (matchs)
                        et bien d'autres événements, quelle que soit leur situation géographique.
                        Pour plus d'informations, n'hésitez pas à contacter notre équipe commerciale à l'adresse
                        suivante : infocommerciale@nbh.ci.
                    </p>

                    <h3>Qu'est-ce qui distingue la NBH des autres systèmes de billetterie en ligne ?</h3>

                    <p>
                        La Nouvelle Billetterie Hybride (NBH) se distingue des autres systèmes de billetterie en ligne
                        par plusieurs aspects :
                    </p>


                    <ul class="description-features-list">
                        <li>Hybridité du Système : NBH propose un contrôle d'accès à la fois automatique et
                            semi-automatique, offrant une flexibilité unique adaptée à différents types d'événements.
                        </li>
                        <li>
                            Intégration de Paiements Digitaux : NBH intègre divers moyens de paiement digitaux, tels
                            que le mobile money, Visa, et Mastercard, permettant aux utilisateurs de régler leurs
                            billets en ligne ou en personne, selon leurs préférences.
                        </li>
                        <li>
                            Accessibilité Multi-Canal : Les billets peuvent être achetés non seulement en ligne, mais
                            aussi dans les points de vente physiques habituels, ce qui élargit l'accessibilité pour les
                            utilisateurs.
                        </li>
                        <li>
                            Simplicité de Gestion : NBH permet aux organisateurs de créer et de gérer leurs
                            événements en ligne, qu'ils soient gratuits ou payants, de manière simple et rapide, en
                            quelques clics.
                        </li>
                        <li>
                            Adaptabilité Géographique : NBH offre ses services à des organisateurs partout dans le
                            monde, sans se limiter à une zone géographique spécifique.
                        </li>
                        <li>
                            Flexibilité des Types d'Événements : NBH est conçu pour gérer une vaste gamme
                            d'événements, qu'il s'agisse de concerts, conférences, formations, spectacles, ou même
                            d'activités plus informelles comme des barbecues ou des galas.
                        </li>
                        <li>
                            L'émission de billets électroniques sur des cartes magnétiques réutilisables pour tous
                            les événements disponibles sur notre plateforme.
                        </li>
                        <li>
                            La possibilité d'acheter le même billet électronique chez le promoteur, auprès des
                            membres de son équipe, ou dans les points de vente partenaires, en utilisant une carte ou en
                            optant pour l'option de QR code.
                        </li>
                    </ul>
                    <p>
                        Ces éléments combinés font de NBH une solution polyvalente et moderne, adaptée aux besoins
                        variés des organisateurs d'événements.
                    </p>
                    <h3>
                        Comment ouvrir un compte organisateur ?
                    </h3>

                    <p>
                        Nous exigeons des justificatifs standards pour vérifier que l'organisateur utilisant notre
                        solution existe réellement et respecte les critères établis par les autorités compétentes ainsi
                        que par nos partenaires.
                    </p>

                    <p>
                        Merci de contacter notre service commercial à l'adresse <a href="javascript:void(0)">infocommerciale@nbh.ci</a>
                        afin qu'il vous
                        transmette une copie du contrat ainsi que les besoins additionnels.
                    </p>

                    <h3>
                        Quelles sont les conditions pour ouvrir un compte spectateur ?
                    </h3>

                    <p>
                        Vous avez la possibilité de créer votre compte soit à partir de l'application mobile disponible
                        sur le store (App Store ou Google Play), soit via notre site web (le lien du site web)
                    </p>
                    <p>Pour ouvrir un compte spectateur, vous devez :</p>
                    <ul>
                        <li>
                            Âge Minimum : Être âgé d'au moins 13 ans
                        </li>
                        <li>
                            Informations Personnelles : Fournir des informations personnelles telles que votre nom
                            complet, date de naissance, adresse e-mail et numéro de téléphone valide.
                        </li>
                        <li>
                            Numéro de téléphone : Utiliser un numéro de téléphone valide que vous pouvez vérifier. Un
                            OTP de confirmation sera envoyé sur ce numéro pour activer votre compte.
                        </li>
                        <li>
                            Mot de Passe : Créer un mot de passe sécurisé pour protéger votre compte. Le mot de passe
                            doit contenir 5 chiffres
                        </li>
                        <li>
                            Vérification : Compléter le processus de vérification de votre compte, qui peut inclure
                            la validation de votre numéro de téléphone et, dans certains cas, des informations
                            supplémentaires pour des raisons de sécurité.
                        </li>
                        <li>
                            Conformité : Accepter les termes et conditions ainsi que la politique de confidentialité de
                            notre application.
                        </li>
                    </ul>
                    <h3>
                        Quelles sont les conditions pour obtenir un lecteur de ticket NBH, si j'organise un évènement ?
                    </h3>

                    <p>
                        L’offre de lecteur de ticket est accessible à toute personne disposant d’un compte
                        organisateur et ayant eu la validité de son événement. Les tarifs relatifs à cette offre sont à
                        retrouver dans le document des conditions d’utilisation de NBH pour organisateur.
                    </p>

                    <h3>
                        Quels sont les moyens de paiement acceptés sur NBH ?
                    </h3>

                    <p>
                        L'offre de lecteur de tickets est accessible à toute personne disposant d'un compte organisateur
                        et dont l'événement a été validé. Les autres conditions relatives à cette offre sont disponibles
                        dans le document des conditions d'utilisation de NBH pour organisateurs.
                    </p>
                    <h3>ACHAT DE TICKET DE TYPE QR CODE </h3>
                    <p>
                        Tout bénéficiaire de ticket qr code doit obligatoirement avoir un compte sur l’application NBH pour recevoir son ticket de type de QR code.
                        Il est egalement obligatoire de rendre avec le Qr code (contenu dans son application) le Jour j de l'evenemnt pour le presenter
                        à l'entrée ( l'ors du controle des tickets).
                    </p>
                    <h3>
                        Quels sont les moyens de paiement acceptés sur NBH ?
                    </h3>

                    <p>
                        En ligne, notre interface de paiement, assurée par la NBH, permet de réaliser des paiements par
                        Visa, Mastercard, ainsi que par mobile money auprès des principaux opérateurs en Côte d'Ivoire.
                        Toutefois, les spectateurs ont également la possibilité de régler en espèces directement auprès
                        de l'organisateur, dans les points de vente partenaires ou chez les commerciaux engagés par
                        l'organisateur.
                    </p>

                    <h3>
                        Qu’est ce qui garantit que le ticket acheté sur votre plateforme est fiable ?
                    </h3>

                    <p>
                        Notre système émet des tickets électroniques uniques et hautement cryptés, chaque ticket étant
                        protégé par un code de cryptage complexe et unique. Cette technologie rend la décryption et la
                        reproduction des tickets extrêmement difficiles, voire impossibles, garantissant ainsi la
                        sécurité de nos billets. De plus, les tickets ne peuvent être utilisés qu'au sein de notre
                        application et pour l'événement pour lequel ils ont été émis.
                    </p>

                    <h3>
                        En cas d’oubli de votre mot de passe, comment récupérer son ticket ?
                    </h3>

                    <p>
                        En cas d’oubli de votre mot de passe, cliquez sur l'option « Mot de passe oublié? » sur la page
                        de connexion de notre application ou site web.
                    </p>
                    <ul>
                        <li>
                            Entrez votre numéro de téléphone associé à votre compte pour recevoir un code OTP (One-Time
                            Password).
                        </li>
                        <li>
                            Saisissez le code reçu par SMS.
                        </li>
                        <li>
                            Entrez votre nouveau mot de passe et confirmez-le.
                        </li>
                        <li>
                            Accéder à votre compte : Connectez-vous avec votre nouveau mot de passe.
                        </li>
                    </ul>

                    <h3>
                        En cas de perte de mon téléphone, comment récupérer mon ticket ?
                    </h3>

                    <p>
                        En cas de perte de votre téléphone, vous pouvez vous connecter sur l'interface web à l'adresse
                        <a href="www.nbh.ci">www.nbh.ci</a> pour gérer vos tickets. Vous aurez la possibilité de
                        transférer vos tickets sur un
                        nouveau compte, télécharger l'application mobile sur un autre téléphone ou vous connecter avec
                        vos identifiants sur le téléphone d'un autre utilisateur.
                    </p>

                    <h3>
                        Comment puis-je contacter le service commercial de la NBH ?
                    </h3>

                    <p>
                        Si vous avez des questions ou besoin d'aide, vous pouvez nous appeler aux numéros suivants :
                    </p>
                    <ul style="list-style: none;">
                        <li>☎️+225 27 20243331</li>
                        <li>📞+225 07 47602283</li>
                        <li> 📞+225 05 06157991</li>
                    </ul>
                    <p>, ou par mail à l’adresse <a
                        href="javascript:void(0)" style="color: #fff">infocommerciale@nbh.ci</a>
                    </p>



                    <h3>
                        Est-il possible d'annuler un ticket pour l'événement A et de l'utiliser pour l'événement B ?
                    </h3>

                    <p>
                        Non, un ticket acheté pour l'événement A ne peut ni être annulé, ni revendu, ni remboursé sur
                        notre plateforme, sauf en cas d'annulation de l'événement A.
                    </p>

                    <h3>
                        En cas d'annulation d'un concert, qui procédera au remboursement ?
                    </h3>

                    <p>
                        En cas d'annulation d'un événement, nous vous contacterons pour vous expliquer la procédure à
                        suivre afin d'obtenir votre remboursement.
                    </p>

                    <h3>
                        Comment puis-je transférer un ticket à une autre personne ?
                    </h3>

                    <p>
                        Pour transférer un ticket (QR code) à une autre personne via notre application NBH, assurez-vous
                        d'abord que le nouveau bénéficiaire possède un compte NBH. Si c'est le cas, connectez-vous à
                        votre espace client et rendez-vous dans la rubrique « MES TICKETS ». Sélectionnez le ticket que
                        vous souhaitez transférer et cliquez sur le bouton « Transférer » situé en bas du ticket. Entrez
                        les informations demandées pour compléter le transfert.
                    </p>
                    <p>Si le ticket est sur une carte NBH, le nouveau bénéficiaire devra également posséder une carte
                        NBH. Dans ce cas, suivez la même procédure pour transférer le ticket directement sur sa
                        carte.</p>
                    <h3>
                        Comment établir un partenariat avec la NBH ?
                    </h3>

                    <p>
                        Pour établir un partenariat avec la Nouvelle Billetterie Hybride (NBH), veuillez nous envoyer un
                        courrier à l'adresse suivante : <a href="javascript:void(0)">infocommerciale@nbh.ci.</a>
                    </p>

                    <h3>
                        Combien de cartes peut-on associer à un compte ?
                    </h3>
                    <p>Vous pouvez associer jusqu'à cinq (5) cartes à votre compte.</p>
                    <h3>
                        Que faire si je perds ma carte pour récupérer mes tickets associés ?
                    </h3>
                    <p>En cas de perte de votre carte, voici les deux étapes à suivre :</p>
                    <ul>
                        <li>Connectez-vous à votre espace client en ligne. Dans la rubrique « CARTE », sélectionnez
                            le numéro de la carte perdue et cliquez sur « Bloquer » pour la désactiver.
                        </li>
                        <li>
                            2. Toujours dans votre espace client, allez dans la rubrique « MES TICKETS ». Sélectionnez
                            le ticket associé à la carte perdue et transférez-le vers une autre carte en entrant le
                            numéro de cette nouvelle carte.
                        </li>
                        <li>
                            Appelez le service client au :
                        <ul style="list-style: none;">
                            <li>☎️+225 27 20243331</li>
                            <li>📞+225 07 47602283</li>
                            <li> 📞+225 05 06157991</li>
                        </ul>
                        </li>
                    </ul>
                    <h3>Comment rattacher ou identifier une carte a un compte ?</h3>
                    <p>
                        Pour associer ou identifier une carte à un compte, accédez à la rubrique « CARTE » dans votre
                        espace client. Cliquez ensuite sur « Ajouter une carte » et saisissez le numéro inscrit au dos
                        de la carte.
                    </p>
                    <h3>Pourquoi dois-je rattacher ou identifier ma carte à mon compte, est ce obligatoire ?</h3>
                    <p>
                        Non, il n'est pas nécessaire de rattacher ou d'identifier une carte à un compte avant de
                        l'utiliser. Cependant, le faire vous permet de bloquer la carte en cas de perte.
                    </p>


                    <h3>
                        En cas de perturbation du réseau lors de la vérification des tickets, quelle option
                        proposez-vous ?
                    </h3>

                    <p>
                        En cas de perturbation du réseau lors du contrôle, notre système vous permet de poursuivre la
                        vérification des tickets en mode hors ligne, sans connexion Internet.
                    </p>
                    <h3>
                        Comment se fait le contrôle des tickets ?
                    </h3>

                    <p>
                        Le contrôle des tickets se fait via une application mobile compatible avec les lecteurs de
                        cartes et les QR codes.
                    </p>

                    <h3>
                        Qui est responsable du contrôle des tickets ?
                    </h3>

                    <p>
                        L'organisateur de l'événement est responsable du contrôle des tickets. Il peut déléguer cette
                        tâche à d'autres personnes en leur fournissant un accès à l'application de contrôle. Pour ce
                        faire, l'organisateur doit enregistrer leurs coordonnées dans la liste des utilisateurs en leur
                        attribuant la fonction de contrôleur. En cas de difficulté, il peut également faire appel au
                        service de contrôle de la NBH pour obtenir de l'aide.
                    </p>

                </div>

            </div>
            <div class="col-lg-2">

            </div>
        </div>
    </div>
</div>
